import Button from "../../../Button/Button";
import "./documentLinkModal.css";
import addDocument from "../../plus.png";
import { useState } from "react";
import link from "../../link.png";
import document from "../../dokument.png";
import { useGlobalContext } from "../../../Context/Context";

const DocumentLinkModal = ({
  realItemData,
  setItemList,
  attachModalActive,
  atachModalCommentActive,
  itemData,
  setItemData,
  activeAdd,
  index,
  order,
}) => {
  const { setOpenAddLink } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const { letterSize } = useGlobalContext();
  const { boldItalic } = useGlobalContext();
  const { fontSize } = useGlobalContext();
  const { fontStyle } = useGlobalContext();
  console.log("Ovo je order", order, attachModalActive);
  // isCOmmenting === true -> dodavanje komentara
  // attachModalActive === undefined => dodavanje stavke
  // !isCommenting -> citanje stavke
  // isCommenting === undefined -> citanje komentara
  console.log(attachModalActive);
  console.log(itemData);
  console.log(realItemData);
  const setSettingData = () => {
    if (attachModalActive === undefined) {
      console.log("Prvi");
      return {
        document: itemData.document,
        link: itemData.link,
      };
    }

    if (attachModalActive.isCommenting === undefined) {
      console.log("drugi");
      return {
        document: itemData.comments[atachModalCommentActive.value].document[0],
        link: itemData.comments[atachModalCommentActive.value].link,
      };
    }

    if (attachModalActive.isCommenting) {
      console.log("Treci");
      return {
        document: realItemData === undefined ? [] : realItemData.document,
        link: realItemData === undefined ? "" : realItemData.link,
      };
    } else {
      console.log("Cetvrti");
      return {
        document: itemData.documents.length === 0 ? [] : itemData.documents[0],
        link: itemData.links[0]?.link,
      };
    }
  };

  const maxFileNameLength = 40;
  const [data, setData] = useState(setSettingData());
  console.log(data);
  const [openLink, setOpenLink] = useState(false);

  const handleInputLink = (e) => {
    setData({ ...data, link: e.currentTarget.value });
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleOpenLink = (e) => {
    if (openLink) {
      let customBtn;
      if (data.link === "") {
        customBtn = e.currentTarget.querySelector(".dos-btn-active");
      } else {
        customBtn = e.currentTarget.querySelector(".dos-btn");
      }
      customBtn.classList.add("correct-btn");

      const time = setTimeout(() => {
        setOpenLink(false);
        setData({ ...data, link: data.link });
        setItemData({ ...realItemData, link: data.link });
        customBtn.classList.remove("correct-btn");
        clearTimeout(time);
      }, 500);
    } else {
      setOpenLink(true);
    }
  };

  const handleInputDocument = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setItemData({
        ...realItemData,
        document: {
          name: currentFile.name,
          data: d.currentTarget.result,
        },
      });
      setData({
        ...data,
        document: {
          name: currentFile.name,
          data: d.currentTarget.result,
        },
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="image-document-wrapper">
      {data.document !== undefined && data.document.length !== 0 && (
        <a
          className="download-link"
          download={data.document?.name}
          href={data.document?.data}
        >
          <img src={document} alt="" />
          {data.document?.name?.length > maxFileNameLength
            ? `${data.document.name.slice(0, maxFileNameLength)}...`
            : data.document.name}
        </a>
      )}
      <span
        className={
          "image-modal-span " +
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText("* Dozvoljeno 1 dokument i 1 link")}{" "}
        {/*pomena jezika*/}
      </span>
      <span
        className={
          "image-modal-span " +
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText("* Dozvoljeni formati: PDF, DOCX...")}{" "}
        {/*promena jezika*/}
      </span>
      <span
        className={
          "image-modal-span " +
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText("* Link se dodaje kopiranjem")} {/*promena jezika*/}
      </span>
      <div className="wrapper-link-button">
        {openLink ? (
          <input
            defaultValue={data.link}
            type="text"
            onChange={handleInputLink}
          />
        ) : (
          <span>{data.link}</span>
        )}
        <div className="document-link-add">
          <div
            className={activeAdd ? "document-add" : "document-add disable-link"}
          >
            <label className="button-image-document-link" htmlFor="file-upload">
              <img src={addDocument} alt="" className="" />
              <img src={document} alt="" className="" />
            </label>
            <input
              accept="application/*"
              id="file-upload"
              type="file"
              onChange={handleInputDocument}
            />
          </div>
          <div
            className={activeAdd ? "document-add" : "document-add disable-link"}
            onClick={handleOpenLink}
          >
            <Button
              active={data.link === "" && openLink}
              activation={true}
              typeButton={typeButton}
            >
              <div className="button-image-document-link">
                <img src={addDocument} alt="" className="visible-img" />
                <img src={link} alt="" className="visible-img" />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentLinkModal;
