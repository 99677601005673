import React, { useRef, useState, useEffect } from "react";
import "./Style.css";
import axios from "axios";
import icon from "./icons/icon.png";
import plane from "./icons/plane.png";
import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";

const CheckForTrustedContacts = ({
  haveTrustContact,
  setCurrentPagePassword,
  correctPattern,
  usePattern,
  usePatternAnswer,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [localCurrentPage, setLocalCurrentPage] = useState(undefined);
  const trustedContact = useRef();
  const { controlEmail, baseUrl, letterSize, boldItalic } = useGlobalContext();
  const { typeButton, fontSize, fontStyle } = useGlobalContext();

  const checkTrustContactExist = async () => {
    ///control/checkIfTContactIsCorrect.php
    try {
      const { data } = await axios.post(
        `${baseUrl}/control/checkIfTContactIsCorrect.php`,
        {
          user_email: controlEmail,
          trusted_contact_email: trustedContact.current,
        }
      );
      if (data.status === 200) {
        return data.correct;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = (type) => {
    setActiveButton(type);

    console.log(type);
    console.log(haveTrustContact);
    console.log(trustedContact.current);
    if (haveTrustContact) {
      //ima pouzdane kontakte
      if (!type) {
        //slucaj za ne
        setLocalCurrentPage(5);
      } else {
        //slucaj za da

        if (trustedContact.current.length !== 0) {
          checkTrustContactExist().then((value) => {
            console.log(value);
            if (value) {
              if (correctPattern) {
                setLocalCurrentPage(4);
              } else {
                setLocalCurrentPage(3);
              }
              console.log("Dobar Pouzdani kontakt");
            } else {
              setLocalCurrentPage(5);
              console.log("Los pouzdani kontakt");
            }
          });
        } else {
          if (correctPattern) {
            setLocalCurrentPage(3);
          } else {
          }

          if (usePatternAnswer) {
            if (!usePattern && !correctPattern) {
              setLocalCurrentPage(3);
            }
            if (usePattern && !correctPattern) {
              setLocalCurrentPage(5);
            }
          } else {
            if (usePattern && !correctPattern) {
              setLocalCurrentPage(3);
            }
            if (!usePattern && !correctPattern) {
              setLocalCurrentPage(5);
            }
          }

          console.log("Nema pouzdani");
        }
      }
    } else {
      //nema pouzdane kontakte
      if (!type) {
        //slucajevi za ne
        if (correctPattern) {
          setLocalCurrentPage(4);
        }
        if (!correctPattern) {
          if (usePattern && usePatternAnswer) {
            setLocalCurrentPage(0);
          }

          if (!usePattern && !usePatternAnswer) {
            setLocalCurrentPage(0);
          }
        }
      } else {
        setLocalCurrentPage(5);
        //slucajevi za da
      }
      if (usePatternAnswer) {
        //slucajevi i za ne i za da
        if (!usePattern && !correctPattern) {
          setLocalCurrentPage(5);
        }
      } else {
        if (usePattern && !correctPattern) {
          setLocalCurrentPage(5);
        }
      }
    }
  };

  const handleSetPage = (e) => {
    if (!haveTrustContact) {
      //nema pouzdane kontakte

      if (usePatternAnswer) {
        //slucajevi i za ne i za da
        if (!usePattern && !correctPattern) {
          setLocalCurrentPage(5);
        }
      } else {
        if (usePattern && !correctPattern) {
          setLocalCurrentPage(5);
        }
      }
    } else {
      trustedContact.current = e.currentTarget.value;
      //ima pouzdane kontakte
    }
  };

  const handleInputSetPage = () => {
    if (!haveTrustContact) {
      setLocalCurrentPage(5);
    } else {
      if (activeButton) {
        if (trustedContact.current.length !== 0) {
          checkTrustContactExist().then((value) => {
            console.log(value);
            if (value) {
              if (correctPattern) {
                setLocalCurrentPage(4);
              } else {
                setLocalCurrentPage(3);
              }
              console.log("Dobar Pouzdani kontakt");
            } else {
              setLocalCurrentPage(5);
              console.log("Los pouzdani kontakt");
            }
          });
        } else {
          if (correctPattern) {
            setLocalCurrentPage(3);
          } else {
          }

          if (usePatternAnswer) {
            if (!usePattern && !correctPattern) {
              setLocalCurrentPage(3);
            }
            if (usePattern && !correctPattern) {
              setLocalCurrentPage(5);
            }
          } else {
            if (usePattern && !correctPattern) {
              setLocalCurrentPage(3);
            }
            if (!usePattern && !correctPattern) {
              setLocalCurrentPage(5);
            }
          }

          console.log("Nema pouzdani");
        }
      }
    }
  };

  const handleSendPage = () => {
    if (activeButton === null && localCurrentPage === undefined) return;
    setCurrentPagePassword(localCurrentPage);
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    trustedContact.current = "";
  }, []);

  return (
    <div className="check-cor-trusted-contacts-container">
      <div className="instruction">
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("OBAVESTENJE")}
        </p>
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(
            "UKOLIKO STE DEFINISALI POUZDANE KONTAKTE NJIHOVA MAIL ADRESA U DONJEM POLJU MORA BITI TACNO NAVEDENA."
          )}
        </p>
      </div>

      <div className="btn-row">
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("IMATE POUZDANI KONTAKT?")}
        </p>
        <div className="btns">
          <div
            className="button-trust-contact"
            onClick={() => handleButtonClick(false)}
          >
            <Button
              typeButton={typeButton}
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              active={activeButton !== null ? !activeButton : false}
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("NE")}
            </Button>
          </div>
          <div
            className="button-trust-contact"
            onClick={() => handleButtonClick(true)}
          >
            <Button
              typeButton={typeButton}
              active={activeButton !== null ? activeButton : false}
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("DA")}
            </Button>
          </div>
        </div>
      </div>

      <div className="trusted-contact">
        <img src={icon} alt="" />
        <input
          onChange={handleSetPage}
          onBlur={handleInputSetPage}
          className="trust-contact-input"
          type="text"
        />
      </div>

      <div className="send-button-truste-contact" onClick={handleSendPage}>
        <Button
          activation={true}
          isDisabled={activeButton === null ? true : false}
          typeButton={typeButton}
        >
          <img src={plane} alt="" />
        </Button>
      </div>
    </div>
  );
};

export default CheckForTrustedContacts;
