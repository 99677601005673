import React, { useEffect, useState } from 'react'

import { CircledButton, FeedPostsList, Loader, StickyMenu } from '../../components';
import { CategoryActiveIcon, CategoryIdleIcon, EyeIdleIcon, FavIdleIcon, SortIdleIcon } from '../../assets';

import { fetchPopularPosts } from '../../data/fetchAPI';

const FeedPosts = () => {

    const [postList, setPostList] = useState([])

    const toggleCategories = () => {
      console.log('categories')
    }

    const stickyButtons = [
      {link: './', idleImage: CategoryIdleIcon, activeImage: CategoryActiveIcon, handleEffect: toggleCategories, toggle: true},
      {link: './', idleImage: EyeIdleIcon, activeImage: EyeIdleIcon, handleEffect: () => {console.log('2')}, toggle: true},
      {link: './', idleImage: SortIdleIcon, activeImage: SortIdleIcon, handleEffect: () => {console.log('3')}, toggle: false},
      {link: './', idleImage: FavIdleIcon, activeImage: FavIdleIcon, handleEffect: () => {console.log('4')}, toggle: false}
    ]
    
    useEffect(() => {
        fetchPopularPosts().then(data => setPostList(data))
    }, [])
    
    

    return (
        <div className='flex-center-col feed-list' style={{width: '95%', margin: '0 auto'}}>
          
          <StickyMenu buttonsList={stickyButtons} />
          
          <div className='posts-list flex-center-col list' style={{gap: '1rem', width: '100%'}}>
            {postList?.length ? <FeedPostsList list={postList} /> : <Loader />}
          </div>
        </div>
      );
}

export default FeedPosts