import React, { useEffect, useState } from 'react'
import { NavMenu } from '../../components'
import { Outlet, useLocation } from 'react-router-dom'
import LandingPage from '../LandingPage/LandingPage'
import { BackActiveIcon, BackIdleIcon, BellActiveIcon, BellIdleIcon, SearchActiveIcon, SearchIdleIcon, UserActiveIcon, UserIdleIcon } from '../../assets'

const RootPage = () => {

    const location = useLocation()

    const [navButtons, setNavButtons] = useState([])

    const backButton = {link: '../', idleImage: BackIdleIcon, activeImage: BackActiveIcon, handleEffect: () => {console.log('1')}, toggle: true}

    const personalButtons = [backButton,
      {link: 'personal', idleImage: UserIdleIcon, activeImage: UserActiveIcon, handleEffect: () => {console.log('2')}, toggle: true},
      {link: '', idleImage: BellIdleIcon, activeImage: BellActiveIcon, handleEffect: () => {console.log('3')}, toggle: false},
      {link: '', idleImage: SearchIdleIcon, activeImage: SearchActiveIcon, handleEffect: () => {console.log('4')}, toggle: false}
    ]


    useEffect(() => {
      switch (location.pathname) {
        case '/social/personal':
            setNavButtons()
            break;
        
        default:
            break;
      }
    }, [location])
    

  return (
    location.pathname === '/social' || location.pathname === '/social/' ?
    <LandingPage />
    :
    <>
    <NavMenu buttonsList={personalButtons} />
    <Outlet />
    </>
  )
}

export default RootPage