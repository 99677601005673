import Button from "../../../Button/Button";
import deleteImg from "../../zatvaranje.png";
import updateImg from "../../olovka.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import imageImg from "../../slika.png";
import { useState } from "react";
import { useGlobalContext } from "../../../Context/Context";

const ImageModalAddEdit = ({
  handleUpdatingImage,
  index,
  setUpdatingImage,
  updatingImage,
  itemData,
  handleDeleteImage,
  setDraftImages,
  isAdding,
  realItemData,
}) => {
  const [title, setTitle] = useState("");
  console.log(title);
  const [isUpdateTitle, setIsUpdateTitle] = useState(false);
  const { typeButton } = useGlobalContext();

  const handleUpdatingTitle = () => {
    if (isUpdateTitle) {
      handleUpdatingImage(title);
    }
    setIsUpdateTitle(!isUpdateTitle);
  };

  const iconStyles = {
    height: "90%",
    width: "100%",
  };

  const downloadStyles = {
    height: "100%",
    width: "100%",
  };

  const handleOpenUpdate = (e) => {
    const buttonImage = e.currentTarget.querySelector(".dos-btn");
    buttonImage.classList.add("correct-btn");
    const time = setTimeout(() => {
      console.log(itemData);
      console.log(realItemData);
      setTitle(
        realItemData.images.length !== 0 ? realItemData.images[index].title : ""
      );
      setUpdatingImage(index);
      setDraftImages([]);
      buttonImage.classList.remove("correct-btn");
      clearTimeout(time);
    }, 500);
  };

  return updatingImage !== index ? (
    <div className="image-modal-settings">
      <div
        className="image-modal-icon"
        onClick={() => handleDeleteImage(index)}
      >
        <Button image={deleteImg} activation={true} typeButton={typeButton} />
      </div>
      <div className="image-modal-icon">
        <Button typeButton={typeButton}>
          <VisibilityIcon style={iconStyles} />
        </Button>
      </div>
      <div className="image-modal-icon">
        <Button activation={true} typeButton={typeButton}>
          <a
            href={itemData.images[index]?.data}
            download={itemData.images[index]?.title}
            style={{ color: "inherit", height: "100%", width: "100%" }}
          >
            <DownloadIcon style={downloadStyles} />
          </a>
        </Button>
      </div>
      <div className="image-modal-icon-double" onClick={handleOpenUpdate}>
        <Button activation={true} typeButton={typeButton}>
          <div className="double-icon-container">
            <img
              className="image-modal-icon-double-img"
              src={updateImg}
              alt=""
            />
            <img
              className="image-modal-icon-double-img"
              src={imageImg}
              alt=""
            />
          </div>
        </Button>
      </div>
    </div>
  ) : (
    <div className="image-modal-settings">
      <div className="image-modal-icon" onClick={handleUpdatingTitle}>
        <Button
          image={updateImg}
          active={isUpdateTitle}
          typeButton={typeButton}
        />
      </div>
      {isUpdateTitle ? (
        <input
          className="image-modal-name-input"
          defaultValue={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
      ) : (
        <span className="image-modal-name">{title}</span>
      )}
      <div className="image-modal-icon" onClick={() => setUpdatingImage(null)}>
        <Button image={deleteImg} activation={true} typeButton={typeButton} />
      </div>
    </div>
  );
};

export default ImageModalAddEdit;
