import { Outlet } from 'react-router-dom'

import {PostForm} from "../../components";
import './feed.css';

const Feed = () => {
    return (
        <div className='feed-page test flex-col'>
       
            <div className="feed-page--form flex-center-col">   
                <PostForm />
            </div>
            <Outlet />
        </div>
    );
}

export default Feed;
