import { useGlobalContext } from "../Context/Context";
import "./termsOfUse.css";
import cancel from "../picture/Cancel.png";
import Button from "../Button/Button";
import { useState } from "react";
import Message from "../Message/Message";

const TermsOfUse = ({ setCurrentPage }) => {
  const { selectedLanguage, loggedUser, isFromHome, setLoggedUser } =
    useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const [activeExit, setActiveExit] = useState(false);

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const hanldeSendTerms = () => {
    console.log(isFromHome);
    if (isFromHome) {
      setCurrentPage(4);
      setLoggedUser(JSON.parse(localStorage.getItem("editor-user")));
    } else {
      setCurrentPage(3);
    }
  };

  //console.log(selectedLanguage);
  return (
    <>
      <div className="terms-of-use-wrapper">
        <img
          src={cancel}
          alt=""
          className="close-terms"
          onClick={() => setActiveExit(true)}
        />
        <div
          className={
            "title-terms " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.titleTerms)}
        </div>
        <div
          className={
            "content-terms " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.termsOfUse)}
        </div>
        <div className="send-terms" onClick={() => hanldeSendTerms()}>
          <Button typeButton={typeButton}>
            <span
              className={
                "send-terms-span " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText(selectedLanguage.data.agree)}
            </span>
          </Button>
        </div>
        {activeExit && <Message setActiveExit={setActiveExit} />}
      </div>

      <div className="terms-of-use-wrapper-landscape">
        <div className="terms-of-use-wrapper-landscape-top">
          <img
            src={cancel}
            alt=""
            className="close-terms"
            onClick={() => setActiveExit(true)}
          />
          <div
            className={
              "title-terms " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(selectedLanguage.data.titleTerms)}
          </div>
          <div className="send-terms" onClick={() => setCurrentPage(3)}>
            <Button typeButton={typeButton}>
              <span
                className={
                  "send-terms-span " +
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {handleConvertText(selectedLanguage.data.agree)}
              </span>
            </Button>
          </div>
          {activeExit && <Message setActiveExit={setActiveExit} />}
        </div>
        <div className="terms-of-use-wraper-landscape-bottom">
          <div
            className={
              "content-terms " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(selectedLanguage.data.termsOfUse)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
