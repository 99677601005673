import "./imageModal.css";
import plusImg from "../../plus.png";
import imageImg from "../../slika.png";
import { useRef, useState } from "react";
import ImageModalAdd from "./ImageModalAdd";
import ImageModalAddEdit from "./ImageModalAddEdit";
import ImageModalComment from "./ImageModalComment";
import sendImg from "../../send.png";
import deleteImg from "../../zatvaranje.png";
import updateImg from "../../olovka.png";
import "./imagePopupComment.css";
import Button from "../../../Button/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { useGlobalContext } from "../../../Context/Context";

const ImagePopupComment = ({
  itemComment,
  setItemComment,
  commentImageActive,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [addedImage, setAddedImage] = useState("");
  const [isUpdateTitle, setIsUpdateTitle] = useState(false);
  const { typeButton } = useGlobalContext();
  const { letterSize, boldItalic, fontSize, fontStyle } = useGlobalContext();
  const title = useRef();

  const iconStyles = {
    height: "90%",
    width: "100%",
  };

  const downloadStyles = {
    height: "100%",
    width: "100%",
  };

  const handleInputImage = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => setAddedImage(reader.result));
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleAddImage = () => {
    if (title.current === "") return;
    const newImage = {
      data: addedImage,
      title: title.current.value,
    };
    setItemComment({ ...itemComment, image: newImage });
    setAddedImage("");
  };

  const handleToggleUpdate = () => {
    setIsEditing(!isEditing);
  };

  const handleToggleUpdateTitle = () => {
    if (isUpdateTitle) {
      const newImage = {
        data: itemComment.image.data,
        title: title.current.value,
      };
      setItemComment({ ...itemComment, image: newImage });
      title.current.value = "";
    }
    setIsUpdateTitle(!isUpdateTitle);
  };

  const handleDeleteImage = () => {
    setItemComment({ ...itemComment, image: "" });
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };
  console.log(itemComment);

  return (
    <div className="image-popup-wrapper">
      <div className="image-popup-modal">
        <div
          className={`${
            addedImage !== "" || itemComment.image !== ""
              ? "image-popup-container with-image"
              : "image-popup-container"
          }`}
        >
          {(addedImage !== "" || itemComment.image !== "") && (
            <div className="popup-image-wrapper">
              <div className="popup-title-container">
                {itemComment?.image.title}
              </div>
              <img
                src={addedImage === "" ? itemComment.image.data : addedImage}
                alt=""
              />
            </div>
          )}
        </div>
        {addedImage === "" && itemComment.image === "" && (
          <div className="line-image" />
        )}
        {addedImage === "" && itemComment.image === "" && (
          <div className="popup-input-picture">
            <span
              className={
                "image-modal-span " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("* Dozvoljeno 1 fotografija")}
            </span>
            <span
              className={
                "image-modal-span " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("* Dozvoljeni formati: JPEG, PNG, TIFF")}
            </span>
            <div className="popup-image-modal-input">
              <label
                className="image-modal-input-wrapper"
                htmlFor="file-upload"
              >
                <img className="plus-img" src={plusImg} alt="" />
                <img className="image-img" src={imageImg} alt="" />
              </label>
              <input
                accept="image/jpeg, image/png, /image/tiff, image/jpg"
                id="file-upload"
                type="file"
                onChange={handleInputImage}
              />
            </div>
          </div>
        )}
        {itemComment.image === "" && addedImage !== "" && (
          <div className="popup-image-modal-settings">
            <input className="image-modal-name-input" ref={title} />
            <div className="image-modal-icon" onClick={handleAddImage}>
              <Button
                image={sendImg}
                activation={true}
                typeButton={typeButton}
              />
            </div>
          </div>
        )}
        {itemComment.image !== "" && (
          <>
            {isEditing ? (
              <div className="popup-image-modal-settings">
                <div
                  className="image-modal-icon"
                  onClick={handleToggleUpdateTitle}
                >
                  <Button
                    image={updateImg}
                    active={isUpdateTitle}
                    typeButton={typeButton}
                  />
                </div>
                {isUpdateTitle ? (
                  <input
                    className="popup-image-modal-name-input"
                    defaultValue={itemComment.image.title}
                    ref={title}
                  />
                ) : (
                  <span className="image-modal-name">
                    {itemComment.image.title}
                  </span>
                )}
                <div className="image-modal-icon" onClick={handleToggleUpdate}>
                  <Button
                    image={deleteImg}
                    activation={true}
                    typeButton={typeButton}
                  />
                </div>
              </div>
            ) : (
              <div className="popup-image-modal-settings">
                <div className="image-modal-icon" onClick={handleDeleteImage}>
                  <Button
                    image={deleteImg}
                    activation={true}
                    typeButton={typeButton}
                  />
                </div>
                <div className="image-modal-icon">
                  <Button typeButton={typeButton}>
                    <VisibilityIcon style={iconStyles} />
                  </Button>
                </div>
                <div className="image-modal-icon">
                  <Button activation={true} typeButton={typeButton}>
                    <a
                      href={itemComment.image.data}
                      download={itemComment.image.title}
                      style={{
                        color: "inherit",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <DownloadIcon style={downloadStyles} />
                    </a>
                  </Button>
                </div>
                <div
                  className="image-modal-icon-double"
                  onClick={handleToggleUpdate}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <div className="double-icon-container">
                      <img
                        className="image-modal-icon-double-img"
                        src={updateImg}
                        alt=""
                      />
                      <img
                        className="image-modal-icon-double-img"
                        src={imageImg}
                        alt=""
                      />
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ImagePopupComment;
