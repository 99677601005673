import { useGlobalContext } from "../Context/Context";
import key from "../picture/Key.png";
import play from "../picture/Play.png";
import playGreen from "../picture/playGreen.png";
import playBlack from "../picture/playBlack.png";
import playBlue from "../picture/playBlue.png";
import playYellow from "../picture/playYellow.png";
import playRed from "../picture/PlayRed.png";
import axios from "axios";
import "./singleAccount.css";
import { useState } from "react";

const SingleAccount = ({ setSelectLocation, setSelectAccount, locations }) => {
  const {
    account,
    baseUrl,
    loggedUser,
    apiKey,
    setLocation,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
  } = useGlobalContext();

  const { colorButton, setColorButton } = useGlobalContext();
  let color = [
    "#ffffff",
    "#ffff00",
    "#ffbc14",
    "#00ff00",
    "#0000ff",
    "#2a2a2a",
  ];

  const [keyCompany, setKeyCompany] = useState(["", "", "", ""]);

  const handleChooseImage = () => {
    console.log(colorButton);
    if ("#CCC" === colorButton) {
      return play;
    }
    if ("#2a2a2a" === colorButton) {
      return playBlack;
    }
    if ("#ffff00" === colorButton) {
      return playYellow;
    }
    if ("#ffbc14" === colorButton) {
      return playRed;
    }

    if ("#00ff00" === colorButton) {
      return playGreen;
    }

    if ("#0000ff" === colorButton) {
      return playBlue;
    }
    if ("#ffffff" === colorButton) {
      return play;
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/, ""); // Ensure only digits are entered
    if (value.length === 1) {
      const valueArray = [...keyCompany];
      valueArray[index] = value;
      setKeyCompany(valueArray);

      // Move to the next input
      const nextInput = document.querySelector(
        `input[name=input-${index + 1}]`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !keyCompany[index] && index > 0) {
      // Move to the previous input
      const prevInput = document.querySelector(
        `input[name=input-${index - 1}]`
      );
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleEntryEditor = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/companies/checkEntryKey.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          company_id: account.id,
          entry_key: keyCompany.join(""),
        }
      );
      console.log(data);
      if (data.status === 200) {
        if (locations.length > 1) {
          setSelectAccount(1);
        } else {
          setSelectAccount(3);
          setLocation(locations[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="single-account">
        <div className="single-account-key">
          <img src={key} alt="Key" />
          {[0, 1, 2, 3].map((_, index) => (
            <input
              type="text"
              name={`input-${index}`}
              maxLength={1}
              key={index}
              value={keyCompany[index]}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              aria-label={`Key input ${index + 1}`}
            />
          ))}
        </div>
        <div className="play-button" onClick={handleEntryEditor}>
          <img src={handleChooseImage()} alt="Play" />
        </div>
        <div className="account-container">
          {account.logo === "" ? (
            <span
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText(account.name[0].toUpperCase())}{" "}
            </span>
          ) : (
            <img
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              src={account.logo}
              alt={handleConvertText(account.name)}
            />
          )}
          <span
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(account.name)}
          </span>
        </div>
      </div>

      <div className="single-account-landscape">
        <div className="sakl">
          <div className="single-account-key">
            <img src={key} alt="Key" />
            {[0, 1, 2, 3].map((_, index) => (
              <input
                type="text"
                name={`input-${index}`}
                maxLength={1}
                key={index}
                value={keyCompany[index]}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                aria-label={`Key input ${index + 1}`}
              />
            ))}
          </div>

          <div className="play-button" onClick={handleEntryEditor}>
            <img src={handleChooseImage()} alt="Play" />
          </div>
        </div>

        <div className="account-container">
          {account.logo === "" ? (
            <span
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText(account.name[0].toUpperCase())}{" "}
            </span>
          ) : (
            <img src={account.logo} alt={account.name} />
          )}
          <span
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(account.name)}
          </span>
        </div>
      </div>
    </>
  );
};

export default SingleAccount;
