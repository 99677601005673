import { Connections } from "../../data/api"
import UserBloc from "../blocs/user-bloc/UserBloc"

const ConnectedUsersList = () => {
  return (
    Connections.users.map((user,key) => (
      <UserBloc item={user} key={key} />
    ))
  )
}

export default ConnectedUsersList