import "./filterArchive.css";
import Button from "../../../Button/Button";
import message from "../../picture/1.png";
import startDate from "../../picture/5.png";
import file from "../../picture/6.png";
import link from "../../picture/7.png";
import image from "../../picture/8.png";
import chart from "../../picture/9.png";
import profile from "../../picture/10.png";
import search from "../../picture/11.png";
import EditorCalendar from "../../EditorCalendar/EditorCalendar";
import { useEffect, useRef, useState } from "react";
import { compareAsc, format } from "date-fns";
import CategoryFilter from "./CategoryFilter";
import UserFilter from "./UserFilter";
import { useGlobalContext } from "../../../Context/Context";

const FilterArchive = ({
  setArchive,
  archiveData,
  filterState,
  setFilterState,
  daysArchive,
  setFilterModalActive,
  setFilterListItemActive,
  setArchiveClicked,
  setArchiveActive,
  setFilteredData,
}) => {
  const [openChart, setOpenChart] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [calendarActive, setCalendarActive] = useState({
    value: false,
    dateToSet: 0,
  });
  const [countMessage, setCountMessage] = useState({
    messages: 0,
    documents: 0,
    links: 0,
    images: 0,
    items: 0,
  });
  const {
    typeButton,
    setTypeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
  } = useGlobalContext();
  const handleChangeColorButton = (index) => {
    const realIndex = index + 2; // prvi parametar krece od indeksa 2
    const filters = Object.values(filterState);
    const filtersName = Object.keys(filterState)[realIndex];
    const newValue =
      filters[realIndex].value === 2 ? 0 : filters[realIndex].value + 1;
    const newData = { count: filters[realIndex].count, value: newValue };
    setFilterState({ ...filterState, [filtersName]: newData });
  };

  const setColor = (value) => {
    switch (value) {
      case 0:
        return "gray";
      case 1:
        return "red";
      case 2:
        return "green";
    }
  };
  const handleSearch = () => {
    //setFilterModalActive(false);
    let filteredData = archiveData;
    let greenExists;
    let redExists;

    // filtriranje po datumu
    if (filterState.endDate === "") {
      const newFilteredData = [];
      filteredData.forEach((fd) => {
        console.log("prvi", fd);
        if (
          compareAsc(
            new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
            filterState.startDate
          ) === 0
        )
          newFilteredData.push(fd);
      });
      filteredData = newFilteredData;
    } else {
      const newFilteredData = [];
      filteredData.forEach((fd) => {
        console.log("prvi", fd);
        console.log(
          new Date(new Date(filterState.startDate).setHours(0, 0, 0, 0))
        );
        console.log(
          new Date(new Date(filterState.endDate).setHours(0, 0, 0, 0))
        );
        if (
          compareAsc(
            new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
            new Date(new Date(filterState.startDate).setHours(0, 0, 0, 0))
          ) >= 0 &&
          compareAsc(
            new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
            new Date(new Date(filterState.endDate).setHours(0, 0, 0, 0))
          ) <= 0
        )
          newFilteredData.push(fd);
      });
      filteredData = newFilteredData;
    }

    const items = Object.values(filterState);
    const dataItems = [items[2], items[3], items[4], items[5]];
    /*const itemsKeys = Object.values(filterState);
    const dataKeys = [itemsKeys[2], itemsKeys[3], itemsKeys[4], itemsKeys[5]];*/
    greenExists = dataItems.find((dataItem) => dataItem.value === 2);
    redExists = dataItems.find((dataItem) => dataItem.value === 1);
    // 4 sa strane
    // ako postoji zeleno, gledam samo zelene
    if (greenExists) {
      if (filterState.message.value === 2)
        filteredData = filteredData.filter(
          (item) => item.comments.length !== 0
        );
      if (filterState.documents.value === 2)
        filteredData = filteredData.filter(
          (item) => item.documents.length !== 0
        );
      if (filterState.image.value === 2)
        filteredData = filteredData.filter((item) => item.images.length !== 0);
      if (filterState.link.value === 2)
        filteredData = filteredData.filter((item) => item.links.length !== 0);
    } else if (redExists) {
      if (filterState.message.value === 1)
        filteredData = filteredData.filter(
          (item) => item.comments.length === 0
        );
      if (filterState.documents.value === 1)
        filteredData = filteredData.filter(
          (item) => item.documents.length === 0
        );
      if (filterState.image.value === 1)
        filteredData = filteredData.filter((item) => item.images.length === 0);
      if (filterState.link.value === 1)
        filteredData = filteredData.filter((item) => item.links.length === 0);
    }
    // kategorije
    if (filterState.category.length !== 0) {
      const filterCategories = filterState.category.map((cat) => cat.id);
      const newFilteredData = [];
      filteredData.forEach((fd) => {
        if (
          filterCategories.length ===
          fd.categories.filter((cat) => filterCategories.includes(cat)).length
        )
          newFilteredData.push(fd);
      });

      filteredData = newFilteredData;
    }

    // useri
    if (filterState.people.length !== 0) {
      const userIds = filterState.people.map((p) => p.user_id);
      filteredData = filteredData.filter((fd) =>
        userIds.some((item) => item === fd.user_id)
      );
    }
    console.log(filteredData);
    console.log(archiveData);
    /*
    const newFilteredData = filteredData.map((item) => { 
      console.log(item)
      console.log(item.document)
      const documents = item.document
      delete item["document"]
      return {...item,documents:documents};
    })*/
    // console.log(newFilteredData)
    const sortedFilteredData = filteredData.sort((a, b) => {
      return compareAsc(a.timeCreated, b.timeCreated);
    });
    setFilteredData(sortedFilteredData);
    setFilterModalActive(false);
    setFilterListItemActive(true);
    setArchiveClicked(false);
    setArchiveActive(true);
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };
  useEffect(() => {
    if (filterState.startDate !== "") {
      let greenExists;
      let redExists;
      let filteredData = archiveData;
      // filtriranje po datumu
      if (filterState.endDate === "") {
        const newFilteredData = [];
        filteredData.forEach((fd) => {
          if (
            compareAsc(
              new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
              filterState.startDate
            ) === 0
          )
            newFilteredData.push(fd);
        });
        filteredData = newFilteredData;
      } else {
        const newFilteredData = [];

        filteredData.forEach((fd) => {
          console.log("prvi", fd);
          if (
            compareAsc(
              new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
              new Date(new Date(filterState.startDate).setHours(0, 0, 0, 0))
            ) >= 0 &&
            compareAsc(
              new Date(new Date(fd.timeCreated).setHours(0, 0, 0, 0)),
              new Date(new Date(filterState.endDate).setHours(0, 0, 0, 0))
            ) <= 0
          )
            newFilteredData.push(fd);
        });
        filteredData = newFilteredData;
      }
      console.log(filteredData);
      console.log(filterState);

      const items = Object.values(filterState);
      const dataItems = [items[2], items[3], items[4], items[5]];
      /*const itemsKeys = Object.values(filterState);
      const dataKeys = [itemsKeys[2], itemsKeys[3], itemsKeys[4], itemsKeys[5]];*/
      greenExists = dataItems.find((dataItem) => dataItem.value === 2);
      redExists = dataItems.find((dataItem) => dataItem.value === 1);
      // 4 sa strane
      // ako postoji zeleno, gledam samo zelene
      if (greenExists) {
        if (filterState.message.value === 2)
          filteredData = filteredData.filter(
            (item) => item.comments.length !== 0
          );
        if (filterState.documents.value === 2)
          filteredData = filteredData.filter(
            (item) => item.documents.length !== 0
          );
        if (filterState.image.value === 2)
          filteredData = filteredData.filter(
            (item) => item.images.length !== 0
          );
        if (filterState.link.value === 2)
          filteredData = filteredData.filter((item) => item.links.length !== 0);
      } else if (redExists) {
        if (filterState.message.value === 1)
          filteredData = filteredData.filter(
            (item) => item.comments.length === 0
          );
        if (filterState.documents.value === 1)
          filteredData = filteredData.filter(
            (item) => item.documents.length === 0
          );
        if (filterState.image.value === 1)
          filteredData = filteredData.filter(
            (item) => item.images.length === 0
          );
        if (filterState.link.value === 1)
          filteredData = filteredData.filter((item) => item.links.length === 0);
      }

      // kategorije
      if (filterState.category.length !== 0) {
        const filterCategories = filterState.category.map((cat) => cat.id);
        const newFilteredData = [];
        filteredData.forEach((fd) => {
          if (
            filterCategories.length ===
            fd.categories.filter((cat) => filterCategories.includes(cat)).length
          )
            newFilteredData.push(fd);
        });

        filteredData = newFilteredData;
      }

      // useri
      if (filterState.people.length !== 0) {
        const userIds = filterState.people.map((p) => p.user_id);
        filteredData = filteredData.filter((fd) =>
          userIds.some((item) => item === fd.user_id)
        );
      }

      console.log(filteredData);

      let countMessages = 0;
      let countLinks = 0;
      let countDocuments = 0;
      let countImages = 0;
      filteredData.forEach((item) => {
        countMessages += item.comments.length;
        countLinks += item.links.length;
        countDocuments += item.documents.length;
        countImages += item.images.length;
      });

      setCountMessage({
        messages: countMessages,
        links: countLinks,
        documents: countDocuments,
        images: countImages,
        items: filteredData.length,
      });
    }
  }, [filterState, setFilterState]);

  console.log(filterState);
  return (
    <>
      <div className="filter-archive">
        <div className="wrap-dates">
          <div className="date">
            <div
              className="date-calendar"
              onClick={() => setCalendarActive({ value: true, dateToSet: 0 })}
            >
              <Button
                activation={true}
                typeButton={typeButton}
                active={filterState.startDate !== ""}
              >
                <div className="start-date-calendar-img">
                  <img src={startDate} alt="" />
                </div>
              </Button>
            </div>
            <span>
              {filterState.startDate === ""
                ? ""
                : format(filterState.startDate, "dd.MM.yyyy")}
            </span>
          </div>
          <div className="date">
            <div
              className="date-calendar"
              style={{
                pointerEvents: `${
                  filterState.startDate === "" ? "none" : "auto"
                }`,
              }}
              onClick={() => setCalendarActive({ value: true, dateToSet: 1 })}
            >
              <Button
                activation={true}
                typeButton={typeButton}
                active={filterState.endDate !== ""}
              >
                <div
                  className={`end-date-calendar-img ${
                    filterState.startDate == "" && "blurred"
                  }`}
                >
                  <img src={startDate} alt="" />
                </div>
              </Button>
            </div>
            <span>
              {filterState.endDate === ""
                ? ""
                : format(filterState.endDate, "dd.MM.yyyy")}
            </span>
          </div>
        </div>

        <div className="bottom-container">
          <div className="vertical-button-row-1">
            <div className="first-group">
              <div
                className="button-message"
                onClick={() => handleChangeColorButton(0)}
                style={{
                  pointerEvents: `${
                    filterState.startDate === "" ? "none" : "auto"
                  }`,
                }}
              >
                <Button activation={true} typeButton={typeButton}>
                  <div
                    className={`button-div ${setColor(
                      filterState.message.value
                    )}`}
                  >
                    <img src={message} alt="" />
                  </div>
                </Button>

                <div className="dot">{countMessage.messages}</div>
              </div>
            </div>

            <div className="first-group">
              <div
                className="file-message switch-toggle"
                onClick={() => handleChangeColorButton(1)}
                style={{
                  pointerEvents: `${
                    filterState.startDate === "" ? "none" : "auto"
                  }`,
                }}
              >
                <Button activation={true} typeButton={typeButton}>
                  <div
                    className={`button-div ${setColor(
                      filterState.documents.value
                    )}`}
                  >
                    <img src={file} alt="" />
                  </div>
                </Button>
                <div className="dot">{countMessage.documents}</div>
              </div>
            </div>

            <div className="first-group">
              <div
                className="link-message switch-toggle"
                onClick={() => handleChangeColorButton(2)}
                style={{
                  pointerEvents: `${
                    filterState.startDate === "" ? "none" : "auto"
                  }`,
                }}
              >
                <Button activation={true} typeButton={typeButton}>
                  <div
                    className={`button-div ${setColor(filterState.link.value)}`}
                  >
                    <img src={link} alt="" />
                  </div>
                </Button>
                <div className="dot">{countMessage.links}</div>
              </div>
            </div>

            <div className="first-group">
              <div
                className="image-message switch-toggle"
                onClick={() => handleChangeColorButton(3)}
                style={{
                  backgroundColor: `${setColor(filterState.image.value)}`,
                  pointerEvents: `${
                    filterState.startDate === "" ? "none" : "auto"
                  }`,
                }}
              >
                <Button activation={true} typeButton={typeButton}>
                  <div
                    className={`button-div ${setColor(
                      filterState.image.value
                    )}`}
                  >
                    <img src={image} alt="" />
                  </div>
                </Button>
                <div className="dot">{countMessage.images}</div>
              </div>
            </div>
          </div>

          <div className="vertical-button-row-2">
            {openChart && (
              <CategoryFilter
                filterState={filterState}
                setFilterState={setFilterState}
                setOpenChart={setOpenChart}
                isFromFilter={true}
              />
            )}
            <div
              className="chart"
              onClick={() => setOpenChart(!openChart)}
              style={{
                pointerEvents: `${
                  filterState.startDate === "" ? "none" : "auto"
                }`,
              }}
            >
              <Button
                active={openChart}
                image={chart}
                typeButton={typeButton}
              />
            </div>
            {openUser && (
              <UserFilter
                filterState={filterState}
                setFilterState={setFilterState}
                isFromFilter={true}
              />
            )}

            <div
              onClick={() => setOpenUser(!openUser)}
              className="profile"
              style={{
                pointerEvents: `${
                  filterState.startDate === "" ? "none" : "auto"
                }`,
              }}
            >
              <Button
                active={openUser}
                image={profile}
                typeButton={typeButton}
              />
            </div>
          </div>

          <div className="vertical-button-row-3">
            <div className="colors">
              <div className="row-flex">
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText("SVE STAVKE")}
                </span>{" "}
                {/* promena jezika */}
                <div className="info-color gray"></div>
              </div>

              <div className="row-flex">
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText("STAVKE BEZ")}
                </span>{" "}
                {/* promena jezika */}
                <div className="info-color red"></div>
              </div>

              <div className="row-flex">
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText("STAVKE SA")}
                </span>
                <div className="info-color green"></div>
              </div>
            </div>

            <div className="search-container">
              <span>PRIKAŽI</span>
              <div
                className="search"
                style={{
                  pointerEvents: `${
                    filterState.startDate === "" ? "none" : "auto"
                  }`,
                }}
                onClick={handleSearch}
              >
                <Button image={search} typeButton={typeButton} />
                <div className="dot-search">{countMessage.items}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {calendarActive.value && (
        <EditorCalendar
          filterState={filterState}
          setFilterState={setFilterState}
          setArchive={setArchive}
          archiveData={archiveData}
          calendarActive={calendarActive}
          setCalendarActive={setCalendarActive}
          daysArchive={daysArchive}
        />
      )}
    </>
  );
};

export default FilterArchive;
