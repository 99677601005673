import { useLocation } from 'react-router-dom'
import {CommentIdleIcon, CvIcon, DownloadBottomArrow, MailLeftArrow, PhoneIcon, ShareIcon, UserFilled} from '../../assets'

import './userPage.css'

const UserPage = ({location, profession, education, field, interest, language}) => {
  
    // Mock data to delete later
    location = 'Sweden'
    profession = 'Doctor'
    education = 'Doctorate in Science'
    field = 'Human Science'
    interest = 'Finance'
    language = 'Romanian'


    const ContactBtn = ({name, icon, link='#'}) => {
        return (
            <a href={link}>
                <div className="contact-btn flex-center-col">
                    <img src={icon} className='contact-btn--img' alt={name} />
                </div>
            </a>
        )
        
    }

    const linkLocation = useLocation()

    return (
    <div className="user-page flex-center-col" style={{paddingBottom: '1rem'}}>
        <div className="user-page--head-icons flex-apart">
            <div className="head-icon head-icon-bordered flex-center-col">
                <img src={UserFilled} alt="User icon" className="icon-img" />
            </div>
            <div className="head-icon flex-center-col">
                <img src={CvIcon} alt="CV icon" className="icon-img"  style={{width: '5.5rem'}} />
            </div>
            <div className="head-icon head-icon-bordered flex-center-col">
                <img src={PhoneIcon} alt="Phone icon" className="icon-img" />
            </div>
        </div>
        <div className="user-page--details">
            <ul className="flex-col">
                <li className="user-detail">{linkLocation.state.id}</li>
                <li className="user-detail">{education}</li>
                <li className="user-detail">{profession}</li>
                <li className="user-detail">{field}</li>
                <li className="user-detail">{interest}</li>
                <li className="user-detail">{language}</li>
            </ul>
        </div>
        <div className="user-page--contact-btns flex-apart">
            <ContactBtn name='share' icon={ShareIcon} />
            <ContactBtn name='download' icon={DownloadBottomArrow} />
            <ContactBtn name='mail' icon={MailLeftArrow} />
            <ContactBtn name='message' icon={CommentIdleIcon} />
        </div>
    </div>
  )
}

export default UserPage