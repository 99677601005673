import "./languageTranslate.css";
import Button from "../../Button/Button";
import Send from "../../picture/Send.png";
import axios from "axios";
import MailOne from "../../picture/TrustEmaiUser1.png";
import MailTwo from "../../picture/TrustMessageUser2.png";
import { useGlobalContext } from "../../Context/Context";
import PlayLanguage from "../../picture/PlayLanguage.png";
import { useRef } from "react";

const LanguageTranslate = ({
  translateLanguage,
  setCurrentPage,
  setTranslateID,
}) => {
  const participant1Ref = useRef();
  const participant2Ref = useRef();

  const { selectedLanguage, loggedUser, apiKey, letterSize } =
    useGlobalContext();
  const { typeButton, boldItalic, fontSize, fontStyle } = useGlobalContext();
  const { baseUrl } = useGlobalContext();

  const handleStartTranslate = async () => {
    console.log("ID jezika na koji se prvodi", translateLanguage);
    console.log("User koji zahteva jezik", loggedUser);
    console.log("Participant 1", participant1Ref.current);
    console.log("Participant 2", participant2Ref.current);
    console.log("APiKey", apiKey);

    const url = `${baseUrl}/translation_users/create.php`;
    const data = {
      language_id: translateLanguage,
      user_id: loggedUser.id,
      api_key: apiKey,
      participant1: participant1Ref.current,
      participant2: participant2Ref.current,
    };

    try {
      const response = await axios.post(url, data);
      console.log("Response:", response.data);
      if (response.data.status === 200) {
        setCurrentPage(4);
        setTranslateID(response.data.translation_id);
      }
      // localStorage.setItem("translation_id", response.data.translation_id);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handelInputMailTwo = (e) => {
    participant2Ref.current = e.currentTarget.value;
  };
  const handelInputMailOne = (e) => {
    participant1Ref.current = e.currentTarget.value;
  };

  return (
    <div className="wrapper-translate">
      <div className="wrapper-table-user">
        <table className="table-language">
          <thead
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            <tr className="first-row">
              <th>N</th>
              <th>{handleConvertText("ENGLISH")}</th>
              <td>{handleConvertText("Ocena klikom")}</td>
            </tr>
          </thead>
          <tbody
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            <tr key={0}>
              <td className="first-column" style={{ backgroundColor: "green" }}>
                9
              </td>
              <td>{handleConvertText("SEND")}</td>
              <td>{handleConvertText("1x = TACNO")}</td>
            </tr>
            <tr key={1}>
              <td className="first-column">37</td>
              <td>{handleConvertText("AGREE")}</td>
              <td>{handleConvertText("0/3x = NE ZNAM")}</td>
            </tr>
            <tr key={2}>
              <td className="first-column" style={{ backgroundColor: "red" }}>
                114
              </td>
              <td>{handleConvertText("ADD")}</td>
              <td>{handleConvertText("2x = NETACNO")}</td>
            </tr>
          </tbody>
        </table>
        <div className="language-translate-start-button">
          <Button typeButton={typeButton}>
            <img src={PlayLanguage} alt="" className="visible-img" />
          </Button>
        </div>
      </div>
      <div className="text-translate">
        <span className="text-translate-title">
          {selectedLanguage?.data.titleTranslate}
        </span>
        <span className="text-translate-body">
          {selectedLanguage.data.textTranslate}
        </span>
      </div>
      <div className="input-mail">
        <img src={MailOne} alt="" className="" />
        <input type="text" onChange={handelInputMailOne} />
      </div>
      <div className="input-mail">
        <img src={MailTwo} alt="" className="" />
        <input type="text" onChange={handelInputMailTwo} />
      </div>
      <div className="language-translate-button" onClick={handleStartTranslate}>
        <Button activation={true} typeButton={typeButton}>
          <img src={Send} alt="" className="visible-img" />
        </Button>
      </div>
    </div>
  );
};

export default LanguageTranslate;
