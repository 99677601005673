import Button from "../../Button/Button";
import { useGlobalContext } from "../../Context/Context";
import "./categoryModal.css";
import { useEffect, useRef, useState } from "react";
const CategoryModal = ({
  itemData,
  setItemData,
  isAdding,
  categoryModalActive,
  order,
}) => {
  console.log(itemData);
  console.log(order);
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();

  const settingLocalCategory = () => {
    console.log("Poziv metode");
    if (categoryModalActive === undefined) {
      return itemData?.categories;
    }

    if (categoryModalActive === true) {
      return itemData;
    }
    /*
        if (imageModalActive.isCommenting) {
          return realItemData.images;
        }
    
        if (imageModalActive.isCommenting === undefined) {
          return [
            itemData?.comments !== undefined
              ? itemData.comments[imageModalCommentActive.value].image
              : realItemData.images,
          ];
        }
    
        if (imageModalActive.isCommenting) {
          return realItemData.images;
        } else {
          return itemData.images;
        }*/
    return [];
  };

  const [localCategory, setLocalCategory] = useState(settingLocalCategory());

  console.log(localCategory);
  console.log(itemData);

  const handleSelectCategory = (index) => {
    const newItemCategories = itemData.categories.map((item, i) => {
      if (i === index) {
        return { ...item, selected: !item.selected };
      } else return item;
    });
    console.log(newItemCategories);
    setItemData({ ...itemData, categories: newItemCategories });
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  console.log(itemData);

  return (
    <div className="category-modal">
      {localCategory?.map((category, index) => {
        console.log(category);
        return (
          <div
            className="category-modal-item-button"
            onClick={() => handleSelectCategory(index)}
          >
            <Button active={category.selected} typeButton={typeButton}>
              <div className="category-modal-item">
                <div className="category-modal-item-color">
                  <div
                    className="category-modal-item-color-letter"
                    style={{ backgroundColor: category.color }}
                  >
                    <div
                      className="category-modal-item-letter"
                      style={{ color: category.letter_color }}
                    >
                      {category.letter}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "category-modal-item-name " +
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(category.name.toUpperCase())}
                </div>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryModal;
