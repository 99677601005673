import { useGlobalContext } from "../../../Context/Context";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "../../../Button/Button";
import userPicture from "../../10.png";
import "./userFilter.css";

const UserFilter = ({ filterState, setFilterState, isFromFilter }) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const [users, setUsers] = useState([]);

  const checkIfExists = (id) => {
    if (filterState?.people === undefined) {
      return false;
    } else {
      const item = filterState.people.find((peopl) => peopl.user_id === id);
      if (item) return true;
      else return false;
    }
  };

  const handleSelectedUser = (user) => {
    if (!checkIfExists(user.user_id))
      setFilterState({
        ...filterState,
        people: [...filterState.people, user],
      });
    else {
      const newPeople = filterState.people.filter(
        (item) => item.user_id !== user.user_id
      );
      setFilterState({ ...filterState, people: newPeople });
    }
  };

  console.log(filterState);

  useEffect(() => {
    const handleUser = async () => {
      try {
        axios
          .post(`${baseUrl}/user_location_privilege/getUsersByLocationId.php`, {
            location_id: location.location_id,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            console.log(data);
            if (data.status === 200) {
              console.log("Postavlja se vrednost kategorije");

              setUsers(data.data);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleUser();
  }, []);

  console.log(filterState);

  return (
    <div
      className={isFromFilter ? "wraper-filter-user" : "wrapper-document-user"}
    >
      {users.map((user) => {
        const { user_id, user_name } = user;
        return (
          <div
            className="user-filter-modal-item-button"
            key={user_id}
            onClick={() => handleSelectedUser(user)}
          >
            <Button active={checkIfExists(user_id)} typeButton={typeButton}>
              <div className="user-filter-modal-item">
                <div className="user-filter-modal-item-color">
                  <img
                    src={userPicture}
                    className="user-filter-modal-item-color-letter"
                  />
                </div>
                <div className="user-filter-modal-item-name">
                  {user_name.toUpperCase()}
                </div>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};
export default UserFilter;
