import Button from "../../Button/Button";
import cancelImg from "../zatvaranje.png";
import downloadImg from "../../picture/DownloadFile.png";
import { useGlobalContext } from "../../Context/Context";
import axios from "axios";

const DetailedFile = ({
  file,
  index,
  detailedFile,
  setDetailedFile,
  documents,
  setDocuments,
}) => {
  const { loggedUser, apiKey, baseUrl, location } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const handleActiveFile = (index) => {
    if (detailedFile.find((file) => file === index))
      setDetailedFile(detailedFile.filter((file) => file !== index));
    else setDetailedFile(detailedFile.filter((file) => file !== index));
  };

  const convertBytes = (bytes) => {
    const bytesLength = bytes.toString().length;
    let returnValue;
    if (bytesLength <= 4)
      returnValue = `${Math.round(bytes / Math.pow(1024, 1), 2)}B`;
    else if (bytesLength <= 7)
      returnValue = `${Math.round(bytes / Math.pow(1024, 2), 2)}KB`;
    else if (bytesLength <= 11)
      returnValue = `${Math.round(bytes / Math.pow(1024, 3), 2)}MB`;
    else returnValue = `${Math.round(bytes / Math.pow(1024, 4), 2)}GB`;
    return returnValue;
  };

  const handleDelete = async (doc) => {
    const newDocuments = documents.filter((d) => d.id !== doc.id);
    setDocuments(newDocuments);
    try {
      axios
        .post(`${baseUrl}/location_documents/delete.php`, {
          user_id: loggedUser.id,
          api_key: apiKey,
          id: doc.id,
        })
        .then((data) => console.log(data));
    } catch (error) {
      console.log("Error deleting document");
    }
  };

  const handleDownload = async (doc) => {
    try {
      axios
        .post(`${baseUrl}/location_documents/getDocumentData.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          location_document_id: doc.id,
        })
        .then((data) => {
          console.log(data);
          const a = document.createElement("a");
          a.href = data.data.data;
          a.download = doc.name;
          a.click();
        });
    } catch (error) {
      console.log("Error deleting document");
    }
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="detailed-file" onClick={() => handleActiveFile(index)}>
      <div className="detailed-file-header">
        <span className="detailed-file-number">{index}.</span>
        <span
          className={
            "detailed-file-name " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(file.user_name)}
        </span>
        <span className="detailed-file-time">{file.date}</span>
      </div>
      <div className="detailed-file-middle">
        <span
          className={
            "detailed-file-title " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(file.name)}
        </span>
        <div className="detailed-file-data">
          <span>{convertBytes(file.size)}</span>
          <span>.{file.extension}</span>
        </div>
      </div>
      <div className="detailed-file-footer">
        <div className="detailed-file-description">{file.description}</div>
        <div
          className="detailed-file-btn"
          onClick={() =>
            loggedUser.id === file.user_id
              ? handleDelete(file)
              : handleDownload(file)
          }
        >
          <Button typeButton={typeButton}>
            <img
              className="detailed-file-btn-img"
              src={loggedUser.id === file.user_id ? cancelImg : downloadImg}
              alt=""
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailedFile;
