import { useEffect } from "react";
import Button from "../Button/Button";
import "./registerChoose.css";
import { useGlobalContext } from "../Context/Context";
const RegisterChoose = ({ setCurrentPage }) => {
  const { selectedLanguage } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const handleStyleInput = () => {
    const registerChoose = document.querySelector(".registration-choose");
    let rect;
    if (registerChoose === null) {
      rect = 20;
    } else {
      rect = registerChoose.getBoundingClientRect().height;
    }
    const registerText = document.querySelectorAll(".registration-text");
    registerText.forEach((input) => {
      input.style.fontSize = ` ${(rect * 20) / 100}px`;
    });
  };
  const handleSelectPassword = () => {
    setCurrentPage(0);
  };

  const handleCopyText = () => {
    setCurrentPage(8);
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    handleStyleInput();
  }, []);

  window.onresize = () => {
    handleStyleInput();
  };

  return (
    <div className="registration-wrapper">
      <div className="registration-choose" onClick={handleSelectPassword}>
        <Button activation={true} typeButton={typeButton}>
          <a
            href={`mailto:registracija@nadzor.app?subject=Registration request&body=I wish to create a private account with my email address.`}
            className={
              "registration-text " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(selectedLanguage.data.givePassword)}
          </a>
        </Button>
      </div>
      <div className="registration-choose" onClick={() => setCurrentPage(3)}>
        <Button typeButton={typeButton}>
          <span
            className={
              "registration-text " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText(selectedLanguage.data.havePassword)}
          </span>
        </Button>
      </div>
      <div className="registration-choose" onClick={handleCopyText}>
        <Button typeButton={typeButton}>
          <span
            className={
              "registration-text " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {" "}
            {handleConvertText(`${selectedLanguage.data.sendMail}`)}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default RegisterChoose;
