import { useState, useEffect } from "react";
import "./homePage.css";
import Company from "../picture/Company.png";
import Tempo from "../picture/Tempo.png";
import WwwLink from "../picture/WwwLink.png";
import PdfItem from "../picture/PdfItem.png";
import PeopleHome from "../picture/PeopleHome.png";
import LanguageHome from "../picture/LanguageHome.png";
import WwwData from "../picture/WwwData.png";
import AddPeople from "../picture/AddPeople.png";
import AddMessage from "../picture/AddMessage.png";
import LogOut from "../picture/LogOut.png";
import SearchHome from "../picture/SearchHome.png";

import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";
import MessageLogOut from "../MessageLoggOut/MessageLogOut";

const HomePage = ({
  setSelectAccount = { setSelectAccount },
  setCurrentPage,
}) => {
  const [showLogOut, setShowLogOut] = useState(false);
  const [chooseOption, setChooseOption] = useState({ index: -1, value: false });

  useEffect(() => {
    const handlePopState = (event) => {
      console.log("Back button clicked or browser history changed");
      setCurrentPage(4);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const { setLoggedUser, setIsFromHome } = useGlobalContext();
  const { apiKey, baseUrl, loggedUser } = useGlobalContext();
  const { typeButton } = useGlobalContext();

  const array = [
    { image: Tempo },
    { image: WwwLink },
    { image: LanguageHome },
    { image: WwwData },
    { image: AddPeople },
    { image: AddMessage },
    { image: LogOut },
    { image: SearchHome },
  ];
  const handleChoseOption = (index) => {
    let timeOut = setTimeout(() => {
      setChooseOption({ index: -1, value: false });
    }, 200);
    setChooseOption({ index: index, value: true });
  };

  const handleChooseItem = (index) => {
    console.log(index);
    handleChoseOption(index);
    if (index === 6) {
      // ODJAVA//
      setShowLogOut(true);
    }
    if (index === 0) {
      //back to start
      setCurrentPage(0);
      setLoggedUser(undefined);
      setIsFromHome(true);
    }
    if (index === 1) {
      const link = document.createElement("a");
      link.href = "https://web.nadzor.app/create.php";
      link.click();
    }
    if (index === 5 || index === 4 || index === 3 || index === 2) {
      setSelectAccount(index + 6);
    }
  };

  return (
    <>
      <h2 className="home-email">{loggedUser.user_email}</h2>
      <div className="home-page">
        {array.map((item, index) => {
          return (
            <div
              className="wrapper-item-button"
              onClick={() => handleChooseItem(index)}
            >
              <Button
                typeButton={typeButton}
                active={chooseOption.index === index}
              >
                <div className="home-page-image">
                  <img src={item.image} alt=""></img>
                </div>
              </Button>
            </div>
          );
        })}
      </div>
      {showLogOut && (
        <MessageLogOut
          setActiveExit={setShowLogOut}
          setCurrentPage={setCurrentPage}
        ></MessageLogOut>
      )}
    </>
  );
};

export default HomePage;
