import React, { useState, useEffect } from "react";

import {Loader, CircledButton, FeedPostsList, StickyMenu} from "../../components";
import { useFetchPosts } from "../../data/useFetchAPI";

import "./userFeed.css";
import { CategoryActiveIcon, CategoryIdleIcon, EyeIdleIcon, FavIdleIcon, SaveActiveIcon, SaveIdleIcon, SortIdleIcon } from "../../assets";




function UserFeed() {
  const [postList, setPostList] = useState([])

  let fetchedPosts = useFetchPosts()

  useEffect(() => {
    setPostList(fetchedPosts)
    

    // Alert : This will cause the api to be called excessively
  }, [fetchedPosts])
  
  const toggleCategories = () => {
    console.log('categories')
  }

  const stickyButtons = [
    {link: './', idleImage: CategoryIdleIcon, activeImage: CategoryActiveIcon, handleEffect: toggleCategories, toggle: true},
    {link: './', idleImage: EyeIdleIcon, activeImage: EyeIdleIcon, handleEffect: () => {console.log('2')}, toggle: true, extraStyling:{width: '11vw'}},
    {link: './', idleImage: SortIdleIcon, activeImage: SortIdleIcon, handleEffect: () => {console.log('3')}, toggle: false, extraStyling:{width: '9vw'}},
    {link: './', idleImage: SaveIdleIcon, activeImage: SaveActiveIcon, handleEffect: () => {console.log('4')}, toggle: false, extraStyling:{height: "10vw", width: '8vw', marginTop: '1vw'}}
  ]

  return (
      <div className='flex-center-col feed-list' style={{width: '95%', margin: '0 auto'}}>  
        <StickyMenu buttonsList={stickyButtons} />
  
        <div className='posts-list list flex-center-col' style={{gap: '1rem', width: '100%'}}>
          {postList?.length ? <><FeedPostsList list={postList} /><FeedPostsList list={postList} /></> : <Loader />}
        </div>
      </div>
    );
  
}

export default UserFeed;
