import { useGlobalContext } from "../../../Context/Context";
import starImg from "../../zvezda.png";
import "./editorEntries.css";

const EditorEntries = ({ isNameEntered, itemData, setItemData }) => {
  const { letterSize, boldItalic, fontSize, fontStyle } = useGlobalContext();

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleDataChange = (e, ID) => {
    if (!isNameEntered) return;
    let newDataItems = itemData.dataItems.map((id) => {
      if (id.id === ID)
        return { ...id, data: handleConvertText(e.currentTarget.value) };
      else return id;
    });
    setItemData({ ...itemData, dataItems: newDataItems });
    /*if (
      e.currentTarget.value === "" &&
      !e.currentTarget.classList.contains("empty")
    )
      e.currentTarget.classList.add("empty");*/
    if (
      e.currentTarget.value !== "" &&
      e.currentTarget.classList.contains("empty")
    )
      e.currentTarget.classList.remove("empty");
  };

  return (
    <div
      className={`${
        itemData.dataItems?.length === 1
          ? "editor-data-entries-wrapper single-data"
          : "editor-data-entries-wrapper"
      }`}
    >
      <div className="editor-data-entries">
        {itemData?.dataItems?.map((item, index) => {
          const { id, name, important, data } = item;

          return (
            <article className="editor-data-entry" key={index}>
              <div className="editor-data-entry-header">
                <h5 className="editor-data-entry-title">{name}</h5>
                {important && <img src={starImg} alt="" />}
              </div>
              <textarea
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
                className={
                  "editor-data-entry-textarea " +
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                value={data}
                onChange={(e) => handleDataChange(e, id)}
              />
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default EditorEntries;
