import { useEffect, useState } from "react";
import axios from "axios";

import { useGlobalContext } from "../../Context/Context";
import { fetchDataByLinkBody } from "./fetchAPI";


export const useFetchPosts = () => {
    const [posts, setPosts] = useState({});
  
    const { apiKey, loggedUser } = useGlobalContext();
    const link = "/relations/posts/readAllPosts.php"
  
    const body = {
        api_key: apiKey,
        user_id: loggedUser?.id,
        profile_id: loggedUser?.profileId,
    };
    
    useEffect(() => {
      try {
        fetchDataByLinkBody(link,body).then(data => setPosts(data))
      } catch (error) {
        console.log(error)
      }
    }, [])
    return posts
};
