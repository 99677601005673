import './connectedUsers.css'
import { CircledButton, ConnectedUsersList, StickyMenu } from '../../components'
import { BlockIdleIcon, FavIdleIcon, FollowersIdleIcon, FollowingIdleIcon } from '../../assets'


const ConnectedUsers = () => {
  
  const toggleCategories = () => {console.log('categories')}
  
  const stickyButtons = [
    {link: './', idleImage: FollowingIdleIcon, activeImage: FollowingIdleIcon, handleEffect: toggleCategories, toggle: true},
    {link: './', idleImage: FollowersIdleIcon, activeImage: FollowersIdleIcon, handleEffect: () => {console.log('2')}, toggle: true},
    {link: './', idleImage: BlockIdleIcon, activeImage: BlockIdleIcon, handleEffect: () => {console.log('3')}, toggle: false},
    {link: './', idleImage: FavIdleIcon, activeImage: FavIdleIcon, handleEffect: () => {console.log('4')}, toggle: false}
  ]

  return (
    <div className='connected-users'>
        
        <StickyMenu buttonsList={stickyButtons}/>
          
        <div className='connected-users--list list flex-center-col' style={{gap: '1rem', width: '100%'}}>
          <ConnectedUsersList />
        </div>
    </div>
  )
}

export default ConnectedUsers