import './loader.css'

const Loader = () => {
  return (
    <div className="loading-screen flex-center-col">
        <div className="clock">
            <div className="minutes"></div>
            <div className="hours"></div>
        </div>
    </div>
  )
}

export default Loader