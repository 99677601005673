import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import icon from "./icons/icon.png";
import plane from "./icons/plane.png";
import Unlockscreen from "../../unlockScreen/Unlockscreen";
import PinUnlock from "../../pinUnlock/PinUnlock";
import { NorthWest, RestaurantMenu } from "@mui/icons-material";
import Button from "../../Button/Button";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";

function ControlScreen({
  setCurrentPagePassword,
  setCorrectPattern,
  correctPattern,
  haveTrustContact,
  setUsePattern,
  usePattern,
  setInputTrustContact,
  inputTrustContact,
  usePatternAnswer,
  setUserPatternAnswer,
}) {
  //odgovori usera
  const [inputPattern, setInputPattern] = useState([]);
  //
  const [isPattern, setIsPattern] = useState(false);
  const [resetSwitch, setResetSwitch] = useState(false);
  const [inputTrustContactText, setInputTrustContactText] = useState("");
  const { controlEmail, baseUrl } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const isDownMouse = useRef();
  const noEvent = useRef();

  const handleUnlockDown = () => {
    if (noEvent.current) {
      isDownMouse.current = true;
      console.log("Pozvao se down", isDownMouse.current);
    }
  };

  const handleUnlockMove = () => {
    if (noEvent.current && isDownMouse.current) {
      setIsPattern(false);
      noEvent.current = false;
    }
  };

  const handleUnlockUp = () => {
    console.log("Pozvao se up", isDownMouse.current);

    if (noEvent.current && isDownMouse.current) {
      setIsPattern(true);
      setInputPattern([]);
      noEvent.current = false;
    }
  };
  const handleChosePin = async (answer) => {
    if (
      usePatternAnswer === undefined ||
      (usePatternAnswer !== undefined && answer !== usePatternAnswer)
    ) {
      try {
        const { data } = await axios.post(
          `${baseUrl}/control/checkIfPinIsUsed.php`,
          {
            user_email: controlEmail,
            pattern_use: answer,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setUserPatternAnswer(answer);
          setUsePattern(data.correct);
        }
        //setUsePattern(data.has_trusted_contact);
      } catch (error) {
        console.log(error);
      }
    } else {
      setUserPatternAnswer(undefined);
      setUsePattern(undefined);
    }
  };

  const handleInputTrustContact = (e) => {
    setInputTrustContactText(e.currentTarget.value);
  };

  const handleSendControl = () => {
    if (usePatternAnswer === undefined) return;
    if (inputPattern.length === 0) return;

    if (haveTrustContact) {
      //ima pouzdani kontakt
      if (usePatternAnswer) {
        //odgovor koristi pouzdani kontakt
        if (inputTrustContactText.length === 0) {
          //nije uneo pouzdani kontakt
          if (usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(3);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
        } else {
          if (usePattern && correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(4);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (!usePattern && correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(4);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (usePattern && !correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(5);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (!usePattern && !correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(5);
            } else {
              setCurrentPagePassword(2);
            }
          }
          //uneo pouzdani kontakt
        }
      } else {
        //odgovor ne koristi pouzdani kontakt
        if (inputTrustContactText.length === 0) {
          //nije uneo pouzdani kontakt
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && correctPattern) {
            setCurrentPagePassword(3);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
        } else {
          if (!usePattern && correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(4);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (usePattern && correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(4);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (!usePattern && !correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(5);
            } else {
              setCurrentPagePassword(2);
            }
          }
          if (usePattern && !correctPattern) {
            if (inputTrustContact) {
              setCurrentPagePassword(5);
            } else {
              setCurrentPagePassword(2);
            }
          }
          //uneo pouzdani kontakt
        }
      }
      console.log("Ima pouzdani kontakt");
    } else {
      //nema pouzdani kontakt
      if (usePatternAnswer) {
        //odgovor  koristi pouzdani kontakt
        if (inputTrustContactText.length === 0) {
          //nije uneo pouzdani kontakt
          if (usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
        } else {
          //uneo pouzdani kontakt
          if (usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
        }
      } else {
        //odgovor da ne koristi pouzdani kontakt
        if (inputTrustContactText.length === 0) {
          //nije uneo pouzdani kontakt
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(5);
          }
        } else {
          if (!usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && correctPattern) {
            setCurrentPagePassword(2);
          }
          if (!usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
          if (usePattern && !correctPattern) {
            setCurrentPagePassword(2);
          }
          //uneo pouzdani kontakt
        }
      }

      console.log("Nema pouzdani kontakt.");
    }
  };

  const handleCheckInputTrustContact = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/control/checkIfTContactIsCorrect.php`,
        {
          user_email: controlEmail,
          trusted_contact_email: inputTrustContactText,
        }
      );
      console.log(data);
      if (data.status === 200) {
        setInputTrustContact(data.correct);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    const handleInputPinCorrect = async () => {
      let inputPatternString = "";
      inputPattern.forEach((element) => {
        inputPatternString += element;
      });

      if (inputPatternString !== "") {
        try {
          const { data } = await axios.post(
            `${baseUrl}/control/checkIfPinIsCorrect.php`,
            {
              user_email: controlEmail,
              user_pattern: inputPatternString,
            }
          );
          console.log(data);
          if (data.status === 200) {
            setCorrectPattern(data.correct);

            let setCurrsor = true;

            if (haveTrustContact && !data.correct) {
              if (!usePatternAnswer) {
                if (!usePattern) {
                  setCurrsor = false;
                }
              } else {
                if (usePattern) {
                  setCurrsor = false;
                }
              }
            }
            if (!haveTrustContact && data.correct) {
              if (!usePatternAnswer) {
                if (usePattern) {
                  setCurrsor = false;
                }
              } else {
                if (!usePattern) {
                  setCurrsor = false;
                }
              }
            }
            if (!haveTrustContact && !data.correct) {
              if (usePatternAnswer) {
                if (!usePattern) {
                  setCurrsor = false;
                }
              } else {
                if (usePattern) {
                  setCurrsor = false;
                }
              }
            }

            const inputTrustContact = document.querySelector(
              ".controle-screen-input"
            );
            if (setCurrsor) {
              inputTrustContact.focus();
            } else {
              inputTrustContact.blur();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    handleInputPinCorrect();
  }, [inputPattern]);

  useEffect(() => {
    noEvent.current = true;
    isDownMouse.current = false;
  }, []);

  return (
    <div className="control-screen-container">
      <div className="account-protected">
        <h3
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("Moj nalog je zaštićen?")}
        </h3>
        <div className="account-protected-buttons">
          <div
            className="account-protected-button-wrap"
            onClick={() => handleChosePin(true)}
          >
            <Button
              typeButton={typeButton}
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              active={usePatternAnswer !== undefined ? usePatternAnswer : false}
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("DA")}
            </Button>
          </div>
          <div
            className="account-protected-button-wrap"
            onClick={() => handleChosePin(false)}
          >
            <Button
              typeButton={typeButton}
              className={
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              active={
                usePatternAnswer !== undefined ? !usePatternAnswer : false
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("NE")}
            </Button>
          </div>
        </div>
      </div>

      <div
        className="pin-or-pattern"
        onMouseDown={handleUnlockDown}
        onMouseMove={handleUnlockMove}
        onMouseUp={handleUnlockUp}
      >
        {isPattern ? (
          <PinUnlock
            height={220}
            width={242}
            setInputPattern={setInputPattern}
            draft={true}
            resetSwitch={resetSwitch}
            setResetSwitch={setResetSwitch}
          />
        ) : (
          <Unlockscreen
            type={"second"}
            winPattern={""}
            draft={true}
            setInputPattern={setInputPattern}
          />
        )}
      </div>

      <div className="message-mid">
        <h4
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText("KONTROLA PRISTUPA")}
        </h4>
        <p
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(
            "Molimo vas da odgovorite na gore postavljeno pitanje i iscrtate sablon ili unesete svoj PIN, a ako imate pouzdani kontakt dole navedite njegovu mail adresu, i zahtevajte verifikacioni KOD."
          )}
        </p>
      </div>

      <div className="enter-trusted-contact">
        <img src={icon} alt="" />
        <input
          className="controle-screen-input"
          type="text"
          onChange={handleInputTrustContact}
          onBlur={handleCheckInputTrustContact}
        />
      </div>

      <div className="confirm-trusted-contact" onClick={handleSendControl}>
        <Button
          isDisabled={
            usePatternAnswer === undefined || inputPattern.length === 0
          }
          activation={true}
          typeButton={typeButton}
        >
          <img src={plane} alt="" />
        </Button>
      </div>
    </div>
  );
}

export default ControlScreen;
