import { useEffect } from "react";
import { useGlobalContext } from "../Context/Context";
import { PDFDownloadLink } from "@react-pdf/renderer";
import cancel from "../picture/Cancel.png";
import download from "../picture/Download.png";
import "./userManual.css";
import Pdf from "../Pdf/Pdf";

const UserManual = ({ setSelectAccountPin }) => {
  const { selectedLanguage, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const handleStyleUserManual = () => {
    const sidebar = document.querySelector(".sidebar");
    const userManual = document.querySelector(".user-manual-wrapper");

    if (userManual !== null) {
      if (sidebar.classList.contains("hidden")) {
        console.log("nevidljiv");
        userManual.style.width = "100vw";
      } else {
        console.log("vidljiv");
        userManual.style.width = `calc(100vw - 11.11vh)`;
      }
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    handleStyleUserManual();
  });

  return (
    <div className="user-manual-wrapper">
      <div className="user-manual-container">
        <div className="user-manual-pdf">
          <PDFDownloadLink
            document={<Pdf text={selectedLanguage.data.termsOfUse} />}
            className="cart-buttons-btn pdf"
          >
            <img className="user-manual-pdf-img" src={download} alt="" />
          </PDFDownloadLink>
        </div>

        <img src={cancel} alt="" onClick={() => setSelectAccountPin(0)} />
      </div>
      <span
        className={
          "user-manual-text " +
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText(selectedLanguage.data.termsOfUse)}
      </span>
    </div>
  );
};

export default UserManual;
