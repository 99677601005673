import { hover } from "@testing-library/user-event/dist/hover";
import "./unlockscreenLog.css";
import { useEffect } from "react";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";

const UnlockscreenLog = ({
  winPattern,
  type,
  draft,
  setInputPattern,
  setResetSwitch,
  resetSwitch,
  setCurrentPage,
}) => {
  let svg;
  let dots;
  let wholeContainer;
  let wrapper;
  // let currentPatternTextBox;
  let timeOut;
  let remaining = 3;
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  console.log(winPattern);

  const reset = () => {
    hoveredDots = [];
    const localDots = document.querySelectorAll(`.dot-${type}`);
    localDots.forEach((dot) => (dot.style.backgroundColor = "#fff"));
    const unlock = document.querySelector(`.unlock-svg-${type}`);
    if (unlock === null) return;
    const lines = unlock.querySelectorAll("line");

    lines.forEach((line) => line.remove());
    //  currentPatternTextBox.value = "";
  };

  let line,
    isClicked = false,
    startX,
    startY;

  const checkPattern = async () => {
    let isCorrect = true;
    console.log(hoveredDots);
    console.log(winningPatern);
    let localPattern = "";
    hoveredDots.forEach((pattern) => {
      localPattern += pattern.toString();
    });
    console.log(localPattern);
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/checkUserPattern.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          user_pattern: localPattern,
        }
      );
      console.log(data);
      if (data.status !== 200) {
        isCorrect = false;
      }
    } catch (error) {
      console.log(error);
    }

    setInputPattern(hoveredDots);

    if (type === "second") makeColor(isCorrect);

    if (remaining === 0) {
      setCurrentPage(7);
    }
    console.log(isCorrect);
    console.log(hoveredDots);

    if (isCorrect && hoveredDots.length > 0) {
      hoveredDots = [];
      return;
    }
    let unlockscreen = document.querySelector(`.unlock-screen-${type}`);

    wrapper.removeEventListener("mousemove", bodyHover);

    if (type === "second") {
      timeOut = setTimeout(() => {
        if (unlockscreen === null) return;
        if (!isCorrect) reset();
      }, 1000);
    }
    isClicked = false;
    if (unlockscreen === null) return;
  };

  console.log(winPattern);
  const arrayWin =
    winPattern === undefined || typeof winPattern !== "string"
      ? []
      : winPattern.split("");
  console.log(arrayWin);
  const arrayWinInt = arrayWin.map((item) => {
    return parseInt(item);
  });

  let hoveredDots;

  let calculatedDotsCenters = [];

  const calculateDotsCenter = () => {
    let wrapper = document.querySelector(`.wholeContainer-${type}`);
    let wrapperRect = wrapper.getBoundingClientRect();

    for (let i = 0; i < dots.length; i++) {
      let dot = dots[i];
      let dotRect = dot.getBoundingClientRect();

      let dotCoordinates = {
        x: Math.ceil(dotRect.left + dotRect.width / 2 - wrapperRect.left),
        y: Math.ceil(dotRect.bottom - dotRect.height / 2 - wrapperRect.top),
        num: i + 1,
      };

      calculatedDotsCenters.push(dotCoordinates);
    }
  };

  let winningPatern = [1, 2, 3, 6, 9, 8];

  /*const correctPattern = () => {
    let pattern = document.querySelector(".correctPattern");
    pattern.value = `Unlock pattern is: ${winningPatern.toString()}`;
  };
*/
  const checkIfHovered = (index) => {
    return hoveredDots.includes(index);
  };

  const bodyHover = (ev) => {
    if (isClicked) drawingLine(ev, false);
  };

  const bodyHoverTouch = (ev) => {
    if (isClicked) drawingLine(ev, true);
  };

  const makeColor = (isCorrect) => {
    if (type === "second" && !isCorrect) remaining--;
    console.log(isCorrect);
    const lines = document.querySelectorAll(`.line-${type}`);
    lines.forEach((line) =>
      line.setAttribute("stroke", isCorrect ? "green" : "red")
    );
    hoveredDots.forEach(
      (index) =>
        (dots[index].style.backgroundColor = isCorrect ? "green" : "red")
    );
  };

  const checkPatternTouch = async () => {
    let isCorrect = true;
    console.log(hoveredDots);
    console.log(winPattern);

    let localPattern = "";
    hoveredDots.forEach((pattern) => {
      localPattern += pattern.toString();
    });
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/checkUserPattern.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          user_pattern: localPattern,
        }
      );

      if (data.status !== 200) {
        isCorrect = false;
      }
    } catch (error) {
      console.log(error);
    }

    setInputPattern(hoveredDots);

    if (type === "second") makeColor(isCorrect);
    if (remaining === 0) {
      setCurrentPage(7);
    }
    if (isCorrect === true && hoveredDots.length > 0) {
      hoveredDots = [];

      return;
    }
    let unlockscreen = document.querySelector(`.unlock-screen-${type}`);

    wrapper.removeEventListener("touchmove", bodyHoverTouch);
    if (type === "second") {
      timeOut = setTimeout(() => {
        if (unlockscreen === null) {
          return;
        }
        if (!isCorrect) reset();
        unlockscreen.style.pointerEvents = "auto";
      }, 1000);
    }
    isClicked = false;
    if (unlockscreen === null) return;
    unlockscreen.style.pointerEvents = "none";
  };

  function checkTouch(dot, ev) {
    const dimension = dot.getBoundingClientRect();
    const cordinate = ev.touches[0];
    if (
      cordinate.clientX > dimension.left &&
      cordinate.clientX < dimension.right &&
      cordinate.clientY > dimension.top &&
      cordinate.clientY < dimension.bottom
    )
      return true;
    return false;
  }

  function checkMouse(dot, ev) {
    const dimension = dot.getBoundingClientRect();

    if (
      ev.clientX > dimension.left &&
      ev.clientX < dimension.right &&
      ev.clientY > dimension.top &&
      ev.clientY < dimension.bottom
    )
      return true;
    return false;
  }

  const drawingLine = (ev, touch) => {
    const app = document.querySelector(`.wholeContainer-${type}`);

    if (touch) {
      console.log("Tach");
      line.setAttribute(
        "x2",
        Math.ceil(ev.touches[0].clientX) - app.getBoundingClientRect().x
      );

      line.setAttribute(
        "y2",
        Math.ceil(ev.touches[0].clientY) - app.getBoundingClientRect().y
      );
      svg.appendChild(line);
      dots.forEach((dot, index) => {
        if (checkTouch(dot, ev) && !checkIfHovered(index)) {
          line.setAttribute("x2", calculatedDotsCenters[index].x);
          line.setAttribute("y2", calculatedDotsCenters[index].y);
          dot.style.backgroundColor = "black";
          line = document.createElementNS("http://www.w3.org/2000/svg", `line`);
          line.setAttribute("stroke", "black");
          line.setAttribute("stroke-width", "4");
          startX = calculatedDotsCenters[index].x;
          startY = calculatedDotsCenters[index].y;
          line.setAttribute("x1", startX);
          line.setAttribute("y1", startY);
          hoveredDots.push(index);
          //  currentPatternTextBox.value =
          //    currentPatternTextBox.value + (index + 1);
          if (hoveredDots.length === dots.length) {
            isClicked = false;

            wrapper.removeEventListener("touchmove", bodyHoverTouch);

            checkPatternTouch();
            return;
          }
        }
      });
    } else {
      line.setAttribute("x2", ev.clientX - app.getBoundingClientRect().x);
      line.setAttribute("y2", ev.clientY - app.getBoundingClientRect().y);
      svg.appendChild(line);
      dots.forEach((dot, index) => {
        if (checkMouse(dot, ev) && !checkIfHovered(index)) {
          line.setAttribute("x2", calculatedDotsCenters[index].x);
          line.setAttribute("y2", calculatedDotsCenters[index].y);
          dot.style.backgroundColor = "black";
          line = document.createElementNS("http://www.w3.org/2000/svg", `line`);
          line.setAttribute("stroke", "black");
          line.setAttribute("stroke-width", "4");

          startX = calculatedDotsCenters[index].x;
          startY = calculatedDotsCenters[index].y;

          line.setAttribute("x1", startX);
          line.setAttribute("y1", startY);
          hoveredDots.push(index);
          //  currentPatternTextBox.value =
          //  currentPatternTextBox.value + (index + 1);
          if (hoveredDots.length === dots.length) {
            isClicked = false;

            wrapper.removeEventListener("mousemove", bodyHover);

            checkPattern();
            return;
          }
        }
      });
    }
  };

  const handleDrawWinPattern = () => {
    console.log("Crtam linije za ", winPattern);
    const app = document.querySelector(`.wholeContainer-${type}`);
    svg = document.querySelector(`.unlock-svg-${type}`);
    dots = document.querySelectorAll(`.dot-${type}`);

    wholeContainer = document.querySelector(`.wholeContainer-${type}`);
    wrapper = document.querySelector(`.wrapper-${type}`);
    let localLine, currentDot, currentDotRect, nextDot, nextDotRect, endX, endY;
    console.log(arrayWinInt);
    arrayWinInt.forEach((dot, index) => {
      if (index + 1 < arrayWinInt.length) {
        localLine = document.createElementNS(
          "http://www.w3.org/2000/svg",
          `line`
        );
        localLine.setAttribute("stroke", "black");
        localLine.setAttribute("stroke-width", "4");
        currentDot = dots[dot - 1];
        currentDotRect = currentDot.getBoundingClientRect();
        // pocetak linije
        startX =
          currentDotRect.x +
          currentDotRect.width / 2 -
          app.getBoundingClientRect().x;
        startY =
          currentDotRect.y +
          currentDotRect.height / 2 -
          app.getBoundingClientRect().y;
        localLine.setAttribute("x1", startX);
        localLine.setAttribute("y1", startY);
        // kraj linije
        console.log(arrayWinInt[index + 1] - 1);
        nextDot = dots[arrayWinInt[index + 1] - 1];
        nextDotRect = nextDot.getBoundingClientRect();
        endX =
          nextDotRect.x + nextDotRect.width / 2 - app.getBoundingClientRect().x;
        endY =
          nextDotRect.y +
          nextDotRect.height / 2 -
          app.getBoundingClientRect().y;
        localLine.setAttribute("x2", endX);
        localLine.setAttribute("y2", endY);
        svg.appendChild(localLine);
      }
    });
  };

  useEffect(() => {
    console.log(draft);
    if (draft) {
      svg = document.querySelector(`.unlock-svg-${type}`);
      dots = document.querySelectorAll(`.dot-${type}`);

      wholeContainer = document.querySelector(`.wholeContainer-${type}`);
      wrapper = document.querySelector(`.wrapper-${type}`);
      // currentPatternTextBox = document.querySelector(".currentPattern");

      calculateDotsCenter();
      //correctPattern();

      dots.forEach((dot, index) => {
        dot.addEventListener("mousedown", (e) => {
          if (isClicked) {
            wrapper.removeEventListener("mousemove", bodyHover);
            checkPattern();
            isClicked = !isClicked;
            return;
          } else {
            reset();
            wrapper.addEventListener("mousemove", bodyHover);
            wrapper.addEventListener("mouseup", checkPattern);
            line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              `line`
            );
            line.setAttribute("stroke", "black");
            line.setAttribute("stroke-width", "4");
            isClicked = !isClicked;
            startX = calculatedDotsCenters[index].x;
            startY = calculatedDotsCenters[index].y;
            line.setAttribute("x1", startX);
            line.setAttribute("y1", startY);
            hoveredDots.push(index);
            // currentPatternTextBox.value = index + 1;
            dot.style.backgroundColor = "black";
          }
        });
      });

      dots.forEach((dot, index) => {
        dot.addEventListener("touchstart", (e) => {
          if (isClicked) {
            wrapper.removeEventListener("touchmove", bodyHoverTouch);
            checkPatternTouch();
            isClicked = !isClicked;
            return;
          } else {
            reset();
            wrapper.addEventListener("touchmove", bodyHoverTouch);
            wrapper.addEventListener("touchend", checkPatternTouch);
            line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              `line`
            );
            line.setAttribute("stroke", "black");
            line.setAttribute("stroke-width", "4");
            isClicked = !isClicked;
            startX = calculatedDotsCenters[index].x;
            startY = calculatedDotsCenters[index].y;

            line.setAttribute("x1", startX);
            line.setAttribute("y1", startY);
            hoveredDots.push(index);
            // currentPatternTextBox.value = index + 1;
            dot.style.backgroundColor = "black";
          }
        });
      });
    }
    console.log(type);
    if (type === "third") handleDrawWinPattern();
  }, []);

  useEffect(() => {
    if (resetSwitch) {
      reset();
      setResetSwitch(false);
    }
    console.log("Pozvao se reset");
  }, [resetSwitch]);

  /*window.addEventListener("resize", () => {
    const patternUnlock = document.querySelector(".unlock-screen");
    const svgContiner = document.querySelector(".unlock-svg");
    svgContiner.setAttribute(
      "width",
      patternUnlock.getBoundingClientRect().width
    );
    svgContiner.setAttribute(
      "height",
      patternUnlock.getBoundingClientRect().height
    );
    calculateDotsCenter();
  });*/

  return (
    <div className={`unlock-screen-${type}`}>
      <div className={`wrapper-${type}`}>
        <div className={`wholeContainer-${type}`}>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
          <div className={`dot-container-${type}`}>
            <div className={`dot-${type}`}></div>
          </div>
        </div>
        <svg className={`unlock-svg-${type}`} height="300" width="300"></svg>

        {/* <input type="text" name="currentPattern" className="currentPattern" />*/}
      </div>
    </div>
  );
};

export default UnlockscreenLog;
