import "./pdfLoader.css";
import { format, compareAsc } from "date-fns";
import documentImg from "../dokument.png";

const PDFLoader = ({
  itemList,
  commentsActive,
  linksActive,
  documentsActive,
  imagesActive,
  account,
  location,
  loggedUser,
  numberOfCopy,
  categoriesItem,
}) => {
  /*const items = [
    {
      id: 1,
      categories: [],
      name: "Mladen Dubovac",
      title: "Item name 1",
      timeCreated: new Date(),
      comments: [],
      data: [
        {
          id: 1,
          name: "Field1",
          data: "field",
        },
        {
          id: 1,
          name: "Field2",
          data: "field",
        },
      ],
      images: [
        {
          data: "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
        },
      ],
      documents: [
        {
          name: "Document1",
          data: "",
        },
      ],
      links: [
        {
          link_id: 1,
          link: "www.facebook.com",
        },
      ],
      comments: [
        {
          id: 1,
          user: "Zikica Petkovic",
          timeCreated: "15.5.2024 15:00",
          text: "Ovo je tekst komentara",
          image:
            "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
          document: "Document from comment",
          link: "www.instagram.com",
        },
      ],
      categories: [
        {
          letter_color: "white",
          color: "blue",
          letter: "A",
        },
        {
          letter_color: "red",
          color: "white",
          letter: "B",
        },
      ],
    },
  ];*/

  const handleNumberOfcomment = () => {
    let numberCom = 0;
    itemList.map((item) => {
      if (item.comments.length !== 0) {
        numberCom += item.comments.length;
      }
    });
    console.log(numberCom);
    return numberCom;
  };

  const downloadDocument = (doc) => {
    console.log(doc);
    const a = document.createElement("a");
    a.href = doc.data;
    a.download = doc.name;
    a.click();
  };

  console.log(itemList);

  return (
    <main className="pdf-loader">
      <div className="pdf-header">
        <div className="upper-header">
          <div className="upper-header-img">
            <img
              alt=""
              src="https://gmt.rs/login/media/images/categories/0-22986400-1669365144.jpeg"
            />
          </div>
          <div className="upper-header-data">
            <div className="upper-header-data-top">
              <div className="upper-header-cell">COMPANY</div>
              <div className="upper-header-cell">LOCATION</div>
              <div className="upper-header-cell">DATE</div>
              <div className="upper-header-cell">PERIOD</div>
            </div>
            <div className="upper-header-data-middle">
              <div className="upper-header-cell">{account.name}</div>
              <div className="upper-header-cell">{location.location_name}</div>
              <div className="upper-header-cell">
                {itemList.length !== 0 &&
                  format(itemList[0].timeCreated, "dd.MM.yyyy")}
              </div>
              <div className="upper-header-cell">
                {itemList.length !== 0 &&
                  (compareAsc(
                    new Date(
                      new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)
                    ),
                    new Date(
                      new Date(
                        itemList[itemList.length - 1].timeCreated
                      ).setHours(0, 0, 0, 0)
                    )
                  ) !== 0
                    ? format(
                        itemList[itemList.length - 1].timeCreated,
                        "dd.MM.yyyy"
                      )
                    : "")}
              </div>
            </div>
            <div className="upper-header-data-bottom">
              <span>REPORT - {format(new Date(), "dd.MM.yyyy")}</span>
            </div>
          </div>
        </div>
        <div className="bottom-header">
          <div className="bottom-header-row">
            <div className="bottom-header-cell">CATEGORIES</div>
            <div className="bottom-header-cell">
              NUMBER OF ITEMS AND COMMENTS
            </div>
            <div className="bottom-header-cell">SENT BY</div>
            <div className="bottom-header-cell">TIME</div>
            <div className="bottom-header-cell">NUMBER OF COPIES</div>
            <div className="bottom-header-cell"></div>
          </div>
          <div className="bottom-header-row">
            <div className="bottom-header-cell">
              {categoriesItem.length !== 0 && (
                <div className="pdf-item-categories">
                  {categoriesItem.map((category, index) => {
                    const styles = {
                      color: category.letter_color,
                      backgroundColor: category.color,
                    };
                    return (
                      <div
                        className="pdf-item-category-header"
                        key={index}
                        style={styles}
                      >
                        {category.letter}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="bottom-header-cell-custom">
              <span>{itemList.length}</span>
              <span>{handleNumberOfcomment()}</span>
            </div>
            <div className="bottom-header-cell">
              <span>{loggedUser.user_email}</span>
            </div>
            <div className="bottom-header-cell">
              {format(new Date(), "hh:mm")}
            </div>
            <div className="bottom-header-cell">
              {numberOfCopy !== undefined && numberOfCopy}
            </div>
            <div className="bottom-header-cell"></div>
          </div>
        </div>
      </div>
      <div className="pdf-items">
        {itemList?.map((item, index) => {
          return (
            <article className="pdf-item" key={item.id}>
              <div className="pdf-item-header">
                <div className="pdf-item-header-left">{index + 1}.</div>
                <div className="pdf-item-header-left empty">
                  {item.categories.map((category, index) => {
                    const styles = {
                      color: category.letter_color,
                      backgroundColor: category.color,
                    };
                    return (
                      <div
                        style={styles}
                        className="pdf-item-category"
                        key={index}
                      >
                        {category.letter}
                      </div>
                    );
                  })}
                </div>
                <div className="pdf-item-header-middle">{item.title}</div>
                <div className="pdf-item-header-right">
                  <div className="pdf-item-header-data">{item.name}</div>
                  <div className="pdf-item-header-data">
                    {format(item.timeCreated, "HH:mm dd.MM.yyyy")}
                  </div>
                  <div className="pdf-item-header-data">
                    {item.comments.length}
                  </div>
                </div>
              </div>
              {item.data.map((field) => {
                const { id, data, name } = field;
                return (
                  <div className="pdf-item-data" key={id}>
                    <span className="pdf-item-data-title">{name}</span>
                    <span className="pdf-item-data-content">{data}</span>
                  </div>
                );
              })}
              <span className="pdf-item-data-title">Images</span>
              {imagesActive &&
                item.images.length !== 0 &&
                item.images[0].data !== "" && (
                  <div className="pdf-item-images">
                    {item.images.map((image, index) => {
                      return <img src={image.data} alt="" key={index} />;
                    })}
                  </div>
                )}
              <span className="pdf-item-data-title">Documents</span>
              {documentsActive &&
                item.documents.map((document, index) => {
                  return (
                    <div
                      className="pdf-item-document"
                      key={index}
                      onClick={() => downloadDocument(document)}
                    >
                      <img src={documentImg} alt="" />
                      <span>{document.name}</span>
                    </div>
                  );
                })}
              <span className="pdf-item-data-title">Links</span>
              {linksActive &&
                item.links.map((link) => {
                  return (
                    <span className="pdf-item-data-content" key={link.link_id}>
                      {link.link}
                    </span>
                  );
                })}
              <span className="pdf-item-data-title">Comments</span>
              {commentsActive &&
                item.comments.map((comment, index) => {
                  console.log(comment);
                  return (
                    <article className="pdf-item-comment" key={index}>
                      <div className="pdf-item-comment-header">
                        <span className="pdf-item-data-content">
                          {index + 1}.
                        </span>
                        <span className="pdf-item-data-content">
                          {comment.user}
                        </span>
                        <span className="pdf-item-data-content">
                          {comment.timeCreated}
                        </span>
                      </div>
                      {comment.text !== "" && (
                        <span className="pdf-item-data-content">
                          {comment.text}
                        </span>
                      )}
                      {comment.image.length !== 0 && comment.image !== "" && (
                        <>
                          <span className="pdf-item-data-title">Image</span>
                          <img src={comment.image.data} alt="" />
                        </>
                      )}
                      {comment.document.length !== 0 && (
                        <>
                          <span className="pdf-item-data-title">Document</span>
                          <div
                            className="pdf-item-document"
                            key={index}
                            onClick={() =>
                              downloadDocument(comment.document[0])
                            }
                          >
                            <img src={documentImg} alt="" />
                            <span>{comment.document[0].name}</span>
                          </div>
                        </>
                      )}
                      {comment.link !== "" && (
                        <>
                          <span className="pdf-item-data-title">Link</span>
                          <span className="pdf-item-data-content">
                            {comment.link}
                          </span>
                        </>
                      )}
                    </article>
                  );
                })}
            </article>
          );
        })}
      </div>
    </main>
  );
};

export default PDFLoader;
