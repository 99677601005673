import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import { CircledButton, NavMenu } from '../../index';
import { 
    BackIdleIcon, BackActiveIcon, BellActiveIcon, BellIdleIcon, 
    CategoryActiveIcon, CategoryIdleIcon, ImageActiveIcon, ImageIdleIcon, 
    PlaneIdleIcon, PlaneActiveIcon, SaveActiveIcon, SaveIdleIcon, 
    SearchActiveIcon, SearchIdleIcon, UserIdleIcon, UserActiveIcon 
} from '../../../assets';

import "./postForm.css";

function PostForm(  ) {
    const [isTyping, setIsTyping] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const navigate = new useNavigate();

    const categoryColors = {
        1: '#00b055ff',
        2: '#418cfcff',
        3: '#ff3519ff',
        4: '#ffd42aed'
    };

    const handleFirstTextareaTyping = (event) => {
        const value = event.target.value;
        setTitle(value);
        if (!isTyping && value.length > 0) {
            setIsTyping(true); // Hide posts when typing starts
        }
        adjustTextareaHeight(event.target);
    };

    const handleBackButtonClick = () => {
        setIsTyping(false); // Hide the second textarea, buttons, etc.
        setTitle(''); // Clear the first textarea
        setContent(''); // Clear the second textarea if needed
        setSelectedCategory(null); // Reset category selection
        setSelectedImage(null);
        navigate('./') // Clear the selected image
    };
    
    const handleSecondTextareaTyping = (event) => {
        setContent(event.target.value);
        adjustTextareaHeight(event.target);
    };

    const adjustTextareaHeight = (textarea) => {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    const toggleCategories = () => {
        setShowCategories(!showCategories);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        event.target.value = '';
    };

    const handleImageCancel = () => {
        setSelectedImage(null);
    };

    const handleSubmit = () => {
        const formData = {
            title,
            content,
            category: selectedCategory,
            image: selectedImage
        };

        console.log(JSON.stringify(formData, null, 2));
    };

    return (
        <div className="post-form-wrapper test">
            <Link to='/social/feed/newpost'>
                <textarea
                    id="title"
                    placeholder='...'
                    rows="4"
                    value={title}
                    onChange={handleFirstTextareaTyping}
                    style={{ color: selectedCategory ? categoryColors[selectedCategory] : 'black' }}
                ></textarea>
            </Link>
            {(isTyping || title.length > 0) && (
                <>
                    <textarea
                        placeholder=''
                        rows="5"
                        value={content}
                        onChange={handleSecondTextareaTyping}
                        spellCheck="false"
                    ></textarea>

                    {selectedImage && (
                        <div className="image-preview">
                            <img src={selectedImage} alt="Selected" />
                            <button className="cancel-image" onClick={handleImageCancel}>X</button>
                        </div>
                    )}

                    <div className="form-buttons btns">
                        <Link to='/social/feed'>
                            <CircledButton 
                                idleImage={BackIdleIcon} 
                                activeImage={BackActiveIcon} 
                                toggle={false} 
                                handleButtonEffect={handleBackButtonClick} 
                            />
                        </Link>
                        <CircledButton 
                            idleImage={CategoryIdleIcon} 
                            activeImage={CategoryActiveIcon} 
                            toggle={true} 
                            handleButtonEffect={toggleCategories} 
                        />
                        <CircledButton 
                            idleImage={ImageIdleIcon} 
                            activeImage={ImageActiveIcon} 
                            toggle={false} 
                            handleButtonEffect={() => document.getElementById('image-upload-input').click()} 
                        />
                        <CircledButton 
                            idleImage={PlaneIdleIcon} 
                            activeImage={PlaneActiveIcon} 
                            toggle={false} 
                            handleButtonEffect={handleSubmit} 
                        />
                    </div>

                    {showCategories && (
                        <div className="category-squares">
                            <div
                                className={`category-square ${selectedCategory === 1 ? 'selected category-1' : ''}`}
                                onClick={() => handleCategoryClick(1)}
                            ></div>
                            <div
                                className={`category-square ${selectedCategory === 2 ? 'selected category-2' : ''}`}
                                onClick={() => handleCategoryClick(2)}
                            ></div>
                            <div
                                className={`category-square ${selectedCategory === 3 ? 'selected category-3' : ''}`}
                                onClick={() => handleCategoryClick(3)}
                            ></div>
                            <div
                                className={`category-square ${selectedCategory === 4 ? 'selected category-4' : ''}`}
                                onClick={() => handleCategoryClick(4)}
                            ></div>
                        </div>
                    )}

                    <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />
                </>
            )}
        </div>
    );
}

export default PostForm;
