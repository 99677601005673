import "./filterArchiveLandscape.css";
import Button from "../.././../Button/Button";
import message from "../.././1.png";
import startDate from "../.././5.png";
import file from "../.././6.png";
import link from "../.././7.png";
import image from "../.././8.png";
import chart from "../.././9.png";
import profile from "../.././10.png";
import search from "../.././11.png";
import { useGlobalContext } from "../../../Context/Context";

const FilterArchive = () => {
  const {
    typeButton,
    setTypeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
  } = useGlobalContext();

  console.log("FIlter kreiran");
  const handleChangeColorButton = (e) => {
    const clickedButton = e.currentTarget.firstChild;
    const clickedButtonStyle = window
      .getComputedStyle(clickedButton, null)
      .getPropertyValue("background-color");
    if (clickedButtonStyle === "rgb(153, 153, 153)") {
      clickedButton.style.backgroundColor = "red";
    } else if (clickedButtonStyle === "rgb(255, 0, 0)") {
      clickedButton.style.backgroundColor = "green";
    } else {
      clickedButton.style.backgroundColor = "#999999ff";
    }
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="filter-archive-landscape">
      <div className="main-landscape">
        <div className="left-container-landscape">
          <div className="left-top-container-landscape">
            <div className="wrap-dates-landscape">
              <div className="date-landscape">
                <Button typeButton={typeButton}>
                  <div className="start-date-calendar-img-landscape">
                    <img src={startDate} alt="" />
                  </div>
                </Button>
                <span>04.03.24</span>
              </div>
              <div className="date-landscape">
                <Button typeButton={typeButton}>
                  <div className="end-date-calendar-img-landscape">
                    <img src={startDate} alt="" />
                  </div>
                </Button>
                <span>04.03.24</span>
              </div>
            </div>
            <div className="horizontal-button-row-1-landscape">
              <div className="chart-landscape">
                <Button image={chart} typeButton={typeButton} />
              </div>
              <div className="profile-landscape">
                <Button image={profile} typeButton={typeButton} />
              </div>
            </div>
          </div>
          <div className="left-bottom-container-landscape">
            <div className="vertical-button-row-3-landscape">
              <div className="colors-landscape">
                <div className="row-flex-landscape">
                  <div className="info-text">
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText("SVE STAVKE")}
                    </span>{" "}
                  </div>
                  <div className="info-color-landscape gray"></div>
                </div>

                <div className="row-flex-landscape">
                  <div className="info-text">
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText("STAVKE BEZ")}
                    </span>{" "}
                    {/* promena jezika */}
                  </div>
                  <div className="info-color-landscape red"></div>
                </div>

                <div className="row-flex-landscape">
                  <div className="info-text">
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText("STAVKE SA")}
                    </span>
                  </div>
                  <div className="info-color-landscape green"></div>
                </div>
              </div>
            </div>
            <div className="vertical-button-row-1-landscape">
              <div className="first-group-landscape">
                <div
                  className="button-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <img src={message} alt="" />
                  </Button>

                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="file-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <img src={file} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="link-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <img src={link} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>

              <div className="first-group-landscape">
                <div
                  className="image-message-landscape"
                  onClick={handleChangeColorButton}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <img src={image} alt="" />
                  </Button>
                  <div className="dot-landscape"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-container-landscape"></div>
        </div>
        <div className="right-container-landscape">
          <div className="search-container-landscape">
            <div className="search-landscape">
              <Button typeButton={typeButton}>
                <img src={search} alt="" />
                <span>PRIKAŽI</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/**/
export default FilterArchive;
