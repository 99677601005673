import Send from "./images/slanje.png";
import Back from "./images/Back.png";
import pictureLoginImg from "./images/picture-login.png";
import mailImg from "./images/mail.png";
import userImg from "./images/korisnik.png";
import Button from "../Button/Button";
import handImg from "./images/hand.png";
import { useEffect } from "react";
import { useGlobalContext } from "../Context/Context";

const ProfileExternalCheckout = ({
  setCurrentPage,
  setUserData,
  userData,
  setSelectAccount,
}) => {
  console.log(userData);
  const { typeButton } = useGlobalContext();
  const handleClickSend = () => {
    //const sendMail = document.createElement("a");
    //sendMail.href = `mailto:registracija@nadzor.app?subject=Registration request&body=The user (${userData.email}) invites you to complete the registration process in the Tempolex application.`;

    //sendMail.click();
    setSelectAccount(0);
  };

  const setPosition = () => {
    if (userData.picture !== "") {
      const container = document.querySelector(".profile-login-img-container");
      if (container !== undefined && container !== null) {
        const image = container.querySelector(".image");
        const hand = container.querySelector(".hand");
        if (hand !== null && image !== null) {
          hand.style.top = `${
            (image.getBoundingClientRect().height * 3.3) / 4
          }px`;
          hand.style.left = `${
            (image.getBoundingClientRect().width * 3.3) / 4
          }px`;
        }
      }
    }
  };

  window.addEventListener("resize", setPosition);

  useEffect(setPosition, [userData.picture]);

  console.log("dasdsada");
  console.log(userData);

  return (
    <div className="profile-login test">
      <div className="profile-login-img">
        <div className="profile-login-img-container">
          {userData.picture !== "" ? (
            <>
              <img src={handImg} alt="" className="hand" />
              <img src={userData.picture} alt="" className="image" />
            </>
          ) : (
            <img src={pictureLoginImg} alt="" className="picture-image" />
          )}
        </div>
      </div>
      <div className="profile-login-form">
        <div className="profile-login-form-entry">
          <img src={mailImg} alt="" />
          <span>{userData.email}</span>
        </div>
        <div className="profile-login-form-entry">
          <img src={userImg} alt="" />
          <span>{userData.name}</span>
        </div>
      </div>

      <div className="profile-login-btn" onClick={handleClickSend}>
        <Button typeButton={typeButton}>
          <img src={Send} alt="" className="profile-login-btn-img" />
        </Button>
      </div>
    </div>
  );
};

export default ProfileExternalCheckout;
