import { useEffect, useRef, useState } from "react";
import infoLoginImg from "./images/info-login.png";
import pictureLoginImg from "./images/picture-login.png";
import mailImg from "./images/mail.png";
import userImg from "./images/korisnik.png";
import sendImg from "./images/slanje.png";
import Button from "../Button/Button";
import handImg from "./images/hand.png";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import "./profile.css";

const ProfileExternalLogin = ({ setCurrentPage, setUserData, userData }) => {
  const {
    typeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
    loggedUser,
    apiKey,
  } = useGlobalContext();
  const [infoClicked, setInfoClicked] = useState(
    userData.picture !== "" ? 2 : 0
  );

  const emailRef = useRef();
  const nameRef = useRef();

  const handleInfoClick = () => {
    // 0 -> info slika
    // 1 -> paragraf
    // 2 -> input slika
    if (infoClicked === 0) setInfoClicked((prev) => prev + 1);
    if (infoClicked === 1) {
      setInfoClicked((prev) => prev + 1);
    }
    if (infoClicked === 2) return;
  };

  const handleInputFile = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setUserData({ ...userData, picture: d.currentTarget.result });
    };
    reader.readAsDataURL(file);
  };

  const handleInputEmail = (e) => {
    emailRef.current = e.currentTarget.value;
  };

  const handleClickBtn = async () => {
    const requestCreateUser = {
      user_email: emailRef.current,
      api_key: apiKey,
      user_id: loggedUser.id,
    };

    const baseUrl = "https://data.nadzor.app/api/";

    const urlCreateObject = baseUrl + "profiles/createUserInvite.php";
    const response = await axios.post(urlCreateObject, requestCreateUser);
    console.log(nameRef);
    console.log(response);
    if (response.status === 200) {
      const requestObject = {
        user_id: response.data.user_id,
        user_name: nameRef.current,
        cp_image: userData.picture !== "" ? userData.picture : userImg,
      };
      const url = baseUrl + "profiles/insertUserName.php";

      const { data } = await axios.post(url, requestObject);
      if (data.status === 200) {
        const newUserData = {
          ...userData,
          id: response.data.user_id,
          apiKey: response.data.api_key,
          email: emailRef.current,
          name: nameRef.current,
        };
        console.log(data);
        setUserData(newUserData);
        setCurrentPage(1);
      }
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleChangeName = (e) => {
    nameRef.current = e.currentTarget.value;
  };

  const setPosition = () => {
    if (userData.picture !== "") {
      const container = document.querySelector(".profile-login-img-container");
      if (container !== null) {
        const image = container.querySelector(".user-image");
        const hand = container.querySelector(".hand");
        if (hand !== null && image !== null) {
          hand.style.top = `${
            (image.getBoundingClientRect().height * 3.3) / 4
          }px`;
          hand.style.left = `${
            (image.getBoundingClientRect().width * 3.3) / 4
          }px`;
        }
      }
    }
  };

  window.addEventListener("resize", setPosition);

  useEffect(setPosition, [userData.picture]);

  console.log("test");
  console.log(userData);

  return (
    <div className="profile-login">
      {infoClicked === 0 && (
        <img
          onClick={handleInfoClick}
          src={infoLoginImg}
          alt=""
          className="profile-login-img"
        />
      )}
      {infoClicked === 1 && (
        <p
          className={
            "profile-login-info " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          onClick={handleInfoClick}
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          <img src={mailImg} alt="" />
          {handleConvertText(
            "- Dozvoljene samo adrese kojima možete pristupiti - Ako više ne pristupate registrovanoj e-adresi, molimo vas zamenite je adresom kojoj pristupate"
          )}
          <br />
          <br />

          {handleConvertText(
            "  - Dozvoljeni svi mogući simboli, slova i cifre, i to min. 1 slovo i min. 1 cifra - Lozinku uvek možete izmeniti u podešavanju "
          )}
          <br />
          <br />

          {handleConvertText(
            "  - U slucaju greske pri logovanju vlasnistvo nad nalogom se dokazuje kroz kontrolne ekrane koji ukljucuju i navodjenje nekih pouzdanih kontakata. - Pozeljno je jos tokom prvog prijavljivanja definisati dva svoja pouzdana kontakta."
          )}
        </p>
      )}
      {infoClicked === 2 && (
        <>
          <label htmlFor="file-upload" className="profile-login-img">
            {userData.picture === "" ? (
              <img src={pictureLoginImg} alt="" />
            ) : (
              <div className="profile-login-img-container">
                <img src={handImg} alt="" className="hand" />
                <img src={userData.picture} alt="" className="user-image" />
              </div>
            )}
          </label>
          <input id="file-upload" type="file" onChange={handleInputFile} />
        </>
      )}
      <div className="profile-login-form">
        <div className="profile-login-form-entry">
          <img src={mailImg} alt="" />
          <input
            defaultValue={userData.email}
            type="text"
            onChange={handleInputEmail}
          />
        </div>
        <div className="profile-login-form-entry">
          <img src={userImg} alt="" />
          <input
            onChange={handleChangeName}
            accept="image/png, image/jpeg, image/jpg"
            type="text"
            placeholder="Ime i prezime"
            defaultValue={userData.name}
          />
        </div>
      </div>
      <div className="profile-login-btn" onClick={handleClickBtn}>
        <Button typeButton={typeButton}>
          <img
            src={sendImg}
            alt=""
            className="profile-login-btn-img visible-img"
          />
        </Button>
      </div>
    </div>
  );
};

export default ProfileExternalLogin;
