import axios from 'axios'

const baseUrl = 'https://data.nadzor.app/api'


export const fetchData = async (link) => {
  try {
      let {data} = await axios.get(
        `${baseUrl}${link}`,
        {}
      );
      if (data.data) return data.data
  } catch (error) {
      console.log(error)
  }
  return []
}

export const fetchPopularPosts = async () => {
  try {
      let {data} = await axios.get(
        `${baseUrl}/relations/posts/readAllPopularPosts.php`,
        {}
      );
      if (data.data) return data.data
  } catch (error) {
      console.log(error)
  }
  return []
}

export async function fetchDataByLinkBody(link,body) {
  
  try {
      let {data} = await axios.post(
        `${baseUrl}${link}`,
        body
      );
      if (data.data) return data.data
  } catch (error) {
      console.log(error)
  }
  return []
}