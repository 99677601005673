import Button from "../../../Button/Button";
import "./documentLinkItemModal.css";
import addDocument from "../../plus.png";
import { useRef, useState } from "react";
import Cancel from "../../3.png";
import Send from "../../slanje.png";
import link from "../../link.png";
import documentImg from "../../dokument.png";
import Base from "../../picture/baza-linkova.png";
import WWW from "../../picture/www.png";
import LinkItem from "../../picture/link-item.png";
import LinksItemModal from "../../../LinksItemModal/LinksItemModal";
import { useGlobalContext } from "../../../Context/Context";
import axios from "axios";
import FilterListItem from "../../Archive/FilterListItem/FilterListItem";

const DocumentLinkItemModal = ({
  realItemData,
  setItemList,
  attachModalActive,
  atachModalCommentActive,
  itemData,
  setItemData,
  activeAdd,
  index,
  setSelecetAccount,
  setArchiveClicked,
  setArchive,
  setArchiveData,
  order,
}) => {
  const [openLinks, setOpenLinks] = useState(true);

  const [wwwOpen, setWWWOpen] = useState(false);
  const inputWWW = useRef();

  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { location } = useGlobalContext();
  const { typeButton } = useGlobalContext();

  const { loggedUser } = useGlobalContext();
  const { newLinks, setNewLinks } = useGlobalContext();
  const { setOpenAddLink } = useGlobalContext();

  inputWWW.current = { name: "", link: "" };

  console.log(itemData);
  console.log("Ovo je order", order, attachModalActive);
  // isCOmmenting === true -> dodavanje komentara
  // attachModalActive === undefined => dodavanje stavke
  // !isCommenting -> citanje stavke
  // isCommenting === undefined -> citanje komentara
  console.log(attachModalActive);
  console.log(itemData);
  console.log(realItemData);
  const setSettingData = () => {
    if (attachModalActive === undefined) {
      console.log("Prvi");
      return {
        document: itemData.documents,
        link: itemData.link,
      };
    }

    if (attachModalActive.isCommenting === undefined) {
      console.log("drugi");
      return {
        document: itemData.comments[atachModalCommentActive.value].document[0],
        link: itemData.comments[atachModalCommentActive.value].link,
      };
    }

    if (attachModalActive.isCommenting) {
      console.log("Treci");
      return { document: realItemData.document, link: realItemData.link };
    } else {
      console.log("Cetvrti");
      console.log(itemData);
      return {
        document: itemData.documents.length === 0 ? [] : itemData.documents[0],
        link: itemData.links,
      };
    }
  };

  const maxFileNameLength = 40;
  const [data, setData] = useState(setSettingData());
  const [openFilterListItem, setOpenFilterListItem] = useState(false);
  const [itemClick, setItemClick] = useState([]);
  const [openLink, setOpenLink] = useState(false);

  const handleInputLink = (e) => {
    setData({ ...data, link: e.currentTarget.value });
  };

  const handleOpenLink = (e) => {
    if (openLink) {
      const time = setTimeout(() => {
        setOpenLink(false);
        setData({ ...data, link: data.link });
        clearTimeout(time);
      }, 500);
    } else {
      setOpenLink(true);
    }
  };

  const handleInputDocument = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setItemData({
        ...realItemData,
        documents: {
          name: currentFile.name,
          data: d.currentTarget.result,
        },
      });
      setData({
        ...data,
        document: {
          name: currentFile.name,
          data: d.currentTarget.result,
        },
      });
    };
    reader.readAsDataURL(file);
  };

  const handleAddLinkFromBase = () => {
    console.log("Setujem na false");
    setOpenLinks(false);
  };

  console.log("definisanje novog ", openLinks);

  const handleSetButton = (e, item, index) => {
    const wrapper = e.currentTarget.parentNode.parentNode.firstChild;
    wrapper.value = "";
    const newAddedLinks = newLinks.filter((link, i) => index !== i);
    console.log(itemData);
    setItemData({
      ...itemData,
      link: [...itemData.link, item],
    });
    setNewLinks(newAddedLinks);
  };

  const handleInputName = (e, index) => {
    const newLinkItem = newLinks.map((links, i) => {
      if (i === index) {
        return { ...links, name: e.currentTarget.value };
      } else {
        return links;
      }
    });
    console.log(newLinkItem);
    setNewLinks(newLinkItem);
  };

  const handleDeleteItem = (index) => {
    console.log(index);
    const newItemLink = itemData.link.filter((link, i) => i !== index);
    console.log(newItemLink);
    setItemData({ ...itemData, link: newItemLink });
  };

  const handleWWW = () => {
    if (!wwwOpen) window.open("https://www.google.rs/");
    setWWWOpen(!wwwOpen);
  };
  const handleSetWWW = () => {
    setItemData({ ...itemData, link: [...itemData.link, inputWWW.current] });
    setWWWOpen(!wwwOpen);
  };

  const handleInputWWW = (e, difference) => {
    if (difference) {
      inputWWW.current.name = e.currentTarget.value;
    } else {
      inputWWW.current.link = e.currentTarget.value;
    }
  };
  const handleOpenNewLink = (item) => {
    if (item.link !== null) {
      window.open(`https://${item.link}`);
    } else {
      console.log(item);
      const sendData = {
        user_id: loggedUser.id,
        api_key: apiKey,
        itemId: item.itemId,
      };
      console.log(sendData);
      try {
        axios
          .post(`${baseUrl}/items/getItemForLink.php`, sendData)
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              setItemClick(data.data.item);
            } else {
              setItemClick([]);
            }
            setOpenFilterListItem(!openFilterListItem);
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    }
  };

  const handleDeleteDocument = (data) => {
    console.log(data);
    setItemData({ ...itemData, documents: [] });
    setData({ ...data, document: [] });
  };

  const handleOpenItem = () => {
    const header = document.querySelector(".editor-header");
    setOpenAddLink(true);
    console.log("otvaram arhivu");
    console.log(baseUrl);
    console.log(loggedUser);
    console.log(apiKey);
    console.log(location);

    try {
      axios
        .post(`${baseUrl}/items/getCommentsSeen.php`, {
          location_id: location.location_id,
          user_id: loggedUser.id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log(data);

          if (data.status === 200) {
            console.log(data);
            if (data.data.items.length === 0) {
            } else {
              setArchive(data.data.items);
              setArchiveData(data.data.items);
              setArchiveClicked(true);
              header.style.zIndex = 50;
            }
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }

    /*  try {
      axios
        .post(`${baseUrl}/items/getCommentsSeen.php`, {
          location_id: location.location_id,
          user_id: loggeduser.id,
          api_key: apiKey,
        })
        .then((data) => {
          console.log(data);

          if (data.status === 200) {
            console.log(data);
            if (data.data.items.length === 0) {
            } else {
              setArchive(data.data.items);
              setArchiveData(data.data.items);
              setArchiveClicked(true);
              header.style.zIndex = 50;
            }
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }*/
  };

  console.log(openFilterListItem);
  return (
    <>
      {openLinks ? (
        <div className="image-document-wrapper">
          <div className="wrapper-container-link-add">
            {wwwOpen && (
              <div className="wrapper-add-link">
                <input
                  className="wrapper-name-link"
                  onChange={(e) => handleInputWWW(e, true)}
                  id={`${index}`}
                ></input>
                <div className="wrapper-link">
                  <input
                    onChange={(e) => handleInputWWW(e, false)}
                    className="add-link-button button-link"
                  ></input>
                  <div
                    className="add-link-button"
                    onClick={() => handleSetWWW()}
                  >
                    <Button activation={true} typeButton={typeButton}>
                      <img src={Send} alt="" className="visible-img" />
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {newLinks.map((newLink, index) => {
              return (
                <div className="wrapper-add-link">
                  <input
                    className="wrapper-name-link"
                    onChange={(e) => handleInputName(e, index)}
                    id={`${index}`}
                  ></input>
                  <div className="wrapper-link">
                    <div
                      className="add-link-button button-link"
                      onClick={() => handleOpenNewLink(newLink)}
                    >
                      <Button typeButton={typeButton} activation={true}>
                        <div className="wrapper-count-name">
                          <span>{index}</span>
                          <div className="add-link-name">
                            {newLink.name === "" ? newLink.link : newLink.name}
                          </div>
                        </div>
                      </Button>
                    </div>
                    <div
                      className="add-link-button"
                      onClick={(e) => handleSetButton(e, newLink, index)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <img src={Send} alt="" className="visible-img" />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="wrapper-container-link">
            {itemData.link !== "" &&
              itemData.link !== undefined &&
              itemData.link.map((addLink, i) => {
                return (
                  <div className="wrapper-added-link">
                    <div
                      className="add-link-button button-link"
                      onClick={() => handleOpenNewLink(addLink)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <div className="wrapper-count-name">
                          <span>{i}</span>
                          <div className="add-link-name ">{addLink.name}</div>
                        </div>
                      </Button>
                    </div>
                    <div
                      className="add-link-button"
                      onClick={() => handleDeleteItem(i)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <img src={Cancel} alt="" className="visible-img" />
                      </Button>
                    </div>
                  </div>
                );
              })}
            {data.document !== undefined && data.document.length !== 0 && (
              <div className="wrapper-added-link">
                <div className="add-link-button button-link">
                  <Button activation={true} typeButton={typeButton}>
                    <div className="wrapper-count-name">
                      <a
                        className="download-link download-link-height"
                        download={data.document?.name}
                        href={data.document?.data}
                      >
                        <img src={documentImg} alt="" className="visible-img" />
                        <div className="add-link-name ">
                          {data.document?.name?.length > maxFileNameLength
                            ? `${data.document.name.slice(
                                0,
                                maxFileNameLength
                              )}...`
                            : data.document.name}{" "}
                        </div>
                      </a>{" "}
                    </div>
                  </Button>
                </div>
                <div
                  className="add-link-button"
                  onClick={() => handleDeleteDocument(data)}
                >
                  <Button typeButton={typeButton}>
                    <img src={Cancel} alt="" className="visible-img" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          {openLink && (
            <div className="wrapper-import-links">
              <div
                className="import-links-button"
                onClick={handleAddLinkFromBase}
              >
                <Button typeButton={typeButton}>
                  <img src={Base} alt="" className="visible-img" />
                </Button>
              </div>
              <div className="import-links-button" onClick={handleWWW}>
                <Button
                  activation={true}
                  active={wwwOpen}
                  typeButton={typeButton}
                >
                  <img src={WWW} alt="" className="visible-img" />
                </Button>
              </div>
              <div className="import-links-button" onClick={handleOpenItem}>
                <Button activation={true} typeButton={typeButton}>
                  <img src={LinkItem} alt="" className="visible-img" />
                </Button>
              </div>
            </div>
          )}
          <div className="wrapper-link-button">
            <div className="document-link-add">
              <div
                className={
                  activeAdd ? "document-add" : "document-add disable-link"
                }
              >
                <label
                  className="button-image-document-link"
                  htmlFor="file-upload"
                >
                  <img src={addDocument} alt="" className="" />
                  <img src={documentImg} alt="" className="" />
                </label>
                <input
                  accept="application/*"
                  id="file-upload"
                  type="file"
                  onChange={handleInputDocument}
                />
              </div>
              <div
                className={
                  activeAdd ? "document-add" : "document-add disable-link"
                }
                onClick={handleOpenLink}
              >
                <Button
                  active={data.link === "" && openLink}
                  activation={true}
                  typeButton={typeButton}
                >
                  <div className="button-image-document-link">
                    <img src={addDocument} alt="" className="" />
                    <img src={link} alt="" className="" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LinksItemModal
          setOpenLinks={setOpenLinks}
          newLinks={newLinks}
          setNewLinks={setNewLinks}
        />
      )}
      {openFilterListItem && (
        <FilterListItem
          setFilterListItemActive={setOpenFilterListItem}
          call={false}
          filteredData={itemClick}
        />
      )}
    </>
  );
};

export default DocumentLinkItemModal;
