import { useEffect } from 'react';
import './stickyMenu.css'
import CircledButton from '../buttons/circled-button/CircledButton';

const StickyMenu = ({buttonsList}) => {
  return (
    <div className='control-buttons'>
        {buttonsList.map((button, key) => (
            <CircledButton
                key={key}
                toggle={button.toggle} 
                idleImage={button.idleImage} 
                activeImage={button.activeImage} 
                handleButtonEffect={button.handleEffect}
                extraStyling={button.extraStyling}
            />
        ))}
    </div>
  )
}

export default StickyMenu