import "./changePassword.css";
import VisibleNon from "../picture/VisibleNon.png";
import Visible from "../picture/Visible.png";
import Button from "../Button/Button";
import Unlockscreen from "../unlockScreen/Unlockscreen";
import Pin from "../picture/Pin.png";
import Key from "../picture/Key.png";
import Send from "../picture/Send.png";
import Edit from "../picture/Pen.png";
import PinUnlock from "../pinUnlock/PinUnlock";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../Context/Context";
const ChangePassword = ({ setCurrentPage, setSelectAccountPin, type }) => {
  const { typeButton } = useGlobalContext();
  const { loggedUser, setLoggedUser } = useGlobalContext();
  const { selectedLanguage } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { letterSize } = useGlobalContext();
  const { boldItalic } = useGlobalContext();
  const { fontSize } = useGlobalContext();
  const { fontStyle } = useGlobalContext();
  const leter = ["X", "X", "X", "X", "X", "X"];
  const timeRemaining = useRef();
  const interval = useRef();
  const [firstKey, setFirstKey] = useState("");
  const [secondKey, setSecondKey] = useState("");
  const firstPassword = useRef([]);
  const secondPassword = useRef([]);
  timeRemaining.current = 10000;
  const [visibility, setVisibility] = useState(false);
  const [logginText, setLoginText] = useState(false);
  const [passwordCurrent, setPasswordCurrent] = useState(false);
  const [pinCurrent, setPinCurrent] = useState(false);
  const [firstPin, setFirstPin] = useState([]);
  const [secondPin, setSecondPin] = useState([]);
  const [chooseOption, setChooseOption] = useState(
    loggedUser.pattern === "" ? "" : "first"
  );
  const [resetSwitch, setResetSwitch] = useState(false);

  console.log("Ovo je tip promenljive", type);
  const handleKeyDown = (e) => {
    e.currentTarget.addEventListener("mousemove", handleMouseLive);
    setVisibility(true);
    clearInterval(interval.current);
  };

  const handleKeyUp = (e) => {
    setVisibility(false);
    timeRemaining.current = 10000;
    clearInterval(interval.current);
    handleInterval();
  };

  const handleStyleInput = () => {
    const inputKey = document.querySelectorAll(".input-key");

    if (inputKey[0] !== undefined) {
      const inputs = inputKey[0].querySelectorAll("input");
      const inputKeyRect = inputKey[0].getBoundingClientRect();
      inputs.forEach((input) => {
        input.style.fontSize = ` ${(inputKeyRect.height * 90) / 100}px`;
      });
    }
    if (inputKey[1] !== undefined) {
      const inputs = inputKey[1].querySelectorAll("input");
      const inputKeyRect = inputKey[1].getBoundingClientRect();
      inputs.forEach((input) => {
        input.style.fontSize = ` ${(inputKeyRect.height * 90) / 100}px`;
      });
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleChange = (index, value, setKey) => {
    console.log("Unos prvi");
    // Zabranjujemo unos space-a
    if (value === " ") {
      return; // Tj. ignorisemo unos space-a
    }
    setKey((prevPassword) => {
      const newPassword =
        prevPassword.substring(0, index) +
        value +
        prevPassword.substring(index + 1);
      return newPassword;
    });
    // Resetujemo state kad korisnik pocne opet da unosi, tj. da ispravlja lozinku
  };

  const handleInputKey = (index, ref) => {
    console.log("Unos drugi");
    if (index < ref.current.length - 1 && ref.current[index].value !== "") {
      ref.current[index + 1].focus();
    }
  };

  const handleBackspace = (index, event, ref) => {
    console.log("Unos treci");
    if (event.keyCode === 8 && index > 0 && ref.current[index].value === "") {
      ref.current[index - 1].focus();
    }
  };

  const handleMouseLive = (e) => {
    setVisibility(false);
    timeRemaining.current = 10000;

    clearInterval(interval.current);
    interval.current = setInterval(() => {
      timeRemaining.current -= 1000;
      const timeSpan = document.querySelector(".countdown");
      if (timeSpan !== null) {
        timeSpan.innerHTML = timeRemaining.current / 1000;
        if (timeRemaining.current === 0) {
          clearInterval(interval.current);
          setCurrentPage(0);
        }
      }
    }, 1000);
    e.currentTarget.removeEventListener("mousemove", handleMouseLive);
  };

  const handleInterval = () => {
    console.log("Pozvao se kreiranje intervala");

    interval.current = setInterval(() => {
      timeRemaining.current -= 1000;
      const timeSpan = document.querySelector(".countdown");
      if (timeSpan !== null) {
        timeSpan.innerHTML = timeRemaining.current / 1000;
        if (timeRemaining.current === 0) {
          clearInterval(interval.current);
          if (type) {
            setCurrentPage(0);
          } else {
            setSelectAccountPin(1);
          }
        }
      }
    }, 1000);
  };

  const handleChangePassword = (e) => {
    console.log("Poziva se izmena sifre");
    if (passwordCurrent) {
      const dosBtn = e.currentTarget.querySelector(".dos-btn");
      if (firstKey === secondKey && firstKey.length === 6) {
        dosBtn.classList.add("correct-btn");
        const time = setTimeout(() => {
          const handleChangePasswordInput = async () => {
            setPasswordCurrent(!passwordCurrent);
            setVisibility(!visibility);

            setLoginText(true);
            //Promena passworda poziv ka bazi

            try {
              const { data } = await axios.post(
                `${baseUrl}/auth/updateUserPassword.php`,
                {
                  user_id: loggedUser.id,
                  api_key: apiKey,
                  old_password: loggedUser.user_pin,
                  new_password: firstKey,
                }
              );

              if (data.status === 200) {
                const currentUser = { ...loggedUser, user_pin: firstKey };
                setLoggedUser(currentUser);
              }
            } catch (error) {
              console.log(error);
            }

            handleInterval();
            dosBtn.classList.remove("correct-btn");
            clearTimeout(time);
          };
          handleChangePasswordInput();
        }, 500);
      } else {
        dosBtn.classList.add("not-correct");
        const time = setTimeout(() => {
          dosBtn.classList.remove("not-correct");
          clearTimeout(time);
        }, 500);
      }
      setFirstKey("");
      setSecondKey("");
    } else {
      setPasswordCurrent(!passwordCurrent);
      clearInterval(interval.current);
      setVisibility(!visibility);
    }
  };

  const setFocusToElement = (e) => {
    console.log(e.currentTarget);
    e.currentTarget.focus();
  };

  const hanldeChangeType = (type) => {
    setChooseOption(type);
  };

  const handleChangePin = (e) => {
    console.log("Promena pina");
    if (pinCurrent) {
      const dosBtn = e.currentTarget.querySelector(".dos-btn");
      if (
        firstPin.toString() === secondPin.toString() &&
        firstPin.length !== 0
      ) {
        dosBtn.classList.add("correct-btn");
        const time = setTimeout(async () => {
          /*const newUsers = users.map((user) => {
            if (user.user_email === loggedUser.user_email)
              return { ...loggedUser, pattern: firstPin.toString() };
            else return user;
            
           
          });
          localStorage.setItem("users", JSON.stringify(newUsers));
*/
          let firstPinString = "";
          firstPin.forEach((pin) => {
            firstPinString += pin.toString();
          });

          try {
            const { data } = await axios.post(
              `${baseUrl}/auth/updateUserPattern.php`,
              {
                user_id: loggedUser.id,
                api_key: apiKey,
                user_pattern: firstPinString,
              }
            );
            if (data.status === 200) {
              setLoggedUser({ ...loggedUser, pattern: firstPinString });
              console.log("Menjam pin");
              if (type) {
                setSelectAccountPin(4);
              } else {
                setSelectAccountPin(5);
                setPinCurrent(false);
              }
            }
          } catch (error) {
            console.log(error);
          }

          dosBtn.classList.remove("correct-btn");
          clearTimeout(time);
        }, 500);
      } else {
        dosBtn.classList.add("not-correct");
        const time = setTimeout(() => {
          console.log("Promena zbog loseg unosa.");
          setPinCurrent(true);
          dosBtn.classList.remove("not-correct");
          clearTimeout(time);
        }, 500);
        setResetSwitch(true);
      }
    } else {
      console.log("Promena zbog chooseOption");
      if (chooseOption === "first" || chooseOption === "second")
        setPinCurrent(true);
    }
  };

  handleStyleInput();
  /*
  useEffect(() => {
    console.log("Interval iz useEffect");
    handleInterval();
    return () => clearInterval(interval.current);
  }, [logginText, setLoginText]);
*/
  console.log("render");
  useEffect(() => {
    if (!type) {
      setLoginText(!type);

      clearInterval(interval.current);
      handleInterval();
    }
    return () => clearInterval(interval.current);
  }, []);
  console.log("ovo je pinCurrent", pinCurrent);
  console.log("Ovo je login text", logginText);
  return (
    <div className="password-pattern-wrapper">
      {!pinCurrent && (
        <>
          <div className="counter-visibility-wrapper">
            {passwordCurrent ? (
              <div className="input-key-container">
                <div className="input-key">
                  {[...Array(6)].map((_, index) => (
                    <input
                      type="text"
                      maxLength="1"
                      key={index}
                      ref={(input) => {
                        firstPassword.current[index] = input;
                      }}
                      value={firstKey[index] || ""}
                      onChange={(e) =>
                        handleChange(index, e.target.value, setFirstKey)
                      }
                      onInput={() => handleInputKey(index, firstPassword)}
                      onKeyDown={(e) =>
                        handleBackspace(index, e, firstPassword)
                      }
                    />
                  ))}
                </div>
                <div className="input-key">
                  {[...Array(6)].map((_, index) => (
                    <input
                      type="text"
                      maxLength="1"
                      key={index}
                      ref={(input) => (secondPassword.current[index] = input)}
                      value={secondKey[index] || ""}
                      onChange={(e) =>
                        handleChange(index, e.target.value, setSecondKey)
                      }
                      onInput={() => handleInputKey(index, secondPassword)}
                      onKeyDown={(e) =>
                        handleBackspace(index, e, secondPassword)
                      }
                    />
                  ))}
                </div>
              </div>
            ) : (
              <>
                {logginText ? (
                  <>
                    {
                      <span className="countdown">
                        {!visibility ? timeRemaining.current / 1000 : ""}
                      </span>
                    }
                    <img
                      src={!visibility ? VisibleNon : Visible}
                      alt=""
                      draggable={false}
                      onMouseDown={handleKeyDown}
                      onMouseUp={handleKeyUp}
                      onTouchStart={handleKeyDown}
                      onTouchEnd={handleKeyUp}
                    />
                  </>
                ) : (
                  <p
                    className={
                      `font-size-${parseInt(
                        fontSize === undefined || fontSize === null
                          ? 0
                          : fontSize / 3
                      )}` +
                      ` font-style-${parseInt(
                        fontStyle === undefined || fontStyle === null
                          ? 0
                          : fontStyle / 3
                      )}`
                    }
                    style={
                      boldItalic.find((element) => parseInt(element) === 2) !==
                      undefined
                        ? {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined &&
                          boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                          }
                        : {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                    }
                  >
                    {handleConvertText(selectedLanguage.data.userLoginText)}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="password-button-edit" onClick={handleChangePassword}>
            <Button typeButton={typeButton} activation={true}>
              <div className="password-image-edit">
                {passwordCurrent ? (
                  <img className="visible-img" src={Send} alt=""></img>
                ) : (
                  <>
                    <img className="visible-img" src={Edit} alt="" />
                    <img className="visible-img" src={Key} alt="" />
                  </>
                )}
              </div>
            </Button>
          </div>
          <div className="password-input">
            {!visibility
              ? leter.map((item) => {
                  return <span>{item}</span>;
                })
              : loggedUser.user_pin.split("").map((item) => {
                  return <span>{item}</span>;
                })}
          </div>
        </>
      )}

      <div className="password-button-edit" onClick={handleChangePin}>
        <Button activation={true} typeButton={typeButton}>
          <div className="password-image-edit">
            {pinCurrent ? (
              <>
                <img className="visible-img" src={Send} alt="" />
              </>
            ) : (
              <>
                <img className="visible-img" src={Edit} alt="" />
                <img className="visible-img" src={Pin} alt="" />
              </>
            )}
          </div>
        </Button>
      </div>
      {!pinCurrent ? (
        <div className="pattern-unlock-wrapper">
          <div className="pattern-unlock">
            {chooseOption === "second" ? (
              <PinUnlock height={"220"} width={"242"} draft={false} />
            ) : chooseOption === "first" ? (
              <Unlockscreen
                type={"fourth"}
                winPattern={visibility ? loggedUser.pattern : ""}
                draft={false}
              />
            ) : (
              <Unlockscreen
                type={"first"}
                winPattern={visibility ? loggedUser.pattern : ""}
                draft={false}
              />
            )}
          </div>
          <div className="pattern-type">
            <div
              className="unlock-screen"
              onClick={() => hanldeChangeType("first")}
            >
              {
                <div className="pattern-unlock">
                  <Unlockscreen
                    type={"third"}
                    winPattern={"1235987"}
                    draft={false}
                  />
                </div>
              }
            </div>
            <div onClick={() => hanldeChangeType("second")}>
              {<PinUnlock draft={false} />}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="pattern-unlock">
            {chooseOption === "first" ? (
              <Unlockscreen
                type={"fourth"}
                winPattern={firstPin.toString()}
                draft={true}
                setInputPattern={setFirstPin}
                resetSwitch={resetSwitch}
                setResetSwitch={setResetSwitch}
              />
            ) : (
              <PinUnlock
                height={300}
                width={300}
                inputPattern={firstPin}
                setInputPattern={setFirstPin}
                draft={true}
                setResetSwitch={setResetSwitch}
                resetSwitch={resetSwitch}
              />
            )}
          </div>
          <div className="pattern-unlock">
            {chooseOption === "first" ? (
              <Unlockscreen
                type={"second"}
                draft={true}
                setInputPattern={setSecondPin}
                winPattern={firstPin.toString()}
                resetSwitch={resetSwitch}
                setResetSwitch={setResetSwitch}
              />
            ) : (
              <PinUnlock
                height={300}
                width={300}
                inputPattern={secondPin}
                setInputPattern={setSecondPin}
                draft={true}
                setResetSwitch={setResetSwitch}
                resetSwitch={resetSwitch}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
