import { useEffect, useState } from "react";
import Button from "../../Button/Button";
import axios from "axios";
import LanguagesHeader from "../LanguagesHeader/LanguagesHeader";
import { useGlobalContext } from "../../Context/Context";
import "./languageNewCountry.css";

const LanguageNewCountry = ({
  currentPage,
  setCurrentPage,
  setSelectedCountry,
}) => {
  const handleOpenLanguage = (lang) => {
    console.log(lang);
    setSelectedCountry(lang);
    setCurrentPage(0);
  };
  const [newLanguages, setNewLanguages] = useState([]);

  const { baseUrl } = useGlobalContext();
  const { loggedUser, boldItalic, fontSize, fontStyle } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { typeButton, letterSize } = useGlobalContext();

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    const requestBody = {
      user_id: loggedUser?.id,
      api_key: apiKey,
    };

    axios
      .post(`${baseUrl}/languages/read.php`, requestBody)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data);
          setNewLanguages(response.data.data);
        } else {
          console.error("Failed to fetch languages:", response.data.status);
        }
      })
      .catch((error) => console.error("Error fetching languages:", error));
  }, []);

  console.log(newLanguages);
  return (
    <>
      <div className="languages-container-new">
        <div className="language-list-new">
          {newLanguages.map((lang) => {
            return (
              <div className="language-section-new">
                <div className="flag-button">
                  <button
                    className="flag-button"
                    style={{ backgroundImage: `url(${lang.lang_flag_base64})` }}
                  ></button>
                </div>
                <div
                  className="language-text"
                  onClick={() => handleOpenLanguage(lang)}
                >
                  <Button
                    typeButton={typeButton}
                    className={
                      `font-size-${parseInt(
                        fontSize === undefined || fontSize === null
                          ? 0
                          : fontSize / 3
                      )}` +
                      ` font-style-${parseInt(
                        fontStyle === undefined || fontStyle === null
                          ? 0
                          : fontStyle / 3
                      )}`
                    }
                    style={
                      boldItalic.find((element) => parseInt(element) === 2) !==
                      undefined
                        ? {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined &&
                          boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                          }
                        : {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                    }
                  >
                    {handleConvertText(lang.lang_name.toUpperCase())}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LanguageNewCountry;
