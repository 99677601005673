import "./trustFolder.css";
import Button from "../Button/Button";
import Back from "../picture/Back.png";
import Send from "../picture/Send.png";
import Pen from "../picture/Pen.png";
import axios from "axios";
import { useRef, useEffect } from "react";
import TrustContact from "../picture/TrustContact.png";
import TrustContactMailOne from "../picture/TrustEmaiUser1.png";
import TrustContactMailTwo from "../picture/TrustEmailUser2.png";
import TrustContactMessageOne from "../picture/TrustMessageUser1.png";
import TrustContactMessageTwo from "../picture/TrustMessageUser2.png";
import { useState } from "react";
import { useGlobalContext } from "../Context/Context";
import { format } from "date-fns";
const TrustFolder = ({ setCurrentPage, type, setSelectAccountPin }) => {
  const firstContact = useRef({ id: 0, email: "" });
  const confirmFirstContact = useRef({ id: 0, email: "" });
  const secondContact = useRef({ id: 0, email: "" });
  const confirmSecondContact = useRef({ id: 0, email: "" });
  const [firstContactSeted, setFirstContactSeted] = useState(true);
  const [secondContactSeted, setSecondContactSeted] = useState(true);
  const [updateContactSeted, setUpdateContactSeted] = useState(true);
  const { loggedUser } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const {
    typeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
    setIsFromFirstTrustContact,
  } = useGlobalContext();

  const [chooseOption, setChooseOption] = useState({ index: 0, value: false });

  const handleBack = () => {
    if (type) {
      setIsFromFirstTrustContact(true);
      setCurrentPage(3);
    } else {
      setSelectAccountPin(1);
    }
  };

  const handleChoseOption = (index) => {
    let timeOut = setTimeout(() => {
      setChooseOption({ index: -1, value: false });
    }, 200);
    setChooseOption({ index: index, value: true });
  };

  const handleChangeText = (e, index) => {
    console.log(e.currentTarget.value);
    if (index === 1) firstContact.current.email = e.currentTarget.value;
    if (index === 2) confirmFirstContact.current.email = e.currentTarget.value;
    if (index === 3) secondContact.current.email = e.currentTarget.value;
    if (index === 4) confirmSecondContact.current.email = e.currentTarget.value;
  };
  console.log(firstContact.current.email);
  const handleInputTrustContact = async () => {
    console.log("Prosao" + updateContactSeted);
    console.log(firstContact.current);
    console.log(confirmFirstContact.current);
    if (updateContactSeted) {
      if (
        firstContact.current.email === confirmFirstContact.current.email &&
        firstContact.current.email.length !== 0 &&
        firstContactSeted
      ) {
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/createTrustedContacts.php`,
            {
              user_id: loggedUser.id,
              api_key: apiKey,
              trusted_contact_email: firstContact.current.email,
            }
          );
          console.log(data);
          if (data.status === 200) {
            firstContact.current.id = data.trusted_contact_id;
            confirmFirstContact.current.id = data.trusted_contact_id;
            setFirstContactSeted(false);
            console.log("Uspesno unesen mail");
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (
        secondContact.current.email === confirmSecondContact.current.email &&
        secondContact.current.email.length !== 0 &&
        secondContactSeted
      ) {
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/createTrustedContacts.php`,
            {
              user_id: loggedUser.id,
              api_key: apiKey,
              trusted_contact_email: secondContact.current.email,
            }
          );
          if (data.status === 200) {
            console.log("Uspesno unesen mail", data);
            secondContact.current.id = data.trusted_contact_id;
            confirmSecondContact.current.id = data.trusted_contact_id;
            setSecondContactSeted(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      console.log("Azuriraj korisnika" + firstContactSeted);

      try {
        const { data } = await axios.put(
          `${baseUrl}/auth/updateTrustedContacts.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
            trusted_contact_id: firstContactSeted
              ? firstContact.current.id
              : secondContact.current.id,
            trusted_contact_email: firstContactSeted
              ? firstContact.current.email
              : secondContact.current.email,
          }
        );
        console.log(data);
        if (data.status === 200) {
          if (firstContactSeted) {
            setFirstContactSeted(false);
          } else {
            setSecondContactSeted(false);
          }
          setUpdateContactSeted(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdateFirstContact = () => {
    setFirstContactSeted(true);
    setUpdateContactSeted(false);
  };

  const handleUpdateSecondContact = () => {
    setSecondContactSeted(true);
    setUpdateContactSeted(false);
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleMessageContact = async (e, first, second, index) => {
    console.log(first, second);
    if (first.length !== 0 && first === second) {
      let customBtn = e.currentTarget.querySelector(".dos-btn");
      if (customBtn === null) {
        customBtn = e.currentTarget.querySelector(".dos-btn-round-none-border");
      }

      if (customBtn === null) {
        customBtn = e.currentTarget.querySelector(".dos-btn-round-border ");
      }

      if (loggedUser === undefined) {
        customBtn.classList.add("not-correct");
        const time = setTimeout(() => {
          customBtn.classList.remove("not-correct");
          return;
        }, 500);
      } else {
        if (validateEmail(first)) {
          try {
            const response = await axios.post(
              `${baseUrl}/chat/checkIfEmailExists.php`,
              {
                email: first,
                api_key: apiKey,
              }
            );
            const otherUserId = response.data.user_id;
            console.log(response);
            if (response.data.status === 200) {
              const dataChat = await axios.post(
                `${baseUrl}/chat/getAllMessagesByTwoUsers.php`,
                {
                  user_id: loggedUser.id,
                  other_user_id: otherUserId,
                  api_key: apiKey,
                }
              );
              console.log(dataChat);
              const other_user_id = dataChat.data.chat_room_id;
              if (dataChat.data.status === 200) {
                const { data } = await axios.post(
                  `${baseUrl}/chat/sendMessage.php`,
                  {
                    sender: loggedUser.user_email,
                    text: `User ${loggedUser.user_email} has named you a trusted contact`,
                    date: format(new Date(), "dd.MM.yyyy HH:mm"),
                    api_key: apiKey,
                    chat_room_id: parseInt(other_user_id), //treba chatRoom
                  }
                );
                console.log(data);
                if (data.message === "Message created successfully") {
                  console.log("Bojim dugme ", customBtn);
                  customBtn.classList.add("correct");
                  const time = setTimeout(async () => {
                    customBtn.classList.remove("correct");
                  }, 500);
                }
              } else {
                const { data } = await axios.post(
                  `${baseUrl}/chat/createChatRoomAfterEmail.php`,
                  {
                    logged_user_id: loggedUser.id,
                    other_user_id: otherUserId,
                    api_key: apiKey,
                  }
                );
                console.log(data);
                if (data.status === 200) {
                  const dataMessage = await axios.post(
                    `${baseUrl}/chat/sendMessage.php`,
                    {
                      sender: loggedUser.user_email,
                      text: `User ${loggedUser.user_email} has named you a trusted contact`,
                      date: format(new Date(), "dd.MM.yyyy HH:mm"),
                      api_key: apiKey,
                      chat_room_id: parseInt(data.chat_room_id), //treba chatRoom
                    }
                  );
                  console.log(dataMessage);
                  if (dataMessage.message === "Message created successfully") {
                    console.log("Bojim dugme ", customBtn);
                    customBtn.classList.add("correct");
                    const time = setTimeout(async () => {
                      customBtn.classList.remove("correct");
                    }, 500);
                  }
                }
              }

              console.log("U bazi je");
            } else {
              console.log("Nije u bazi");
              customBtn.classList.add("not-correct");
              const time = setTimeout(() => {
                customBtn.classList.remove("not-correct");
                return;
              }, 500);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("Nije mail");
        }
        return;
      }
    }
  };

  useEffect(() => {
    const handelTrustContact = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/auth/getTrustedContactsByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        if (data.status === 200) {
          if (data.contacts.length === 2) {
            firstContact.current.id = data.contacts[0].id;
            firstContact.current.email = data.contacts[0].email;
            confirmFirstContact.current.id = data.contacts[0].id;
            confirmFirstContact.current.email = data.contacts[0].email;
            secondContact.current.id = data.contacts[1].id;
            secondContact.current.email = data.contacts[1].email;
            confirmSecondContact.current.id = data.contacts[1].id;
            confirmSecondContact.current.email = data.contacts[1].email;
            setFirstContactSeted(false);
            setSecondContactSeted(false);
          }
          if (data.contacts.length === 1) {
            firstContact.current.id = data.contacts[0].id;
            firstContact.current.email = data.contacts[0].email;
            confirmFirstContact.current.id = data.contacts[0].id;
            confirmFirstContact.current.email = data.contacts[0].email;
            setFirstContactSeted(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    handelTrustContact();
  }, []);

  const handleEmailContact = (email, index) => {
    handleChoseOption(index);
    if (email && email.length > 0) {
      let subject = "You have been added as a trusted contact";
      let body = `User ${loggedUser.user_email} has added you to their trusted contacts.`;

      // Properly format the mailto URL with correct encoding
      let mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoUrl;
    } else {
      alert("Please enter a valid email address.");
    }
  };

  return (
    <div className="wrapper-trust-contact">
      {updateContactSeted ? (
        <>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={TrustContactMailOne}
                alt=""
              ></img>
              {!firstContactSeted && (
                <div
                  className="wrapper-button-edit"
                  onClick={handleUpdateFirstContact}
                >
                  <Button typeButton={typeButton}>
                    <img src={Pen} alt="" className="visible-img" />
                  </Button>
                </div>
              )}
            </div>
            {firstContactSeted ? (
              <>
                <input type="text" onChange={(e) => handleChangeText(e, 1)} />
                <input type="text" onChange={(e) => handleChangeText(e, 2)} />
              </>
            ) : (
              <span
                className={
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {handleConvertText(firstContact.current.email)}
              </span>
            )}
            <div className="wrapper-trust-contact-button">
              {firstContactSeted && (
                <>
                  <div
                    className="trust-contact-button"
                    onClick={() =>
                      handleEmailContact(firstContact.current.email, 1)
                    }
                  >
                    <Button
                      typeButton={typeButton}
                      active={chooseOption.index === 1}
                    >
                      <img
                        src={TrustContactMailOne}
                        alt=""
                        className="trust-contact-button-image"
                      />
                    </Button>
                  </div>
                  <div
                    className="trust-contact-button"
                    onClick={(e) =>
                      handleMessageContact(
                        e,
                        firstContact.current.email,
                        confirmFirstContact.current.email,
                        2
                      )
                    }
                  >
                    <Button typeButton={typeButton} activation={true}>
                      <img
                        src={TrustContactMessageOne}
                        alt=""
                        className="trust-contact-button-image"
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={TrustContactMailTwo}
                alt=""
              ></img>
              {!secondContactSeted && (
                <div
                  className="wrapper-button-edit"
                  onClick={() => handleUpdateSecondContact()}
                >
                  <Button typeButton={typeButton}>
                    <img src={Pen} alt="" className="visible-img" />
                  </Button>
                </div>
              )}
            </div>
            {secondContactSeted ? (
              <input type="text" onChange={(e) => handleChangeText(e, 3)} />
            ) : (
              <span
                className={
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {" "}
                {handleConvertText(secondContact.current.email)}
              </span>
            )}

            {secondContactSeted && (
              <input type="text" onChange={(e) => handleChangeText(e, 4)} />
            )}
            <div className="wrapper-trust-contact-button">
              {secondContactSeted && (
                <>
                  <div
                    className="trust-contact-button"
                    onClick={() =>
                      handleEmailContact(secondContact.current.email, 3)
                    }
                  >
                    <Button
                      typeButton={typeButton}
                      active={chooseOption.index === 3}
                    >
                      {" "}
                      <img
                        src={TrustContactMailTwo}
                        alt=""
                        className="trust-contact-button-image visible-img"
                      />
                    </Button>
                  </div>
                  <div
                    className="trust-contact-button"
                    onClick={(e) =>
                      handleMessageContact(
                        e,
                        secondContact.current.email,
                        confirmSecondContact.current.email,
                        4
                      )
                    }
                  >
                    <Button typeButton={typeButton} activation={true}>
                      {" "}
                      <img
                        src={TrustContactMessageTwo}
                        alt=""
                        className="trust-contact-button-image visible-img"
                      />{" "}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="finish-contact-button" onClick={() => handleBack()}>
            <Button typeButton={typeButton}>
              <img src={Back} alt="" className="visible-img" />{" "}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="trust-contact">
            <div className="trust-contact-seted">
              <img
                className="trust-contact-picture"
                src={
                  firstContactSeted ? TrustContactMailOne : TrustContactMailTwo
                }
                alt=""
              ></img>
            </div>

            <input
              type="text"
              defaultValue={
                firstContactSeted
                  ? firstContact.current.email
                  : secondContact.current.email
              }
              onChange={(e) => handleChangeText(e, firstContactSeted ? 1 : 3)}
            />
            <input
              type="text"
              defaultValue={
                firstContactSeted
                  ? confirmFirstContact.current.email
                  : confirmSecondContact.current.email
              }
              onChange={(e) => handleChangeText(e, firstContactSeted ? 2 : 4)}
            />
          </div>
          <div
            className={
              "trust-contact-privilege " +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText("Ekran za definisanje privilegija")}
          </div>
        </>
      )}

      {firstContactSeted || secondContactSeted ? (
        <div
          className="finish-contact-button"
          onClick={handleInputTrustContact}
        >
          {
            <Button activation={true} typeButton={typeButton}>
              <img src={Send} alt="" className="visible-img" />
            </Button>
          }
        </div>
      ) : (
        <div className="finish-contact-button"></div>
      )}
    </div>
  );
};

export default TrustFolder;
