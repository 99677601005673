import React, { useState, useEffect } from "react";
import LogoPage from "./LogoPage/LogoPage";
import LanguageApp from "./LanguagePage/LanguageApp/LanguageApp";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import Login from "./Login/Login";
import ForgetPassword from "./Login/ForgetPassword";
import RegisterChoose from "./RegisterChoose/RegisterChoose";
import Sidebar from "./Sidebar/Sidebar";
import ControlPassword from "./forgetPassword/ControlPassword/ControlPassword";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"; // Import the service worker registration
import "./app.css";
import SendRegistration from "./SendRegistration/SendRegistration";
import { useGlobalContext } from "./Context/Context";
import LanguageFileNew from "./LanguagePage/LangugageFlag/LanguageFileNew";

const App = () => {
  const { loggedUser, selectedLanguage, isFromFirstTrustContact } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(
    loggedUser === undefined ? 0 : 4
  );

  // Prevent swipe navigation globally
  useEffect(() => {
    const preventSwipeNavigation = (e) => {
      const threshold = 50; // Allow some movement but block large swipes
      if (Math.abs(e.deltaX) > threshold || Math.abs(e.deltaY) > threshold) {
        e.preventDefault();
      }
    };

    const preventTouchNavigation = (e) => {
      const threshold = 50;
      if (Math.abs(e.changedTouches[0].clientX) > threshold) {
        e.preventDefault();
      }
    };

    // Attach event listeners to block swipe navigation
    window.addEventListener("wheel", preventSwipeNavigation, { passive: false });
    window.addEventListener("touchmove", preventTouchNavigation, { passive: false });

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener("wheel", preventSwipeNavigation);
      window.removeEventListener("touchmove", preventTouchNavigation);
    };
  }, []);

  return (
    <div className="app">
      {currentPage === 0 && !loggedUser && (
        <LogoPage setCurrentPage={setCurrentPage} />
      )}
      {currentPage === 1 && !loggedUser && (
        <LanguageApp setCurrentPageGlobal={setCurrentPage} />
      )}
      {currentPage === 2 && !loggedUser && (
        <TermsOfUse setCurrentPage={setCurrentPage} />
      )}
      {((currentPage === 3 && !loggedUser) ||
        (currentPage === 3 && isFromFirstTrustContact)) && (
        <Login setCurrentPage={setCurrentPage} currentPage={currentPage} />
      )}
      {currentPage === 4 && <Sidebar setCurrentPage={setCurrentPage} />}
      {currentPage === 5 && <ForgetPassword />}
      {currentPage === 6 && <RegisterChoose setCurrentPage={setCurrentPage} />}
      {currentPage === 7 && <ControlPassword setCurrentPage={setCurrentPage} />}
      {currentPage === 8 && (
        <SendRegistration setCurrentPage={setCurrentPage} />
      )}
    </div>
  );
};

// Register the service worker
serviceWorkerRegistration.register();

export default App;
