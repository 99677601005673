import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import documentImg from "../dokument.png";
import { compareAsc, format } from "date-fns";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    gap: "2vh",
    padding: "1vh 1vw",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "25vh",
    border: "1px solid black",
  },
  /* UPPER HEADER */
  upperHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "60%",
  },
  imgContainer: {
    height: "100%",
    width: "16.7%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    height: "80%",
    width: "80%",
  },
  upperTable: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "85%",
    border: "1px solid black",
  },
  rowHeader: {
    display: "flex",
    flexDirection: "row",
    height: "40%",
    width: "100%",
  },
  cellHeader: {
    fontSize: "12",
    textAlign: "start",
    flex: "1",
    height: "100%",
    border: "1px solid black",
    padding: "1%",
    borderCollapse: "colapse",
  },
  rowText: {
    display: "flex",
    flexDirection: "row",
    height: "20%",
    width: "100%",
  },
  cellText: {
    fontSize: "8",
    textAlign: "start",
    flex: "1",
    height: "100%",
    border: "1px solid black",
    padding: "1%",
    borderCollapse: "colapse",
  },
  rowReport: {
    width: "100%",
    height: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textReport: {
    fontSize: "14",
  },
  /* LOWER HEADER */
  lowerHeader: {
    display: "flex",
    flexDirection: "column",
    height: "40%",
    width: "100%",
  },
  rowLowerHeader: {
    display: "flex",
    flexDirection: "row",
    height: "50%",
    width: "100%",
  },
  cellCommentsItems: {
    flex: "1",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
  },
  textCellItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "5%",
    width: "100%",
    height: "50%",
  },
  itemText: {
    fontSize: "12",
  },
  /* LIST OF ITEMS */
  itemList: {
    display: "flex",
    flexDirection: "column",
    gap: "5vh",
    width: "100%",
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2vh",
  },
  /* iTEM HEADER */
  itemHeader: {
    height: "10vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
  },
  itemNumber: {
    width: "5%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
  },
  itemCategory: {
    width: "5%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
  },
  itemName: {
    height: "100%",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid black",
    paddingLeft: "1%",
  },
  itemHeaderInfo: {
    height: "100%",
    width: "20%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
  },
  itemHeaderInfoCell: {
    height: "33%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid black",
    paddingLeft: "2%",
  },
  itemHeaderInfoCellText: {
    fontSize: "8",
  },
  /* ITEM FIELD */
  itemField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1vh",
  },
  itemFieldTitle: {
    textAlign: "left",
    fontSize: "10",
    width: "100%",
    paddingLeft: "1%",
  },
  itemFieldText: {
    textAlign: "left",
    fontSize: "8",
    width: "100%",
    padding: "0 1%",
  },
  imagesWrapper: {
    height: "15vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "2vw",
  },
  itemImage: {
    height: "100%",
    width: "15%",
  },
  documentsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1vh",
    paddingLeft: "1%",
  },
  itemDocument: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
    height: "2vh",
    gap: "2vw",
  },
  itemDocumentImg: {
    height: "100%",
    aspectRatio: "1/1",
  },
  itemDocumentText: {
    fontSize: "8",
  },
  // COMMENTS
  commentsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2vh",
    width: "100%",
  },
  comment: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    paddingLeft: "3%",
    gap: "1vh",
  },
  commentInfoHeader: {
    display: "flex",
    flexDirection: "row",
    height: "3vh",
    width: "50%",
  },
  commentInfoCell: {
    fontSize: "8",
    textAlign: "start",
    paddingLeft: "1vw",
    height: "100%",
    flex: "5",
  },
  commentInfoNumber: {
    fontSize: "8",
    textAlign: "start",
    paddingLeft: "1vw",
    height: "100%",
    flex: "1",
  },
  commentImage: {
    height: "15vh",
    width: "10vw",
  },
  // CATEGORIES
  itemCategories: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "1",
    fontSize: "8",
    border: "1px solid black",
    padding: "2 1%",
    height: "100%",
  },
  itemCategoryEmpty: {
    backgroundColor: "black",
  },
});

function Pdf({
  itemList,
  commentsActive,
  linksActive,
  documentsActive,
  imagesActive,
  account,
  location,
  loggedUser,
  numberOfCopy,
  categoriesItem,
}) {
  const handleNumberOfcomment = () => {
    let numberCom = 0;
    itemList.map((item) => {
      if (item.comments.length !== 0) {
        numberCom += item.comments.length;
      }
    });
    console.log(numberCom);
    return numberCom;
  };

  console.log(itemList);
  console.log(linksActive);

  return (
    <Document title={`REPORT - ` + format(new Date(), "dd.MM.yyyy")}>
      <Page size="A4" style={styles.page}>
        {/* header of pdf */}
        <View style={styles.header}>
          {/* upper part from image to report */}
          <View style={styles.upperHeader}>
            <View style={styles.imgContainer}>
              <Image src={account.logo} style={styles.logoImg} />
            </View>
            <View style={styles.upperTable}>
              {/* first row */}
              <View style={styles.rowHeader}>
                <Text style={styles.cellHeader}>COMPANY</Text>
                <Text style={styles.cellHeader}>LOCATION</Text>
                <Text style={styles.cellHeader}>DATE</Text>
                <Text style={styles.cellHeader}>PERIOD</Text>
              </View>
              {/* second row */}
              <View style={styles.rowText}>
                <Text style={styles.cellText}>{account.name}</Text>
                <Text style={styles.cellText}>{location.location_name}</Text>
                <Text style={styles.cellText}>
                  {itemList.length !== 0 &&
                    format(itemList[0].timeCreated, "dd.MM.yyyy")}
                </Text>
                <Text style={styles.cellText}>
                  {itemList.length !== 0 &&
                    (compareAsc(
                      new Date(
                        new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)
                      ),
                      new Date(
                        new Date(
                          itemList[itemList.length - 1].timeCreated
                        ).setHours(0, 0, 0, 0)
                      )
                    ) !== 0
                      ? format(
                          itemList[itemList.length - 1].timeCreated,
                          "dd.MM.yyyy"
                        )
                      : "")}
                </Text>
              </View>
              {/* REPORT */}
              <View style={styles.rowReport}>
                <Text style={styles.textReport}>
                  REPORT - {format(new Date(), "dd.MM.yyyy")}
                </Text>
              </View>
            </View>
          </View>
          {/* lower part with two rows */}
          <View style={styles.lowerHeader}>
            <View style={styles.rowLowerHeader}>
              <Text style={styles.cellHeader}>CATEGORIES</Text>
              <Text style={styles.cellHeader}>
                NUMBER OF ITEMS AND COMMENTS
              </Text>
              <Text style={styles.cellHeader}>SENT BY</Text>
              <Text style={styles.cellHeader}>TIME</Text>
              <Text style={styles.cellHeader}>NUMBER OF COPY</Text>
              <Text style={styles.cellHeader}></Text>
            </View>
            <View style={styles.rowLowerHeader}>
              <View style={styles.itemCategories}>
                {categoriesItem.length !== 0 ? (
                  categoriesItem.map((item) => {
                    const newStyles = {
                      wrapper: {
                        backgroundColor: item.color,
                        height: "50%",
                        width: "25%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                      text: {
                        color: item.letter_color,
                        fontSize: "10",
                      },
                    };
                    return (
                      <View style={newStyles.wrapper}>
                        <Text style={newStyles.text}>{item.letter}</Text>
                      </View>
                    );
                  })
                ) : (
                  <View style={styles.itemCategoryEmpty}></View>
                )}
              </View>
              <View style={styles.cellText}>
                <Text style={styles.itemText}>{itemList.length}</Text>
                <Text style={styles.itemText}>{handleNumberOfcomment()}</Text>
              </View>
              <Text style={styles.cellText}>{loggedUser.user_email}</Text>
              <Text style={styles.cellText}>{format(new Date(), "hh:mm")}</Text>
              <Text style={styles.cellText}>
                {numberOfCopy !== undefined && numberOfCopy}
              </Text>
              <Text style={styles.cellText}></Text>
            </View>
          </View>
        </View>
        {/* list of items with selected data */}
        <View style={styles.itemList}>
          {itemList?.map((item, index) => {
            console.log(item);
            console.log(item.comments);
            return (
              <View wrap={false} style={styles.itemWrapper} key={item.id}>
                {/* item header */}
                <View style={styles.itemHeader}>
                  <View style={styles.itemNumber}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={styles.itemCategory}>
                    {item?.categories?.map((category) => {
                      const newStyles = {
                        wrapper: {
                          backgroundColor: category.color,
                          width: "100%",
                          height:
                            String(
                              100 /
                                item.categories.filter((cat) => cat.selected)
                                  .length
                            ) + "%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                        text: {
                          color: item.letter_color,
                          fontSize: "10",
                        },
                      };
                      console.log(category);
                      return (
                        category.selected && (
                          <View style={newStyles.wrapper} key={category.id}>
                            <Text style={newStyles.text}>
                              {category.letter}
                            </Text>
                          </View>
                        )
                      );
                    })}
                  </View>
                  <View style={styles.itemName}>
                    <Text>{item.title}</Text>
                  </View>
                  <View style={styles.itemHeaderInfo}>
                    <View style={styles.itemHeaderInfoCell}>
                      <Text style={styles.itemHeaderInfoCellText}>
                        {item.name}
                      </Text>
                    </View>
                    <View style={styles.itemHeaderInfoCell}>
                      <Text style={styles.itemHeaderInfoCellText}>
                        {format(item.timeCreated, "HH:mm dd.MM.yyyy")}
                      </Text>
                    </View>
                    <View style={styles.itemHeaderInfoCell}>
                      <Text style={styles.itemHeaderInfoCellText}>
                        {item.comments.length}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* item fields */}
                {item?.data?.map((field) => {
                  console.log(field);
                  const { id, data, name } = field;
                  return (
                    <View style={styles.itemField} key={id}>
                      <Text style={styles.itemFieldTitle}>{name}</Text>
                      <Text style={styles.itemFieldText}>{data}</Text>
                    </View>
                  );
                })}
                {/* item images */}
                <Text style={styles.itemFieldTitle}>Images</Text>
                {imagesActive && item.images.length !== 0 && (
                  <View style={styles.imagesWrapper}>
                    {item.images.map((image, index) => {
                      console.log(image.data);
                      return (
                        <Image
                          src={image.data}
                          style={styles.itemImage}
                          key={index}
                        />
                      );
                    })}
                  </View>
                )}
                {/* item documents */}
                <Text style={styles.itemFieldTitle}>Documents</Text>
                {documentsActive &&
                  item.documents.length !== 0 &&
                  item.documents[0].name !== "" && (
                    <View style={styles.documentsWrapper}>
                      {item.documents.map((document, index) => {
                        return (
                          <View style={styles.itemDocument} key={index}>
                            <Image
                              src={documentImg}
                              style={styles.itemDocumentImg}
                            ></Image>
                            <Text style={styles.itemDocumentText}>
                              {document.name}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                  )}
                {/* item links */}
                <Text style={styles.itemFieldTitle}>Links</Text>
                {linksActive && item.links.length !== 0 && (
                  <View style={styles.documentsWrapper}>
                    {item.links.map((link) => {
                      return (
                        <Text
                          style={styles.itemDocumentText}
                          key={link.link_id}
                        >
                          {link.link}
                        </Text>
                      );
                    })}
                  </View>
                )}
                {/* item comments */}
                <Text style={styles.itemFieldTitle}>Comments</Text>
                {commentsActive && item.comments.length !== 0 && (
                  <View style={styles.commentsWrapper}>
                    {item.comments.map((comment, index) => {
                      console.log("Pozivam se za index" + index, comment);
                      return (
                        <View style={styles.comment} key={comment.id}>
                          <View style={styles.commentInfoHeader}>
                            <Text style={styles.commentInfoNumber}>
                              {index + 1}
                            </Text>
                            <Text style={styles.commentInfoCell}>
                              {comment.user}
                            </Text>
                            <Text style={styles.commentInfoCell}>
                              {comment.timeCreated}
                            </Text>
                          </View>
                          <Text style={styles.commentInfoText}>
                            {comment?.text !== undefined ? comment.text : ""}
                          </Text>
                          {comment.image[0] !== undefined &&
                            comment.image.length !== 0 &&
                            comment.image[0].data !== null && (
                              <>
                                <Text style={styles.itemFieldTitle}>Image</Text>
                                <Image
                                  src={comment.image[0].data}
                                  style={styles.commentImage}
                                />
                              </>
                            )}
                          {comment.document.length !== 0 && (
                            <View style={styles.itemDocument}>
                              <Image
                                src={documentImg}
                                style={styles.itemDocumentImg}
                              />
                              <Text style={styles.itemDocumentText}>
                                {comment.document[0].name}
                              </Text>
                            </View>
                          )}
                          {comment.link !== "" && (
                            <Text style={styles.itemDocumentText}>
                              {comment.link}
                            </Text>
                          )}
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}

export default Pdf;
