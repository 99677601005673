import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";
import "./sendRegistration.css";

const SendRegistration = ({ setCurrentPage }) => {
  const { selectedLanguage } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleCopyText = (e) => {
    const dosBtn = e.currentTarget.querySelector(".dos-btn");
    dosBtn.classList.add("correct-btn");
    dosBtn.innerHTML = selectedLanguage.data.copiedText.toUpperCase();
    console.log(dosBtn);
    navigator.clipboard.writeText(
      `${selectedLanguage.data.dear},\n\n${selectedLanguage.data.sendRegistrationCopyText}`
    );
    const time = setTimeout(() => {
      setCurrentPage(3);
      dosBtn.classList.remove("correct-btn");
      clearTimeout(time);
    }, 500);
  };
  console.log(selectedLanguage);
  return (
    <div className="wrapper-send-registration">
      <div className="wrapper-information">
        <span
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.dear)},
        </span>
        <span
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.sendRegistrationData)}
        </span>
        <div className="wrapper-send-to">
          <span
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {" "}
            {handleConvertText(
              selectedLanguage.data.sendRegistrationDataMail
            )}{" "}
          </span>
          <span
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {" "}
            {handleConvertText("registracija@nadzor.app")}
          </span>
        </div>
      </div>
      <div className="wrapper-copy-text">
        <span
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.dear)},
        </span>
        <span
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.sendRegistrationCopyText)}
        </span>
      </div>
      <div className="copy-text-button" onClick={handleCopyText}>
        <Button
          activation={true}
          typeButton={typeButton}
          className={
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.copyText.toUpperCase())}
        </Button>
      </div>
    </div>
  );
};

export default SendRegistration;
