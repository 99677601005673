import Button from "../Button/Button";
import "./styleScreen.css";
import SettingsDefault from "../picture/SettingsDefault.png";
import axios from "axios";
import Notebook from "../picture/Notes.png";
import Send from "../picture/Send.png";
import { useState } from "react";
import { useGlobalContext } from "../Context/Context";
import { eventWrapper } from "@testing-library/user-event/dist/utils";

const StyleScreen = ({ setSelectAccount, setSelectAccountPin }) => {
  const { loggedUser, setLoggedUser } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { selectedLanguage } = useGlobalContext();
  const { typeButton, setTypeButton } = useGlobalContext();
  const { colorButton, setColorButton } = useGlobalContext();
  const { letterSize, setLetterSize } = useGlobalContext();
  const { boldItalic, setBoldItalic } = useGlobalContext([]);
  const { fontSize, setFontSize } = useGlobalContext();
  const { fontStyle, setFontStyle } = useGlobalContext();

  const [currentLetterSize, setCurrentLetterSize] = useState(letterSize);
  const [currentBoldItalic, setCurrentBoldItalic] = useState(boldItalic);
  const [currentFontSize, setCurrentFontSize] = useState(fontSize);
  const [currentFontStyle, setCurrentFontStyle] = useState(fontStyle);
  const [colorButtonCurrent, setColorButtonCurrent] = useState(colorButton);
  const [typeButtonCurrent, setTypeButtonCurrent] = useState(typeButton);
  const [typeStyleCurrent, setTypeStyleCurrent] = useState(typeButton);
  console.log("settings");

  let color = [
    "#ffffff",
    "#ffff00",
    "#ffbc14",
    "#00ff00",
    "#0000ff",
    "#2a2a2a",
  ];

  const handleSetTypeButton = (index) => {
    setTypeButtonCurrent(index);
  };

  const handleSelectColor = (index) => {
    setColorButtonCurrent(color[index / 3]);
  };

  const handleFontSize = (index) => {
    setCurrentFontSize(index);
    console.log(index);
  };

  const handleFontStyle = (index) => {
    setCurrentFontStyle(index);
    console.log(index);
  };

  const handleSendTypeButton = async () => {
    console.log("Postavljen tip", typeButtonCurrent);
    setTypeButton(typeButtonCurrent);
    setTypeStyleCurrent(typeButtonCurrent);
    setColorButton(colorButtonCurrent);
    setLetterSize(currentLetterSize);
    setBoldItalic(currentBoldItalic);
    setFontSize(currentFontSize);
    setFontStyle(currentFontStyle);

    const newLoggedUser = {
      ...loggedUser,
      typeButton: typeButtonCurrent,
      colorButton: colorButtonCurrent,
      leterSize: currentLetterSize,
      boldItalic: currentBoldItalic,
      fontSize: currentFontSize,
      fontStyle: currentFontStyle,
    };
    setLoggedUser(newLoggedUser);
    localStorage.setItem("editor-user", JSON.stringify(newLoggedUser));
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateButtonType.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          typeButton: typeButtonCurrent,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateButtonColor.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          colorButton: colorButtonCurrent,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateFontCase.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          letterSize: currentLetterSize,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateLineType.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          boldItalic: currentBoldItalic,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateFontSize.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          fontSize: currentFontSize,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateFontStyle.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          fontStyle: currentFontStyle,
        }
      );
    } catch (data) {
      console.log(data);
    }
  };

  const handleResetStyle = async () => {
    setTypeButton(0);
    setTypeStyleCurrent(0);
    const newLoggedUser = {
      ...loggedUser,
      typeButton: 0,
      colorButton: "#CCC",
      letterSize: undefined,
      boldItalic: [],
      fontSize: undefined,
      fontStyle: undefined,
    };
    setLoggedUser(newLoggedUser);
    setColorButton("#CCC");
    setLetterSize(undefined);
    setBoldItalic([]);
    setCurrentBoldItalic([]);
    setFontSize();
    setFontStyle();
    localStorage.setItem("editor-user", JSON.stringify(newLoggedUser));
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateButtonType.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          typeButton: 0,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateButtonColor.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          colorButton: "#CCC",
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateFontCase.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          letterSize: null,
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateLineType.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          boldItalic: [],
        }
      );
    } catch (data) {
      console.log(data);
    }
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateFontStyle.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          fontStyle: currentFontStyle,
        }
      );
    } catch (data) {
      console.log(data);
    }
    setSelectAccountPin(1);
    setSelectAccount(4);
  };

  const handleLetterSize = (index) => {
    setCurrentLetterSize(index);
  };

  const handleBoldItalic = (index) => {
    console.log(index);
    let newCurrent;
    if (currentBoldItalic.find((el) => el === index)) {
      newCurrent = currentBoldItalic.filter((el) => el !== index);
    } else {
      newCurrent = [...currentBoldItalic, index];
    }
    setCurrentBoldItalic(newCurrent);
  };

  const handleBoldItalicStyle = (e) => (ev) => {
    console.log(e);
    console.log(ev);
    /*
    const container = document.querySelector("." + e);
    if (boldItalic.find((element) => element === 0) !== undefined) {
      container.currentTarget.style.fontWeight = "normal";
      e.currentTarget.style.fontStyle = "normal";
    }

    if (boldItalic.find((element) => element === 1) !== undefined) {
      e.currentTarget.style.fontWeight = "bold";
    } else {
      e.currentTarget.style.fontWeight = "normal";
    }

    if (boldItalic.find((element) => element === 1) !== undefined) {
      e.currentTarget.style.fontStyle = "italic";
    } else {
      e.currentTarget.style.fontWeight = "normal";
    }*/
  };

  const handleConvertText = (text) => {
    console.log(text);

    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const range = (to) => {
    return Array.from({ length: to }, (_, index) => index + 1);
  };

  let numbers = range(18);
  let numbersType = range(4);

  console.log(boldItalic);
  console.log(boldItalic.find((element) => parseInt(element) === 1));
  return (
    <div className="wrapper-settings-style-screen">
      <div className="column-wrapper-settings-style-screen ">
        {numbersType.map((n) => {
          return (
            <>
              {n === 1 && (
                <div
                  className="settings-button-screen-style "
                  onClick={() => handleResetStyle(0)}
                >
                  <Button typeButton={typeButton} activation={true}>
                    <div className="settings-button-style-screen-image screen-background-red">
                      <img src={SettingsDefault} alt=""></img>{" "}
                    </div>
                  </Button>
                </div>
              )}
              {n === 2 && (
                <div
                  className="settings-button-screen-style"
                  onClick={() => handleSetTypeButton(1)}
                >
                  <Button typeButton={1} active={typeButtonCurrent === 1}>
                    <div className="settings-button-style-screen-image"></div>
                  </Button>
                </div>
              )}
              {n === 3 && (
                <div
                  className="settings-button-screen-style"
                  onClick={() => handleSetTypeButton(0)}
                >
                  <Button typeButton={0} active={typeButtonCurrent === 0}>
                    <div className="settings-button-style-screen-image"></div>
                  </Button>
                </div>
              )}
              {n === 4 && (
                <div
                  className="settings-button-screen-style"
                  onClick={() => handleSetTypeButton(2)}
                >
                  <Button typeButton={2} active={typeButtonCurrent === 2}>
                    <div className="settings-button-style-screen-image"></div>
                  </Button>
                </div>
              )}
            </>
          );
        })}
      </div>
      <span
        className={
          "style-screen-span " +
          `font-size-${parseInt(
            fontSize === undefined || fontSize === null ? 0 : fontSize / 3
          )}` +
          ` font-style-${parseInt(
            fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
          )}`
        }
        style={
          boldItalic.find((element) => parseInt(element) === 2) !== undefined
            ? {
                fontWeight: "normal",
                fontStyle: "normal",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined &&
              boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
            ? {
                fontWeight: "bold",
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 0) !==
              undefined
            ? {
                fontStyle: "italic",
              }
            : boldItalic.find((element) => parseInt(element) === 1) !==
              undefined
            ? {
                fontWeight: "bold",
              }
            : {
                fontWeight: "normal",
                fontStyle: "normal",
              }
        }
      >
        {handleConvertText("IZABERITE STIL PRIKAZA EKRANA")}
      </span>
      <div className="column-wrapper-settings-style-screen screen-hight-second">
        {numbers.map((n, index) => {
          const styleAuto = {
            fontSize: "40px",
          };
          const styleBold = {
            fontWeight: "bold",
            fontSize: "40px",
          };
          const styleItalic = {
            fontStyle: "italic",
            fontSize: "40px",
          };
          return (
            <>
              {index % 3 === 0 && (
                <div
                  className="settings-button-screen-style"
                  onClick={() => handleSelectColor(index)}
                >
                  <Button
                    typeButton={typeStyleCurrent}
                    active={color[index / 3] === colorButtonCurrent}
                  >
                    <div className="settings-button-style-screen-image">
                      <div
                        className="settings-button-style-color"
                        style={{ backgroundColor: color[index / 3] }}
                      ></div>
                    </div>
                  </Button>
                </div>
              )}
              {index === 1 || index === 2 || index === 0 ? (
                <div
                  className="settings-button-screen-style"
                  onClick={() => handleBoldItalic(index)}
                >
                  <Button typeButton={typeStyleCurrent}>
                    <div
                      className="settings-button-style-screen-image"
                      style={
                        (index === 0 && styleItalic) ||
                        (index === 1 && styleBold) ||
                        (index === 2 && styleAuto)
                      }
                    >
                      A
                    </div>
                  </Button>
                </div>
              ) : index % 3 === 2 && index / 3 > 1 && index / 3 < 7 ? (
                <div
                  className={"settings-button-screen-style "}
                  onClick={() => handleFontSize(index)}
                >
                  <Button
                    typeButton={typeStyleCurrent}
                    active={index === currentFontSize}
                  >
                    <div
                      className={
                        "settings-button-style-screen-image " +
                        `font-size-${parseInt(index / 3)}`
                      }
                    >
                      A
                    </div>
                  </Button>
                </div>
              ) : index % 3 === 1 && index / 3 > 1 && index / 3 < 7 ? (
                <div
                  className={"settings-button-screen-style "}
                  onClick={() => handleFontStyle(index)}
                >
                  <Button
                    typeButton={typeStyleCurrent}
                    active={index === currentFontStyle}
                  >
                    <div
                      className={
                        "settings-button-style-screen-image " +
                        `font-style-${parseInt(index / 3)}`
                      }
                      style={{ fontSize: "40px" }}
                    >
                      A
                    </div>
                  </Button>
                </div>
              ) : (
                <div className="settings-button-screen-style">
                  <Button typeButton={typeStyleCurrent}>
                    <div className="settings-button-style-screen-image"></div>
                  </Button>
                </div>
              )}
            </>
          );
        })}
        <div
          className="settings-button-screen-style"
          onClick={() => handleLetterSize(0)}
        >
          <Button
            typeButton={typeStyleCurrent}
            active={currentLetterSize === 0}
          >
            <div
              style={{ fontSize: "40px" }}
              className="settings-button-style-screen-image"
            >
              OCO
            </div>
          </Button>
        </div>
        <div
          className="settings-button-screen-style"
          onClick={() => handleLetterSize(1)}
        >
          <Button
            typeButton={typeStyleCurrent}
            active={currentLetterSize === 1}
          >
            <div
              className="settings-button-style-screen-image"
              style={{ fontSize: "40px" }}
            >
              Oco
            </div>
          </Button>
        </div>
        <div
          className="settings-button-screen-style"
          onClick={() => handleLetterSize(2)}
        >
          <Button
            typeButton={typeStyleCurrent}
            active={currentLetterSize === 2}
          >
            <div
              className="settings-button-style-screen-image"
              style={{ fontSize: "40px" }}
            >
              oco
            </div>
          </Button>
        </div>
        <div
          className="settings-button-screen-style"
          onClick={handleSendTypeButton}
        >
          <Button typeButton={typeStyleCurrent} activation={true}>
            <div className="settings-button-style-screen-image screen-background-green">
              <img src={Send} alt=""></img>{" "}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StyleScreen;
