import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";
import cancel from "../picture/Cancel.png";
import "./messageLogOut.css";

const MessageLogOut = ({ setActiveExit, setCurrentPage }) => {
  const { selectedLanguage, setFromEnterCode } = useGlobalContext();
  const {
    typeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
    setColorButton,
    setTypeButton,
    setLetterSize,
    setBoldItalic,
    setFontSize,
    setFontStyle,
    setSelectedLanguage,
    setLoggedUser,
    setIsFromHome,
  } = useGlobalContext();

  const handleLogOut = () => {
    localStorage.removeItem("editor-user");
    localStorage.removeItem("api-key");
    localStorage.removeItem("select-language");
    setFromEnterCode(false);
    setSelectedLanguage(undefined);
    setCurrentPage(0);
    setLoggedUser(undefined);
    setIsFromHome(false);
    setColorButton("#CCC");
    setTypeButton(0);
    setLetterSize(undefined);
    setBoldItalic([]);
    setFontSize();
    setFontStyle();
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="message-log-out-wrapper">
      <div className="message-log-out-container">
        <img
          src={cancel}
          alt=""
          className="cancel-message-log-out"
          onClick={() => setActiveExit(false)}
        />
        <span
          className={
            "message-log-out-text " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(
            "Are you sure you want to log out the application?"
          )}
        </span>
        <div className="leave-button-log-out" onClick={() => handleLogOut()}>
          <Button activation={true} typeButton={typeButton}>
            <span
              className={
                "leave-application-log-out " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText("Log Out")}
            </span>{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageLogOut;
