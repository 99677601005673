import "./singleMessage.css";
import { format } from "date-fns";
import Button from "../Button/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import ReplyIcon from "@mui/icons-material/Reply";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RedoIcon from "@mui/icons-material/Redo";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState, useRef } from "react";
import { useGlobalContext } from "../Context/Context";
import BackCheck from "../picture/Back.png";
import ForwardMessage from "./ForwardMessage";
import User from "../picture/User.png";

const SingleMessage = ({
  currentChat,
  setCurrentChat,
  currentTab,
  isFullVisible,
  setCheckBack,
}) => {
  const { setActiveIconsMiddle, activeIconsMiddle, letterSize } =
    useGlobalContext();
  const { loggedUser, apiKey, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const { typeButton } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedMessages, setSearchMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [localChat, setLocalChat] = useState(null);
  const { baseUrl } = useGlobalContext();
  const [selectedMessage, setSelectedMessage] = useState({
    value: false,
    data: "",
  });
  const [isReply, setIsReply] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const messageEndRef = useRef(null);

  const handleSearch = (isUp) => {
    if (searchTerm === "") return;
    if (searchedMessages.length === 0) {
      const newMessages = localChat.messages.filter((msg) =>
        msg.text.includes(searchTerm)
      );
      setSearchMessages(newMessages);
      setCurrentMessage(0);
    } else {
      if (isUp) {
        setCurrentMessage(
          currentMessage === searchedMessages.length - 1
            ? 0
            : currentMessage + 1
        );
      } else {
        setCurrentMessage(
          currentMessage === 0
            ? searchedMessages.length - 1
            : currentMessage - 1
        );
      }
    }
  };

  const handleClickViewDocument = () => {
    setCheckBack(false);
  };

  const handleChangeTerm = (e) => {
    setSearchTerm(e.target.value);
    setSearchMessages([]);
  };

  const setClassNameCheck = (id, sender_id) => {
    console.log(loggedUser.id);
    console.log(sender_id);
    if (loggedUser.id === sender_id) {
      return id === selectedMessage.data?.id
        ? "message current checked"
        : "message current";
    } else {
      return id === selectedMessage.data?.id
        ? "message other checked"
        : "message other";
    }
  };

  const setClassNameSelect = (id, sender_id) => {
    console.log(loggedUser.id);
    console.log(sender_id);
    if (loggedUser.id === sender_id) {
      return id === searchedMessages[currentMessage]?.id
        ? "message current selected"
        : "message current";
    } else {
      return id === searchedMessages[currentMessage]?.id
        ? "message other selected"
        : "message other";
    }
  };

  const handleCopy = async (message) => {
    try {
      await navigator.clipboard.writeText(message);
      setSelectedMessage({ value: false, data: "" });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSelectMessage = (message) => {
    setSelectedMessage({ value: true, data: message });
  };

  const handleCloseSelect = () => {
    setSelectedMessage({ value: false, data: "" });
  };

  const handleSendMessage = async () => {
    console.log("Salje se poruka.");
    const message =
      document.querySelectorAll(".message-input.msg")[isFullVisible ? 0 : 1];
    if (message.value === "") return;
    try {
      let newMessage = {
        id: Date.now(), // Assign a unique ID temporarily for rendering
        sender_id: loggedUser.id,
        sender: loggedUser.name === "" ? loggedUser.user_email: loggedUser.name,
        text: message.value,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        reply: "",
      };
      const { data } = await axios.post(`${baseUrl}/chat/sendMessage.php`, {
        sender: loggedUser.user_email,
        text: message.value,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        api_key: apiKey,
        chat_room_id: parseInt(currentChat.chat_room_id),
      });

      let messageId = data.message_id;
      if (isReply) {
        setIsReply(false);
        const { data } = await axios.post(`${baseUrl}/chat/sendReply.php`, {
          user_id: parseInt(loggedUser.id),
          message_id: parseInt(messageId),
          reply_text: selectedMessage.data.text,
          api_key: apiKey,
        });
        if (data.status === 200) {
          newMessage = {
            ...newMessage,
            reply: {
              sender: selectedMessage.data.sender,
              text: selectedMessage.data.text,
            },
          };
          setSelectedMessage({ value: false, data: "" });
          message.value = "";
        }
      }

      const newMessages = [...localChat.messages, newMessage];
      setLocalChat({ ...localChat, messages: newMessages });
      if (currentChat.id === localChat.id)
        setCurrentChat({ ...currentChat, messages: newMessages });
      message.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let currentActive = activeIconsMiddle.find(
      (icon) => icon.name === currentTab
    );
    console.log("Prosao", currentActive);
    if (isFullVisible) setLocalChat(currentChat);
    //provera activeIconsMiddle
    /*
    if (!isFullVisible && currentActive.data.id !== currentChat.id) {
      console.log("usao prvi");
      const correctChat = JSON.parse(localStorage.getItem("messages")).find(
        (chat) => chat.id === currentActive.data.id
      );
      setLocalChat(correctChat);
      setSearchMessages([]);
      setCurrentMessage("");
      setSearchTerm("");
    }
    if (!isFullVisible && currentActive.data.id === currentChat.id) {
      setLocalChat(currentChat);
      console.log("usao drugi");
      setSearchMessages([]);
      setCurrentMessage("");
      setSearchTerm("");
      const messageInputs = document.querySelectorAll(".message-input");
      if (messageInputs.length === 2) messageInputs[0].value = "";
      else messageInputs[2].value = "";
    }*/
  }, [currentChat, activeIconsMiddle]);

  useEffect(() => {
    if (localChat !== null) {
      let containers = document.querySelectorAll(".left-side-message-wrapper");
      let container;
      if (!isFullVisible) container = containers[1];
      else container = containers[0];
      container
        ?.querySelector(`[id='${localChat.messages.length}']`)
        ?.scrollIntoView();
    }
  }, [localChat]);

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };
  // Scroll to bottom when a new message is added
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [localChat?.messages.length]);

  // Scroll to current search result
  useEffect(() => {
    console.log("Prvi skrol");
    if (searchedMessages.length > 0 && currentMessage !== "") {
      const currentMsg = document.getElementById(
        searchedMessages[currentMessage].id
      );
      if (currentMsg) {
        currentMsg.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentMessage, searchedMessages]);

  useEffect(() => {
    console.log("Drugi skrol");
    if (searchedMessages.length !== 0) {
      let containers = document.querySelectorAll(".left-side-message-wrapper");
      let container;
      if (containers[1] === undefined && !isFullVisible)
        container = containers[0];
      else if (!isFullVisible) container = containers[1];
      else container = containers[0];
      container
        ?.querySelector(`#\\3${searchedMessages[currentMessage].id}`)
        ?.scrollIntoView();
    }
  }, [currentMessage]);

  console.log(localChat);

  return (
    <>
      <div className="left-side-message-wrapper">
        <div
          className={`${
            selectedMessage.value ? `message-header blocked` : `message-header`
          } `}
        >
          <div className="btn-up" onClick={() => handleSearch(false)}>
            <Button activation={true} typeButton={typeButton}>
              <KeyboardArrowUpIcon />
            </Button>
          </div>
          <input
            className={
              `${
                selectedMessage.value
                  ? `message-input blocked`
                  : `message-input`
              } ` +
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            placeholder={handleConvertText("Search...")}
            onChange={handleChangeTerm}
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          />
          <div className="btn-down" onClick={() => handleSearch(true)}>
            <Button activation={true} typeButton={typeButton}>
              <KeyboardArrowDownIcon />
            </Button>
          </div>
          {isFullVisible && (
            <div className="btn-refresh" onClick={handleClickViewDocument}>
              <Button activation={true} typeButton={typeButton}>
                <img className="visible-img" src={BackCheck} alt=""></img>
              </Button>
            </div>
          )}
        </div>
        <div className="message-chat">
          {localChat !== null &&
            localChat.messages.map((msg, index) => {
              const { id, sender_id, sender, text, date } = msg;
              console.log(date);
              const dateYear = format(date, "dd.MM.yyyy");
              const dateTime = format(date, "HH:mm");
              console.log(dateYear);
              console.log(dateTime);
              return (
                <article
                  id={id}
                  className={
                    selectedMessage.value
                      ? setClassNameCheck(id, sender_id)
                      : setClassNameSelect(id, sender_id)
                  }
                  key={index}
                  onDoubleClick={() => handleSelectMessage(msg)}
                  onTouchStart={() => handleSelectMessage(msg)}
                >
                  {msg.reply !== "" && (
                    <div className="msg-reply">
                      <ReplyIcon />
                      <div className="msg-reply-information">
                        <span>{msg.reply.sender}</span>
                        <span>{msg.reply.text}</span>
                      </div>
                    </div>
                  )}
                  <div className="sender-icon-name">
                    <img src={User} alt="" />
                    <span className="msg-data">{sender}</span>
                  </div>
                  <span className="msg-text">{text}</span>
                  <div className="wrapper-message-date-time">
                    <span>{dateYear}</span>
                    <span>{dateTime}</span>
                  </div>
                </article>
              );
            })}
          <div ref={messageEndRef} />
        </div>
        <div className="message-footer">
          {selectedMessage.value ? (
            <>
              {isReply ? (
                <>
                  <div className="message-reply">
                    <div className="message-reply-information">
                      <div className="message-sender">
                        {selectedMessage.data.sender}
                      </div>
                      <div className="message-text">
                        {selectedMessage.data.text}
                      </div>
                    </div>
                    <div
                      className="msg-select-button"
                      onClick={() => setIsReply(false)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                  <div className="reply-input-container">
                    <input className="message-input msg" />
                    <div className="btn-send" onClick={handleSendMessage}>
                      <Button activation={true} typeButton={typeButton}>
                        <SendIcon />
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="msg-footer-select-left">
                    <div
                      className="msg-select-button"
                      onClick={handleCloseSelect}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <CloseIcon />
                      </Button>
                    </div>
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText("1 selected")}
                    </span>{" "}
                    {/*promena jezika*/}
                  </div>
                  <div className="msg-footer-select-right">
                    <div
                      className="msg-select-button"
                      onClick={() => setIsReply(true)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <ReplyIcon />
                      </Button>
                    </div>
                    <div
                      className={"msg-select-button "}
                      onClick={() => handleCopy(selectedMessage.data.text)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <ContentCopyIcon />
                      </Button>
                    </div>
                    <div
                      className="msg-select-button"
                      onClick={() => setIsForward(true)}
                    >
                      <Button activation={true} typeButton={typeButton}>
                        <RedoIcon />
                      </Button>
                    </div>
                  </div>{" "}
                </>
              )}
            </>
          ) : (
            <>
              <input className="message-input msg" />
              <div className="btn-send" onClick={handleSendMessage}>
                <Button activation={true} typeButton={typeButton}>
                  <SendIcon />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {isForward && (
        <ForwardMessage
          setSelectedMessage={setSelectedMessage}
          setIsForward={setIsForward}
          selectedMessage={selectedMessage}
          isFullVisible={isFullVisible}
          localChat={localChat}
          setLocalChat={setLocalChat}
          currentTab={currentTab}
        />
      )}
    </>
  );
};

export default SingleMessage;
