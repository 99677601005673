import { Outlet } from 'react-router-dom'

import './personalSpace.css'


const PersonalSpace = () => {

  
      
  return (
    <div className="user-feed-wrapper">

      <Outlet />
    </div>
  )
}

export default PersonalSpace