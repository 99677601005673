import { useEffect, useState, useRef } from "react";
import Button from "../../Button/Button";
import axios from "axios";
import Plus from "../../picture/plus-btn.png";
import Send from "../../picture/Send.png";
import "./languageFlag.css";
import { useGlobalContext } from "../../Context/Context";

const LanguageFlag = ({
  setCurrentPage,
  setCurrentPageGlobal,
  setSelectAccount,
  selectedCountry,
  setSelectedCountry,
}) => {
  const [flags, setFlags] = useState([]);
  const baseUrl = "https://data.nadzor.app/api";
  const [countryIndex, setCountryIndex] = useState(0);
  const [restFlagsHeight, setRestFlagsHeight] = useState(
    window.innerHeight * 0.07
  );
  const [pictureHeight, setPictureHeight] = useState(window.innerHeight * 0.07);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [wrapperWidth, setWrapperWidth] = useState(0.5 * height);
  const [wrapperHeight, setWrapperHeight] = useState(0.95 * height);

  const interval = useRef();
  const changeFlagInterval = useRef();
  const { loggedUser, apiKey, setSelectedLanguage } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  

  const addNewLanguage = () => {
    if (loggedUser !== undefined) {
      setCurrentPage(1);
    } else {
      setCurrentPage(5);
    }
  };

  const handleSelectFlag = (country, countryIndex) => {
    setSelectedCountry(country);
    setCountryIndex(countryIndex);
    console.log("Brise se interval", interval);
    clearTimeout(interval.current);
    console.log("Zemlja brise interval", country);
  };

  const handleSelectLanguage = async () => {
    console.log("Ime prosledjenog jezika", selectedCountry);
    try {
      const { data } = await axios.post(`${baseUrl}/vocabulary/getAll.php`, {
        lang_name:
          selectedCountry === undefined ? "English" : selectedCountry.lang_name,
      });
      console.log(data);
      if (data.status === 200) {
        setSelectedLanguage(data);
        localStorage.setItem("select-language", JSON.stringify(data));
        if (loggedUser === undefined) {
          console.log("Poziva se za unos drugog jezika", data);
          if (data.name === "English") {
            const item = flags.find((flag) => flag.lang_name === "English");
            handleSelectFlag(item);
            interval.current = setTimeout(() => {
              setCurrentPageGlobal(2);
            }, 2000);
          } else {
            interval.current = setTimeout(() => {
              setCurrentPageGlobal(2);
            }, 2000);
          }
        } else {
          setSelectAccount(0);
          let sidebar = document.querySelector(".sidebar");
          sidebar.style.pointerEvents = "auto";
          let definePin = document.querySelector(".definePin-container");
          definePin.style.width = "calc(100vw -11.11vh)";
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkAspectRatio = () => {
    if (width / height > 1) {
      document.querySelector(".wrapper-languages-container").style.height =
        "100%";
      document.querySelector(".wrapper-languages-container").style.width =
        "50vw";
      setWrapperWidth(width * 0.5);
      setWrapperHeight(width * 0.95);
      setRestFlagsHeight(window.innerWidth * 0.07);
      console.log(wrapperWidth);
    } else {
      document.querySelector(".wrapper-languages-container").style.height =
        "100%";
      document.querySelector(".wrapper-languages-container").style.width =
        "50vh";
      setWrapperWidth(height * 0.5);
      setWrapperHeight(height * 0.95);
      setRestFlagsHeight(window.innerHeight * 0.07);
      console.log(wrapperWidth);
      console.log(restFlagsHeight);
    }
    if (width / height < 0.6) {
      document.querySelector(".wrapper-languages-container").style.height =
        "171vw";
      document.querySelector(".wrapper-languages-container").style.width =
        "90vw";
      setRestFlagsHeight(window.innerWidth * 0.13);
      setWrapperWidth(0.9 * width);
    }
    console.log(width / height);
    if (width / height > 1.66) {
      document.querySelector(".wrapper-languages-container").style.width =
        "50vw";
      document.querySelector(".wrapper-languages-container").style.height =
        "100%";
      // setRestFlagsHeight(window.innerHeight * 0.03);
      //setWrapperWidth(0.9 * height);
    }
    if (width / height < 0.4 || width / height > 2.5) {
      const name = document.querySelector(".name-country");
      if (name !== null) name.style.fontSize = "30px";
    } else {
      const name = document.querySelector(".name-country");
      if (name !== null) name.style.fontSize = "50px";
    }
  };
  useEffect(() => {
    checkAspectRatio();
    // setRestFlagsHeight(window.innerHeight * 0.08)
  }, [height, width]);

  useEffect(() => {
    console.log("POziva se useEffect");
    const handleSetFlags = async () => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/languages/ReadMainLanguages.php`,
          {}
        );
        console.log(data);
        if (data.status === 200) setFlags(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    handleSetFlags();

    window.addEventListener(
      "resize",
      () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      },
      true
    );
    console.log("Interval postavljen", interval);
    return () => clearTimeout(interval.current);
  }, []);

  useEffect(() => {
    if (flags.length === 0) return;
    if (loggedUser === undefined) {
      interval.current = setTimeout(() => {
        console.log("Interval postavlja language", interval);
        handleSelectLanguage();
      }, 2000);
    } else {
      let sidebar = document.querySelector(".sidebar");
      if (sidebar.classList.contains("visible")) {
        sidebar.classList.replace("visible", "hidden");
        sidebar.style.pointerEvents = "none";
        let definePin = document.querySelector(".definePin-container");
        definePin.style.width = "100vw";
        console.log("Postavljen novi css", sidebar);
      }
    }
  }, [flags]);


  return (
    <div className="wrapper-languages-container">
      <div className="flags-up">
        {flags.map((item, index) => {
          const numberOfFlags = Math.round(flags.length / 4);
          if (index < Math.floor(flags.length / 4)) {
            const theta = Math.PI / Math.floor(flags.length / 4 + 1);
            const centerSideFlagsDivWidth = wrapperWidth / 5;
            const flagAndWrapperGap =
              (centerSideFlagsDivWidth - restFlagsHeight) / 2;
            const circleRadius = wrapperWidth / 2 - centerSideFlagsDivWidth / 2;
            const x =
              circleRadius * Math.cos(theta * (index + 1)) +
              wrapperWidth / 2 -
              restFlagsHeight / 2;
            const y =
              circleRadius * Math.sin(theta * (index + 1)) - restFlagsHeight;
            console.log("Element" + index + "has" + x + "width");
            console.log("Element" + index + "has" + y + "height");
            const extraMargin = item.lang_name === "Türkçe" && '15px';
            console.log(extraMargin)
            return (
              <div
                key={item.language_id}
                className="one-flag-up"
                style={
                  countryIndex === index
                    ? {
                        bottom: `${y - (pictureHeight - 60) / 2}px`,
                        right: `${x - (pictureHeight - 60) / 2}px`,
                      }
                    : { bottom: `${y}px`, right: `${x}px` }
                }
                onClick={() => {
                  clearTimeout(changeFlagInterval.current);
                  handleSelectFlag(item, index);
                }}
              >
                <img
                  src={item.lang_flag_base64}
                  alt=""
                  style={
                    
                    countryIndex === index
                      ? { height: `${restFlagsHeight}px`, width: "auto", marginLeft: extraMargin && '17px' }
                      : { height: `${restFlagsHeight}px`, width: "auto", marginLeft: extraMargin && '17px' }
                      
                  }
                />
              </div>
            );
          }
        })}
      </div>
      <div className="flags-center">
        <div className="flags-center-side-left">
          {flags.map((item, index) => {
            const numberOfFlags = Math.round(flags.length / 4);
            // if (index >= flags.length / 4 && index < flags.length / 2) {
            if (index >= flags.length - numberOfFlags && index < flags.length) {
              // let distance = ((90*window.innerHeight/2)/200)/(flags.length/4) + 30
              return (
                <div
                  key={item.language_id}
                  className="one-flag"
                  onClick={() => {
                    clearTimeout(changeFlagInterval.current);
                    handleSelectFlag(item, index);
                  }}
                >
                  <img
                    src={item.lang_flag_base64}
                    alt=""
                    style={
                      countryIndex === index
                        ? { height: `${restFlagsHeight}px`, width: "auto" }
                        : { height: `${restFlagsHeight}px`, width: "auto" }
                    }
                  />
                </div>
              );
            }
          })}
        </div>
        {selectedCountry !== undefined && (
          <div className="wrapper-select-flag">
            <img
              src={selectedCountry.lang_flag_base64}
              className="image-flag"
            ></img>
            <div className="wrap-language-send">
              <div className="name-country">
                {selectedCountry.lang_name.slice(0, 3).toUpperCase()}
              </div>
              <div className="usage-percentage">
                <p style={{textAlign: 'center', fontSize: '1.4rem',fontWeight: '600', color: 'black', opacity: '0.8', marginBottom: '15%'}}>67.3%</p>
              </div>
              <div
                className="select-country-button"
                onClick={handleSelectLanguage}
              >
                <Button typeButton={typeButton}   >
                  <img src={Send} alt="" className="language-selection-img" />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="flags-center-side-right">
          {flags.map((item, index) => {
            // if (index >= flags.length / 2 && index < (3 * flags.length) / 4) {
            const numberOfFlags = Math.round(flags.length / 4);
            if (
              index >= Math.floor(flags.length / 4) &&
              index < Math.floor(flags.length / 4) + numberOfFlags
            ) {
              return (
                <div
                  key={item.language_id}
                  className="one-flag"
                  onClick={() => {
                    clearTimeout(changeFlagInterval.current);
                    handleSelectFlag(item, index);
                  }}
                >
                  <img
                    src={item.lang_flag_base64}
                    alt=""
                    style={
                      countryIndex === index
                        ? { height: `${restFlagsHeight}px`, width: "auto" }
                        : { height: `${restFlagsHeight}px`, width: "auto" }
                    }
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="flags-down">
        {flags.map((item, index) => {
          const numberOfFlags = Math.round(flags.length / 4);
          // if (index >= (3 * flags.length) / 4 && index < flags.length) {
          if (
            index >= Math.floor(flags.length / 4) + numberOfFlags &&
            index < flags.length - numberOfFlags
          ) {
            var theta;
            if (flags.length % 4 == 1) {
              theta = Math.PI / Math.floor(flags.length / 4 + 2);
            } else if (flags.length % 4 == 3) {
              theta = Math.PI / Math.round(flags.length / 4 + 1);
            } else {
              theta = Math.PI / Math.floor(flags.length / 4 + 1);
            }
            console.log(theta);
            const centerSideFlagsDivWidth = wrapperWidth / 5;
            const flagAndWrapperGap =
              (centerSideFlagsDivWidth - restFlagsHeight) / 2;
            const circleRadius = wrapperWidth / 2 - centerSideFlagsDivWidth / 2;
            // console.log(circleRadius);
            // console.log(wrapperWidth);
            const x =
              circleRadius *
                Math.cos(theta * (flags.length - numberOfFlags - index)) +
              wrapperWidth / 2 -
              restFlagsHeight / 2;
            const y =
              circleRadius *
                Math.sin(
                  theta *
                    (index - (Math.floor(flags.length / 4) + numberOfFlags) + 1)
                ) -
              restFlagsHeight;
            // console.log(wrapperWidth);
            // console.log("Element"+index+"has"+ x +"width");
            // console.log("Element"+index+"has"+ y +"height");
            return (
              <div
                key={item.language_id}
                className="one-flag-down"
                style={
                  countryIndex === index
                    ? {
                        top: `${y - (pictureHeight - 60) / 2}px`,
                        right: `${x - (pictureHeight - 60) / 2}px`,
                      }
                    : { top: `${y}px`, right: `${x}px` }
                }
                onClick={() => {
                  clearTimeout(changeFlagInterval.current);
                  handleSelectFlag(item, index);
                }}
              >
                <img
                  src={item.lang_flag_base64}
                  alt=""
                  style={
                    countryIndex === index
                      ? { height: `${restFlagsHeight}px`, width: "auto" }
                      : { height: `${restFlagsHeight}px`, width: "auto" }
                  }
                />
              </div>
            );
          }
        })}
        <div className="flags-down-button" onClick={addNewLanguage}>
          <Button typeButton={typeButton} activation={false} btnId='language-selection-add-button'>
            <img src={Plus} alt="" className="language-selection-img" style={{width: '30px'}} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LanguageFlag;
