import { compareAsc, format, getDay, getMonth, getYear } from "date-fns";
import "./editorCalendar.css";
import { setDays, weekdays, months } from "./data";
import { useState, useEffect } from "react";
import arrowLeft from "../play-left.png";
import arrowRight from "../play-right.png";
import { useGlobalContext } from "../../Context/Context";

const EditorCalendar = ({
  filterState,
  setFilterState,
  calendarActive,
  setCalendarActive,
  daysArchive,
}) => {
  const [selectedDay, setSelectedDay] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [currentDays, setCurrentDays] = useState(setDays(new Date()));
  const { letterSize, boldItalic, fontSize, fontStyle } = useGlobalContext();

  const renderEmpty = (cond) => {
    const index = cond
      ? getDay(currentDays[0])
      : 6 - getDay(currentDays[currentDays.length - 1]);
    if (index === 0) return;
    let array = Array.from({ length: index });
    return array.map((_, index) => {
      return <div className="calendar-main-item" key={index}></div>;
    });
  };

  const handleNextMonth = () => {
    let newDate;
    if (getMonth(selectedDay) + 1 === 12)
      newDate = new Date(`${getYear(selectedDay) + 1}-1-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDay)}-${getMonth(selectedDay) + 2}-1`
      );
    }
    setSelectedDay(newDate);
    setCurrentDays(setDays(newDate));
  };

  const handlePreviousMonth = () => {
    let newDate;
    if (getMonth(selectedDay) - 1 === -1)
      newDate = new Date(`${getYear(selectedDay) - 1}-12-1`);
    else {
      newDate = new Date(
        `${getYear(selectedDay)}-${
          getMonth(selectedDay) - 1 !== 0 ? getMonth(selectedDay) : "1"
        }-1`
      );
    }
    setSelectedDay(newDate);
    setCurrentDays(setDays(newDate));
  };

  const handleSelectedDay = (selected) => {
    console.log(filterState);
    if (compareAsc(selected, new Date(daysArchive[0])) < 0) return;
    if (compareAsc(selected, new Date(new Date().setHours(0, 0, 0, 0))) >= 0)
      return;
    if (
      calendarActive.dateToSet === 1 &&
      filterState.startDate !== "" &&
      compareAsc(selected, filterState.startDate) < 0
    )
      return;
    const selectedDate = Object.keys(filterState)[calendarActive.dateToSet];
    setSelectedDay(selected);
    setFilterState({ ...filterState, [selectedDate]: selected });
    setCalendarActive({ value: false, dateToSet: 0 });
  };

  const checkIfSelected = (day) => {
    if (compareAsc(day, selectedDay) === 0) return true;
    else return false;
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="calendar-wrapper">
      <div className="calendar">
        <div className="calendar-header">
          <img
            src={arrowLeft}
            alt=""
            className="calendar-header-left"
            onClick={handlePreviousMonth}
          />
          <div id="header" className="calendar-header-date">
            <span
              className={
                "month " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText(months[getMonth(selectedDay)])}
            </span>
            <span className="line">-</span>
            <span className="year">{getYear(selectedDay)}</span>
          </div>
          <img
            src={arrowRight}
            alt=""
            className="calendar-header-right"
            onClick={handleNextMonth}
          />
        </div>
        <div className="calendar-main-wrapper">
          <div className="calendar-main">
            {weekdays.map((day, index) => {
              return (
                <span
                  key={index}
                  className={
                    "calendar-main-item letter " +
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(day)}
                </span>
              );
            })}
            {renderEmpty(true)}
            {currentDays.map((day, index) => {
              return (
                <div
                  className={
                    checkIfSelected(day)
                      ? "calendar-main-item active selected"
                      : "calendar-main-item active"
                  }
                  key={index}
                  onClick={() => handleSelectedDay(day)}
                >
                  <span className="calendar-main-item-day">
                    {format(day, "d").toString().length === 1
                      ? `0${format(day, "d")}`
                      : format(day, "d")}
                  </span>
                </div>
              );
            })}
            {renderEmpty(false)}
          </div>
          <div className="slider-wrapper"></div>
        </div>
      </div>
    </div>
  );
};
export default EditorCalendar;
