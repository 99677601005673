import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import CircledButton from '../buttons/circled-button/CircledButton'
import { BackActiveIcon, BackIdleIcon, BellActiveIcon, BellIdleIcon, SearchActiveIcon, SearchIdleIcon, 
    UserActiveIcon, UserIdleIcon,
    GearActiveIcon,
    GearIdleIcon,
    OtherActiveIcon,
    OtherIdleIcon,
    SaveActiveIcon,
    SaveIdleIcon, 
    UserfeedIdleIcon,
    UserfeedActiveIcon} 
from '../../assets'

import './navMenu.css'


const NavMenu = ({buttonsList}) => {
    const [isActive, setIsActive] = useState(false)

    const handleThirdButtonEffect = () => {
        setIsActive(!isActive)
    }

    const location = useLocation()
    const [navPage, setNavPage] = useState('')

    useEffect(() => {
      setNavPage(location.pathname)
      console.log(buttonsList)
    }, [location])
    
  return (
    location.pathname.substring(0,12) === '/social/feed' ?
    <nav className="top-buttons">
        {buttonsList.map((button, key) => (
            <Link to={button.link} key={key}>
                <CircledButton
                    toggle={button.toggle} 
                    idleImage={button.idleImage} 
                    activeImage={button.activeImage} 
                    handleButtonEffect={button.handleEffect}
                />
            </Link>
        ))}
    </nav>
    :
    <nav className="top-buttons">
        <Link to="feed">
          <CircledButton idleImage={BackIdleIcon} handleButtonEffect={() => console.log("Test")}/>
        </Link>
        <Link to='personal'>
            <CircledButton
            idleImage={UserfeedIdleIcon}
            activeImage={UserfeedActiveIcon}
            toggle={true}
            handleButtonEffect={() => console.log('saved')} // Handle third button click
            />
        </Link>
        <Link to='personal/user-bloc'>
            <CircledButton
            idleImage={OtherIdleIcon}
            activeImage={OtherActiveIcon}
            toggle={true}
            handleButtonEffect={handleThirdButtonEffect} // Handle third button click
            />
        </Link>
        <CircledButton idleImage={GearIdleIcon} activeImage={GearActiveIcon} handleButtonEffect={()=>console.log("dsads")} />
    </nav>
  )
}

export default NavMenu