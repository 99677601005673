import { useState, useEffect } from "react";
import axios from "axios";

import { format } from "date-fns";
import Button from "../Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import "./forwardMessage.css";
import { useGlobalContext } from "../Context/Context";

const ForwardMessage = ({
  setIsForward,
  setSelectedMessage,
  selectedMessage,
  localChat,
  setLocalChat,
  currentTab,
}) => {
  const [data, setData] = useState([]);
  const { loggedUser } = useGlobalContext();
  const [selectedChat, setSelectedChat] = useState(null);
  const { typeButton } = useGlobalContext();
  const { letterSize } = useGlobalContext();
  const { boldItalic } = useGlobalContext();
  const { fontSize } = useGlobalContext();
  const {
    currentChat,
    setCurrentChat,
    activeIconsMiddle,
    setActiveIconsMiddle,
    baseUrl,
    apiKey,
    fontStyle,
  } = useGlobalContext();

  const handleForward = async () => {
    if (selectedChat === null) return;
    const correctChat = data.find((chat) => chat.id === selectedChat);

    //Ovde treba poziv za dodavanje porukue novom
    try {
      //console.log(currentChat);
      //console.log(newMessage);
      const dataForward = await axios.post(
        `${baseUrl}/chat/getAllMessagesByTwoUsers.php`,
        {
          user_id: loggedUser.id,
          other_user_id: correctChat.id,
          api_key: apiKey,
        }
      );
      console.log(dataForward.data);
      console.log(currentChat);
      const dataSend = await axios.post(`${baseUrl}/chat/sendMessage.php`, {
        sender: loggedUser.user_email,
        text: selectedMessage.data.text,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        api_key: apiKey,
        chat_room_id: parseInt(dataForward.data.chat_room_id),
      });

      let messageId = dataSend.data.message_id;

      let newMessage = {
        id: messageId,
        sender: loggedUser.user_email,
        sender_id: parseInt(loggedUser.id),
        text: selectedMessage.data.text,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        apiKey: apiKey,
      };

      if (selectedMessage.data.reply !== "") {
        const { data } = await axios.post(`${baseUrl}/chat/sendReply.php`, {
          user_id: parseInt(loggedUser.id),
          message_id: parseInt(messageId),
          reply_text: selectedMessage.data.text,
          api_key: apiKey,
        });

        if (data.status === 200) {
          newMessage = {
            ...newMessage,
            reply: {
              sender: selectedMessage.data.sender,
              text: selectedMessage.data.text,
            },
          };
          setSelectedMessage({ value: false, data: "" });
        }
      } else {
        newMessage = {
          ...newMessage,
          reply: "",
        };
      }
      console.log(newMessage);
      if (dataForward.data.status === 200) {
        const newMessages = [...dataForward.data.messages, newMessage];

        //localStorage.setItem("messages", JSON.stringify(newData));//ovde treba poziv za citanje novog sve poruke
        // if (localChat.id === dataForward.data.other_user_id)
        setLocalChat({ ...localChat, messages: newMessages });

        // if (correctChat.id === dataForward.data.other_user_id)
        setCurrentChat({ ...currentChat, messages: newMessages });

        /*
        let currentActive = activeIconsMiddle.find(
          (icon) => icon.name === currentTab
        );
        if (currentActive.data.id === correctChat.id) {
          const correctData = { ...currentActive.data, messages: newMessages };
          const newIconsMiddle = activeIconsMiddle.map((icon) => {
            if (currentTab === icon.name)
              return {
                name: icon.name,
                data: correctData,
              };
            else return icon;
          });
          setActiveIconsMiddle(newIconsMiddle);
        }*/

        setIsForward(false);
        setSelectedMessage({ value: false, data: "" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    // chat/getAllChatRoomsByUserId.php
    const fetchAllMessages = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/chat/getAllChatRoomsByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        setData(response.data.chat_rooms);
        console.log(response.data);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    fetchAllMessages();
  }, []);

  return (
    <div className={"msg-forward mini"}>
      <div className="msg-forward-wrapper">
        <div className="msg-forward-header">
          <h4
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText("Prosledi poruku:")}
          </h4>{" "}
          {/* promena jezika*/}
          <div className="msg-cancel" onClick={() => setIsForward(false)}>
            <Button typeButton={typeButton}>
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className="msg-forward-chats">
          {data.map((userChat) => {
            console.log(userChat);
            return (
              <span
                className={
                  selectedChat === userChat.id
                    ? "msg-user selected"
                    : "msg-user"
                }
                key={userChat.id}
                onClick={() => setSelectedChat(userChat.id)}
              >
                {userChat.name}
              </span>
            );
          })}
        </div>
        <div className="msg-forward-btn" onClick={handleForward}>
          <Button
            activation={true}
            typeButton={typeButton}
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            <div className="forward-message-text">
              {handleConvertText("Prosledi")} {/*promena jezika*/}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForwardMessage;
