import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../Context/Context";
import axios from "axios";
import Button from "../../../Button/Button";
import "./categoryFilter.css";

const CategoryFilter = ({
  setOpenChart,
  filterState,
  setFilterState,
  isFromFilter,
}) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const [categories, setCategories] = useState([]);
  const { location } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { letterSize } = useGlobalContext();
  const { boldItalic } = useGlobalContext();
  const { fontSize, fontStyle } = useGlobalContext();

  const handleSelectedCategory = (cat) => {
    if (!checkIfExists(cat.id))
      setFilterState({
        ...filterState,
        category: [...filterState.category, cat],
      });
    else {
      const newCategory = filterState.category.filter(
        (item) => item.id !== cat.id
      );
      setFilterState({ ...filterState, category: newCategory });
    }
  };

  const checkIfExists = (id) => {
    const item = filterState.category.find((cat) => cat.id === id);
    if (item) return true;
    else return false;
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    const handleCategory = async () => {
      try {
        axios
          .post(`${baseUrl}/categories/getAllCategoriesByLocationId.php`, {
            location_id: location.location_id,
            api_key: apiKey,
            user_id: loggedUser.id,
          })
          .then((data) => {
            console.log(data);
            if (data.data.status === 200) {
              console.log("Postavlja se vrednost kategorije");

              setCategories(data.data.categories);
              // setOpenChart(true);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleCategory();
  }, []);

  console.log(filterState);

  return (
    <div
      className={
        isFromFilter ? "wraper-filter-category" : "wraper-viewer-category"
      }
    >
      {categories.map((category, index) => {
        const { id, color, letter_color, letter, name } = category;
        return (
          <div
            className="category-filter-modal-item-button"
            key={id}
            onClick={() => handleSelectedCategory(category)}
          >
            <Button active={checkIfExists(id)} typeButton={typeButton}>
              <div className="category-filter-modal-item">
                <div className="category-filter-modal-item-color">
                  <div
                    className="category-filter-modal-item-color-letter"
                    style={{ backgroundColor: color }}
                  >
                    <div
                      className="category-filter-modal-item-letter"
                      style={{ color: letter_color }}
                    >
                      {letter}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "category-filter-modal-item-name " +
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(name.toUpperCase())}
                </div>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryFilter;
