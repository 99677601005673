import mail from "../picture/Mail.png";
import key from "../picture/Key.png";
import send from "../picture/Send.png";
import Button from "../Button/Button";
//import data from "./data";
import "./login.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import cancel from "../picture/Cancel.png";
import plus from "../picture/Plus.png";
import user from "../picture/User.png";

import infoLogin from "../picture/info-login.png";
import infoKeyImg from "../picture/info-key-img.png";
import infoTrusted from "../picture/info-trusted.png";

const Login = ({ setCurrentPage, currentPage }) => {
  const inputsRef = useRef([]);
  const resetTimeRef = useRef();
  const [remaining, setRemaining] = useState(2);
  const [keyCurrent, setKeyCurrent] = useState("");
  const [mailCurrent, setMailCurrent] = useState("");
  const [registerUser, setRegisterUser] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const [infoActive, setInfoActive] = useState(false);
  const countDown = useRef();
  countDown.current = 60000;

  const { selectedLanguage } = useGlobalContext();
  const { setLoggedUser } = useGlobalContext();
  const { setControlEmail } = useGlobalContext();
  const { setApiKey } = useGlobalContext();
  const { setIsFromFirstTrustContact } = useGlobalContext();
  const { typeButton, setTypeButton, letterSize, setLetterSize } =
    useGlobalContext();
  const { colorButton, setColorButton, boldItalic, fontSize, fontStyle } =
    useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { setBoldItalic } = useGlobalContext();
  const { setFontSize } = useGlobalContext();
  const { setFontStyle } = useGlobalContext();

  const handleInputMail = (e) => {
    let mailUser = document.querySelector(".input-mail");
    if (mailUser.classList.contains("not-correct-content"))
      mailUser.classList.remove("not-correct-content");

    setMailCurrent(e.currentTarget.value);
  };

  const handleInputKey = (index) => {
    if (
      index < inputsRef.current.length - 1 &&
      inputsRef.current[index].value !== ""
    ) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleLogin = async (e) => {
    //mladendubovac98@gmail.com
    //uFhOdn
    const mailUser = {
      id: 0,
      user_email: mailCurrent,
      user_pin: keyCurrent,
      isTemp: false,
      pattern: "",
      privilage: "",
      firstContact: "",
      secondContact: "",
      name: "",
    };
    const inputData = {
      user_email: mailUser.user_email,
      user_pin: mailUser.user_pin,
    };

    try {
      if (!registerUser) {
        const { data } = await axios.post(
          `${baseUrl}/auth/login.php`,
          inputData
        );
        console.log(data);
        if (data.status === 200) {
          setIsFromFirstTrustContact(false);
          mailUser.id = parseInt(data.user_id);
          setApiKey(data.api_key);
          mailUser.name = data.name;
          if (data.pattern) {
            mailUser.pattern = data.pattern;
          } else {
            mailUser.pattern = "";
          }
          const newUser = {
            ...mailUser,
            isPatternSet: false,
            defaultHomePage: data.defaultHomePage,
            typeButton: data.typeButton,
            colorButton: data.colorButton,
            letterSize:
              data.letterSize === null ? undefined : parseInt(data.letterSize),
            boldItalic: data.boldItalic,
            fontSize: data.fontSize,
            fontStyle: data.fontStyle,
            profileId: data.profile_id,
          };
          setTypeButton(data.typeButton);
          setColorButton(data.colorButton);
          setLetterSize(
            data.letterSize === null ? undefined : parseInt(data.letterSize)
          );
          setBoldItalic(data.boldItalic);
          setFontSize(data.fontSize);
          setFontStyle(data.fontStyle);
          let customBtn = document.querySelector(".dos-btn");
          customBtn.classList.add("correct");
          const time = setTimeout(() => {
            setCurrentPage(4);
            customBtn.classList.remove("correct");

            setLoggedUser(newUser);
            clearTimeout(time);
          }, 500);
          localStorage.setItem("editor-user", JSON.stringify(newUser));
          localStorage.setItem("api-key", JSON.stringify(data.api_key));
        } else {
          const dataAuth = await axios.post(`${baseUrl}/auth/checkEmail.php`, {
            user_email: mailUser.user_email,
          });

          if (dataAuth.data.status === 200) {
            mailUser.id = dataAuth.data.id;
            mailUser.user_pin = null;
          }
        }
      } else {
        const { data } = await axios.post(`${baseUrl}/auth/checkEmail.php`, {
          user_email: mailUser.user_email,
        });

        if (data.status === 200) {
          mailUser.id = data.id;
        }
      }
    } catch (error) {
      console.log(error);
    }

    //napomena promeniti key ako je status code 404 i promeniti id

    if (registerUser) {
      if (mailUser.id !== 0) {
        setRegisterUser(false);
      } else {
        setCurrentPage(0);
      }
    } else {
      if (mailUser.id !== 0 && mailUser.user_pin !== keyCurrent) {
        const inputMail = document.querySelector(".input-mail");
        const loginButton = document.querySelector(".loggin-button");
        let customBtn;
        console.log(loginButton);

        customBtn = loginButton.querySelector(".dos-btn");

        if (customBtn === null) {
          customBtn = loginButton.querySelector(".dos-btn-round-none-border");
        }

        if (customBtn === null) {
          customBtn = loginButton.querySelector(".dos-btn-round-border ");
        }

        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        const submit = document.querySelector(".loggin-button");
        const infoLogin = document.querySelector(".info-login");
        console.log(remaining);
        if (remaining === 2) {
          customBtn.classList.add("not-correct");
          inputs.forEach((input) => {
            input.style.color = "red";
          });
          const time = setTimeout(() => {
            setRemaining(1);
            customBtn.classList.remove("not-correct");
            inputs.forEach((input) => {
              input.style.color = "black";
              input.innerHTML = "";
            });
            inputs[0].focus();
            setKeyCurrent("");
            inputMail.style.color = "green";
            clearTimeout(time);
          }, 500);
        } else if (remaining === 1) {
          customBtn.classList.add("not-correct");
          inputs.forEach((input) => {
            input.style.color = "red";
            input.style.pointerEvents = "none";
          });
          inputMail.style.pointerEvents = "none";
          submit.style.pointerEvents = "none";
          const wrapper = document.querySelector(".login-wrapper");
          const numberDiv = document.createElement("span");
          numberDiv.innerHTML = countDown.current / 1000;
          numberDiv.classList.add("countdown-span");
          const wrapperFirst = wrapper.firstChild;
          console.log(wrapper.firstChild);
          if (wrapperFirst !== null) {
            wrapperFirst.style.display = "none";
          }

          wrapper.insertBefore(numberDiv, wrapper.children[1]);
          console.log("Dodajem number div", numberDiv);
          const interval = setInterval(() => {
            countDown.current -= 1000;
            numberDiv.innerHTML = countDown.current / 1000;
          }, 1000);
          setInfoActive(true);
          const time = setTimeout(() => {
            if (wrapperFirst !== null) {
              wrapperFirst.style.display = "flex";
            }
            setRemaining(0);
            setInfoActive(false);
            customBtn.classList.remove("not-correct");
            inputs.forEach((input) => {
              input.style.color = "black";
              input.innerHTML = "";
              input.style.pointerEvents = "auto";
            });
            inputMail.style.pointerEvents = "auto";
            inputs[0].focus();

            setKeyCurrent("");
            submit.style.pointerEvents = "auto";
            resetTimeRef.current = setTimeout(() => {
              setCurrentPage(0);
              clearTimeout(resetTimeRef.current);
            }, 10000);
            numberDiv.innerHTML = "";
            wrapper.removeChild(numberDiv);
            clearInterval(interval);
            clearTimeout(time);
          }, 60000);
        } else if (remaining === 0) {
          setControlEmail(mailCurrent);
          console.log(mailCurrent);
          setCurrentPage(7);
        }
      }

      if (mailUser.id === 0) {
        let customBtn = document.querySelector(".dos-btn");
        if (customBtn === null) {
          customBtn = document.querySelector(".dos-btn-round-none-border");
        }

        if (customBtn === null) {
          customBtn = document.querySelector(".dos-btn-round-border ");
        }

        customBtn.classList.add("not-correct");
        let mailUser = document.querySelector(".input-mail");
        mailUser.classList.add("not-correct-content");
        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        inputs.forEach((input) => {
          input.style.color = "black";
          input.innerHTML = "";
          input.style.pointerEvents = "auto";
        });
        const time = setTimeout(() => {
          setRegisterUser(true);
          mailUser.focus();
          customBtn.classList.remove("not-correct");
          clearTimeout(time);
        }, 500);
      }
    }
  };

  const handleStyleInput = () => {
    const app = document.querySelector(".app");
    const inputKey = document.querySelector(".input-key");
    const inputMail = document.querySelector(".input-mail");
    if (inputKey !== null) {
      const inputs = inputKey.querySelectorAll("input");
      const inputKeyRect = app.getBoundingClientRect();
      inputs.forEach((input) => {
        input.style.fontSize = ` ${(inputKeyRect.height * 5) / 100}px`;
      });
      if (inputMail !== null) {
        inputMail.style.fontSize = ` ${(inputKeyRect.height * 5) / 100}px`;
      }
    }
  };

  const handleChange = (index, value) => {
    // Zabranjujemo unos space-a
    if (value === " ") {
      return; // Tj. ignorisemo unos space-a
    }
    clearTimeout(resetTimeRef.current);
    setKeyCurrent((prevPassword) => {
      const newPassword =
        prevPassword.substring(0, index) +
        value +
        prevPassword.substring(index + 1);
      return newPassword;
    });
  };

  const handleBackspace = (index, event) => {
    if (
      event.keyCode === 8 &&
      index > 0 &&
      inputsRef.current[index].value === ""
    ) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleRegisterUser = (e) => {
    let customBtn = e.currentTarget.querySelector(".dos-btn");
    customBtn.classList.add("correct");
    const time = setTimeout(() => {
      setCurrentPage(6);
      customBtn.classList.remove("correct");
      clearTimeout(time);
    }, 500);
  };

  handleStyleInput();

  const handleShowInfo = () => {
    let infoImage = document.querySelector(".info-login > img");
    let modal = document.querySelector(".info-login-collapse");
    let infoLogin = document.querySelector(".info-login");

    infoImage.style.display = "none";
    modal.style.display = "flex";
    infoLogin.style.display = "none";
  };

  const setActiveExit = () => {
    let infoImage = document.querySelector(".info-login > img");
    let modal = document.querySelector(".info-login-collapse");
    let infoLogin = document.querySelector(".info-login");

    infoImage.style.display = "flex";
    modal.style.display = "none";
    infoLogin.style.display = "flex";
  };

  const handleShowInfoMobile = () => {
    console.log("Poziva se info colapse");
    let infoImage = document.querySelector(
      ".login-wrapper-landscape .info-login > img"
    );
    let modal = document.querySelector(
      ".login-wrapper-landscape .info-login-collapse"
    );

    infoImage.style.display = "none";
    modal.style.display = "flex";
  };

  window.onresize = () => {
    if (!registerUser) handleStyleInput();
    if (window.innerHeight < window.innerWidth) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  useEffect(() => {
    setRemaining(2);

    return () => {
      clearTimeout(resetTimeRef.current);
    };
  }, []);

  useEffect(() => {
    console.log("Ostalo pokusaja", remaining);
    /*if (remaining === 1) {
      resetTimeRef.current = setTimeout(() => {
        const inputMail = document.querySelector(".input-mail");
        let customBtn = document.querySelector(".dos-btn");
        const inputKey = document.querySelector(".input-key");
        const inputs = inputKey.querySelectorAll("input");
        const submit = document.querySelector(".login-button");

        customBtn.classList.add("not-correct");
        inputs.forEach((input) => {
          input.style.color = "red";
          input.style.pointerEvents = "none";
        });
        inputMail.style.pointerEvents = "none";
        submit.style.pointerEvents = "none";
        inputs[0].blur();
        const wrapper = document.querySelector(".login-wrapper");
        const numberDiv = document.createElement("span");
        numberDiv.innerHTML = countDown.current / 1000;
        numberDiv.classList.add("countdown-span");
        console.log("Dodajem number div", numberDiv);
        wrapper.insertBefore(numberDiv, wrapper.firstChild);
        const interval = setInterval(() => {
          countDown.current -= 1000;
          numberDiv.innerHTML = countDown.current / 1000;
        }, 1000);
        const time = setTimeout(() => {
          setRemaining(0);
          customBtn.classList.remove("not-correct");
          inputs.forEach((input) => {
            input.style.color = "black";
            input.innerHTML = "";
            input.style.pointerEvents = "auto";
          });
          inputMail.style.pointerEvents = "auto";
          inputs[0].focus();
          setKeyCurrent("");
          submit.style.pointerEvents = "auto";
          resetTimeRef.current = setTimeout(() => {
            setCurrentPage(0);
            clearTimeout(resetTimeRef.current);
          }, 10000);
          numberDiv.innerHTML = "";
          clearInterval(interval);
          clearTimeout(time);
        }, 60000);
        clearTimeout(resetTimeRef.current);
      }, 10000);
    }*/
  }, [remaining]);

  return (
    <>
      {!isLandscape ? (
        <div className="login-wrapper">
          {registerUser ? (
            <div className="register-wrapper">
              <div className="register-text">
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(selectedLanguage.data.registrationAddress)}
                </span>
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(selectedLanguage.data.readTerms)}
                </span>
              </div>
              <div className="register-button" onClick={handleRegisterUser}>
                <Button activation={true} typeButton={typeButton}>
                  <div className="register-button-img">
                    <img className="visible-img" src={plus} />
                    <img className="visible-img" src={user} />{" "}
                  </div>
                </Button>
              </div>
              <div className="register-text">
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(selectedLanguage.data.noUser)}
                </span>
                <span
                  className={
                    `font-size-${parseInt(
                      fontSize === undefined || fontSize === null
                        ? 0
                        : fontSize / 3
                    )}` +
                    ` font-style-${parseInt(
                      fontStyle === undefined || fontStyle === null
                        ? 0
                        : fontStyle / 3
                    )}`
                  }
                  style={
                    boldItalic.find((element) => parseInt(element) === 2) !==
                    undefined
                      ? {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined &&
                        boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 0
                        ) !== undefined
                      ? {
                          fontStyle: "italic",
                        }
                      : boldItalic.find(
                          (element) => parseInt(element) === 1
                        ) !== undefined
                      ? {
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                          fontStyle: "normal",
                        }
                  }
                >
                  {handleConvertText(selectedLanguage.data.newAddress)}
                </span>
              </div>
            </div>
          ) : (
            <div className="wrapper-login-text-picture">
              {!infoActive && (
                <div onClick={handleShowInfo} className="info-login">
                  <img className="login-click-img" src={infoLogin} alt="" />
                </div>
              )}
              <div className="info-login-collapse">
                <div
                  className="cancel-message-login"
                  onClick={() => setActiveExit()}
                >
                  <img src={cancel} alt="" />
                </div>
                <div className="info-login-collapse-right">
                  <p
                    className={
                      `font-size-${parseInt(
                        fontSize === undefined || fontSize === null
                          ? 0
                          : fontSize / 3
                      )}` +
                      ` font-style-${parseInt(
                        fontStyle === undefined || fontStyle === null
                          ? 0
                          : fontStyle / 3
                      )}`
                    }
                    style={
                      boldItalic.find((element) => parseInt(element) === 2) !==
                      undefined
                        ? {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined &&
                          boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                          }
                        : {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                    }
                  >
                    <img src={mail} alt="" />
                    {handleConvertText(
                      "- Dozvoljene samo adrese kojima možete pristupiti - Ako više ne pristupate registrovanoj e-adresi, molimo vas zamenite je adresom kojoj pristupate"
                    )}
                    <br />
                    <br />
                    <img src={infoKeyImg} alt="" />
                    {handleConvertText(
                      " - Dozvoljeni svi mogući simboli, slova i cifre, i to min. 1 slovo i min. 1 cifra - Lozinku uvek možete izmeniti u podešavanju"
                    )}
                    <br />
                    <br />
                    <img src={infoTrusted} alt="" />
                    {handleConvertText(
                      "- U slucaju greske pri logovanju vlasnistvo nad nalogom se dokazuje kroz kontrolne ekrane koji ukljucuju i navodjenje nekih pouzdanih kontakata. - Pozeljno je jos tokom prvog prijavljivanje definisati dva svoja pouzdana kontakta."
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="wrapper-login-input-value">
            <div className="wrapper-input-fild">
              <img src={mail} alt="" />
              <input
                value={mailCurrent}
                className="input-mail"
                onChange={handleInputMail}
              />
            </div>
            {!registerUser && (
              <div className="wrapper-input-fild">
                <img src={key} alt="" />
                <div className="input-key">
                  {[...Array(6)].map((_, index) => (
                    <input
                      type="text"
                      maxLength="1"
                      key={index}
                      ref={(input) => (inputsRef.current[index] = input)}
                      value={keyCurrent[index] || ""}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onInput={() => handleInputKey(index)}
                      onKeyDown={(e) => handleBackspace(index, e)}
                      autoCapitalize="off"
                    />
                  ))}
                </div>
              </div>
            )}

            <div className="loggin-button" onClick={(e) => handleLogin(e)}>
              <Button activation={true} typeButton={typeButton}>
                <img className="visible-img" src={send} alt="" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-wrapper-landscape">
          {registerUser && (
            <div className="register-wrapper">
              <div className="register-wrapper-top">
                <div className="register-wrapper-left">
                  <div className="register-text">
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText(
                        selectedLanguage.data.registrationAddress
                      )}
                    </span>
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText(selectedLanguage.data.readTerms)}
                    </span>
                  </div>
                  <div className="register-button" onClick={handleRegisterUser}>
                    <Button activation={true} typeButton={typeButton}>
                      <div className="register-button-img">
                        <img className="visible-img" src={plus} />
                        <img className="visible-img" src={user} />{" "}
                      </div>
                    </Button>
                  </div>
                </div>

                <div className="register-wrapper-right">
                  <div className="register-text">
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText(selectedLanguage.data.noUser)}
                    </span>
                    <span
                      className={
                        `font-size-${parseInt(
                          fontSize === undefined || fontSize === null
                            ? 0
                            : fontSize / 3
                        )}` +
                        ` font-style-${parseInt(
                          fontStyle === undefined || fontStyle === null
                            ? 0
                            : fontStyle / 3
                        )}`
                      }
                      style={
                        boldItalic.find(
                          (element) => parseInt(element) === 2
                        ) !== undefined
                          ? {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined &&
                            boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 0
                            ) !== undefined
                          ? {
                              fontStyle: "italic",
                            }
                          : boldItalic.find(
                              (element) => parseInt(element) === 1
                            ) !== undefined
                          ? {
                              fontWeight: "bold",
                            }
                          : {
                              fontWeight: "normal",
                              fontStyle: "normal",
                            }
                      }
                    >
                      {handleConvertText(selectedLanguage.data.newAddress)}
                    </span>
                  </div>
                  <img src={mail} alt="" />
                  <input
                    value={mailCurrent}
                    className="input-mail"
                    onChange={handleInputMail}
                  />
                  <div
                    className="loggin-button"
                    onClick={(e) => handleLogin(e)}
                  >
                    <Button
                      image={send}
                      activation={true}
                      typeButton={typeButton}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="landscape-pair">
            {!registerUser && (
              <>
                <div className="landscape-info">
                  {!infoActive && (
                    <div onClick={handleShowInfoMobile} className="info-login">
                      <img src={infoLogin} alt="" />
                    </div>
                  )}
                  <div className="info-login-collapse">
                    <div
                      className="cancel-message-login"
                      onClick={() => setActiveExit()}
                    >
                      <img src={cancel} alt="" />
                    </div>
                    <div className="info-login-collapse-right scrollable-content">
                      <p
                        className={
                          `font-size-${parseInt(
                            fontSize === undefined || fontSize === null
                              ? 0
                              : fontSize / 3
                          )}` +
                          ` font-style-${parseInt(
                            fontStyle === undefined || fontStyle === null
                              ? 0
                              : fontStyle / 3
                          )}`
                        }
                        style={
                          boldItalic.find(
                            (element) => parseInt(element) === 2
                          ) !== undefined
                            ? {
                                fontWeight: "normal",
                                fontStyle: "normal",
                              }
                            : boldItalic.find(
                                (element) => parseInt(element) === 1
                              ) !== undefined &&
                              boldItalic.find(
                                (element) => parseInt(element) === 0
                              ) !== undefined
                            ? {
                                fontWeight: "bold",
                                fontStyle: "italic",
                              }
                            : boldItalic.find(
                                (element) => parseInt(element) === 0
                              ) !== undefined
                            ? {
                                fontStyle: "italic",
                              }
                            : boldItalic.find(
                                (element) => parseInt(element) === 1
                              ) !== undefined
                            ? {
                                fontWeight: "bold",
                              }
                            : {
                                fontWeight: "normal",
                                fontStyle: "normal",
                              }
                        }
                      >
                        <img src={mail} alt="" />
                        {handleConvertText(
                          "- Dozvoljene samo adrese kojima možete pristupiti - Ako više ne pristupate registrovanoj e-adresi, molimo vas zamenite je adresom kojoj pristupate"
                        )}
                        <br />
                        <br />
                        <img src={infoKeyImg} alt="" />
                        {handleConvertText(
                          "- Dozvoljeni svi mogući simboli, slova i cifre, i to min. 1 slovo i min. 1 cifra - Lozinku uvek možete izmeniti u podešavanju"
                        )}

                        <br />
                        <br />
                        <img src={infoTrusted} alt="" />
                        {handleConvertText(
                          "  - U slucaju greske pri logovanju vlasnistvo nad nalogom se dokazuje kroz kontrolne ekrane koji ukljucuju i navodjenje nekih pouzdanih kontakata. - Pozeljno je jos tokom prvog prijavljivanja definisati dva svoja pouzdana kontakta."
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="login-form-landscape">
              {!registerUser && (
                <>
                  <img src={mail} alt="" />
                  <input
                    value={mailCurrent}
                    className="input-mail"
                    onChange={handleInputMail}
                  />
                </>
              )}

              {!registerUser && (
                <>
                  <img src={key} alt="" />
                  <div className="input-key">
                    {[...Array(6)].map((_, index) => (
                      <input
                        type="text"
                        maxLength="1"
                        key={index}
                        ref={(input) => (inputsRef.current[index] = input)}
                        value={keyCurrent[index] || ""}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onInput={() => handleInputKey(index)}
                        onKeyDown={(e) => handleBackspace(index, e)}
                        autoCapitalize="off"
                      />
                    ))}
                  </div>
                </>
              )}
              <div className="loggin-button" onClick={(e) => handleLogin(e)}>
                {!registerUser && (
                  <Button activation={true} typeButton={typeButton}>
                    <img src={send} alt="" className="visible-img" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
