import React from "react";
import "./customPdf.css";
import { compareAsc, format } from "date-fns";
import ItemPdf from "./ItemPdf";

function CustomPdf({
  account,
  location,
  loggedUser,
  numberOfCopy,
  categoriesItem,
  itemList,
  showImages,
  showDocuments,
  showLinks,
  showComments,
}) {
  const handleNumberOfComments = () => {
    let numberCom = 0;
    itemList.forEach((item) => {
      if (item.comments.length !== 0) {
        numberCom += item.comments.length;
      }
    });
    return numberCom;
  };

  return (
    <div className="custom-pdf-wrapper">
      {/* REPORT HEADER */}
      <div className="custom-pdf-header">
        <table className="tg" style={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <thead>
            <tr>
              <th className="custom-pdf-header-logo" rowSpan={3}>
                <img src={account.logo} alt="Company Logo" style={{ width: "80%" }} />
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">COMPANY NAME</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">LOCATION</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">DATE</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">PERIOD</span>
              </th>
            </tr>
            <tr>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">{account.name}</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">{location.location_name}</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">{itemList.length !== 0 &&
                  format(itemList[0].timeCreated, "dd.MM.yyyy")}</span>
              </th>
              <th className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">
                  {itemList.length !== 0 &&
                    (compareAsc(
                      new Date(new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)),
                      new Date(new Date(itemList[itemList.length - 1].timeCreated).setHours(0, 0, 0, 0))
                    ) !== 0
                      ? format(itemList[itemList.length - 1].timeCreated, "dd.MM.yyyy")
                      : "")}
                </span>
              </th>
            </tr>
            <tr>
              <th className="custom-pdf-header-report" colSpan={5}>
                <span>
                  REPORT - {format(new Date(), "dd.MM.yyyy")}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">CATEGORIES</span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">ITEMS & COMMENTS</span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">SENT BY</span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">TIME</span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-title">NUMBER OF COPY</span>
              </td>
            </tr>
            <tr>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">
                  {categoriesItem.map((category) => category.letter).join(", ")}
                </span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">
                  {itemList.length}
                  <br />
                  {handleNumberOfComments()}
                </span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">{loggedUser.user_email}</span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">
                  {format(new Date(), "hh:mm")}
                </span>
              </td>
              <td className="custom-pdf-header-title-place">
                <span className="custom-pdf-header-value">{numberOfCopy}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* REPORT CONTENT */}
      <div className="custom-pdf-content">
        {itemList.map((item, index) => (
          <div className="item-wrapper" key={item.id}>
            <ItemPdf
              itemNumber={index + 1}
              itemName={item.name}
              itemTitle={item.title}
              itemTime={format(item.timeCreated, "HH:mm")}
              itemComments={item.comments.length}
              categories={item.categories}
              images={showImages ? item.images : []}
              documents={showDocuments ? item.documents : []}
              links={showLinks ? item.links : []}
              comments={showComments ? item.comments : []}
              data={item.data} // Pass item fields here
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomPdf;
