import React, { useEffect, useState } from "react";
import "./pdfViewer.css";
import Button from "../../Button/Button";
import commentImg from "../komentar.png";
import linkImg from "../7.png";
import documentImg from "../dokument.png";
import imageImg from "../slika.png";
import cancelImg from "../zatvaranje.png";
import categoriesImg from "../9.png";
import userImg from "../korisnik.png";
import pdfImg from "../generisi_pdf.png";
import CustomPdf from "./CustomPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import UserFilter from "../Archive/FilterArchive/UserFilter";
import CategoryFilter from "../Archive/FilterArchive/CategoryFilter";
import { compareAsc, format } from "date-fns";
import Pdf from "./Pdf.jsx";
import PDFLoader from "./PDFLoader.jsx";

const PdfViewer = ({
  setPdfActive,
  itemList,
  setItemList,
  isFilter,
  account,
  filterData,
}) => {
  const [openUser, setOpenUser] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [commentsActive, setCommentsActive] = useState(false);
  const [linksActive, setLinksActive] = useState(false);
  const [documentsActive, setDocumentsActive] = useState(false);
  const [imagesActive, setImagesActive] = useState(false);
  const [viewerState, setViewerState] = useState({ people: [], category: [] });
  const [filteredData, setFilteredData] = useState(itemList);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [numberOfCopy, setNumberOfCopy] = useState(0);
  const { location, baseUrl, apiKey, loggedUser } = useGlobalContext();
  const { typeButton } = useGlobalContext();

  // Define handleIncrementCopy
  const handleIncrementCopy = async () => {
    try {
      const { data } = await axios.post(
        `${baseUrl}/reports_counter/incrementCounter.php`,
        {
          location_id: parseInt(location.location_id),
          startDate:
            itemList.length !== 0 &&
            format(itemList[0].timeCreated, "yyyy-MM-dd"),
          endDate:
            itemList.length !== 0 &&
            (compareAsc(
              new Date(new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)),
              new Date(
                new Date(itemList[itemList.length - 1].timeCreated).setHours(
                  0,
                  0,
                  0,
                  0
                )
              )
            ) !== 0
              ? format(itemList[itemList.length - 1].timeCreated, "yyyy-MM-dd")
              : ""),
          user_id: loggedUser.id,
          api_key: apiKey,
        }
      );
      if (data.status === 200) {
        console.log("Successfully downloaded the report");
      }
    } catch (error) {
      console.log("Error incrementing copy count", error);
    }
  };

  // Fetch images for each item
  const handleImageAdd = async () => {
    const responses = [];
    filteredData.forEach((item) => {
      try {
        const response = axios.post(
          `${baseUrl}/items/getAllImagesByItemId.php`,
          {
            item_id: item.id,
            api_key: apiKey,
            user_id: loggedUser.id,
          }
        );
        responses.push(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    });

    const fullImage = await Promise.all(responses);
    const newFilteredData = filteredData.map((item, index) => {
      return {
        ...item,
        images:
          fullImage[index].data.status === 200
            ? fullImage[index].data.images
            : [],
      };
    });
    setFilteredData(newFilteredData);
    setImagesActive(!imagesActive); // Toggle state
  };

  // Fetch documents for each item
  const handleAddDocument = async () => {
    const responses = [];
    filteredData.forEach((item) => {
      try {
        const response = axios.post(
          `${baseUrl}/items/getAllDocumentsForLink.php`,
          {
            item_id: item.id,
            api_key: apiKey,
            user_id: loggedUser.id,
          }
        );
        responses.push(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    });
    const fullDocument = await Promise.all(responses);
    const newFilteredData = filteredData.map((item, index) => {
      return {
        ...item,
        documents:
          fullDocument[index].data.status === 200
            ? fullDocument[index].data.documents
            : [],
      };
    });
    setFilteredData(newFilteredData);
    setDocumentsActive(!documentsActive); // Toggle state
  };

  // Fetch comments and their attachments
  const handleAddComment = async () => {
    const responseImages = [];
    const responseDocuments = [];
    filteredData.forEach((item) => {
      item.comments.forEach((comment) => {
        try {
          const imageResponse = axios.post(
            `${baseUrl}/items/getAllImagesByCommentId.php`,
            {
              item_id: item.id,
              api_key: apiKey,
              user_id: loggedUser.id,
              comment_id: comment.id,
            }
          );
          responseImages.push({
            promise: imageResponse,
            commentId: comment.id,
          });
        } catch (error) {
          console.log("Error fetching comment images");
        }

        try {
          const documentResponse = axios.post(
            `${baseUrl}/items/getAllDocumentsByCommentId.php`,
            {
              item_id: item.id,
              api_key: apiKey,
              user_id: loggedUser.id,
              comment_id: comment.id,
            }
          );
          responseDocuments.push({
            promise: documentResponse,
            commentId: comment.id,
          });
        } catch (error) {
          console.log("Error fetching comment documents");
        }
      });
    });

    const newImages = await Promise.all(responseImages.map((r) => r.promise));
    const images = newImages.map((newImage, i) => {
      if (newImage.data.images !== "") {
        const image = newImage.data.images[0];
        return {
          data: image.data === null ? "" : image.data,
          title: image.title === null ? "" : image.title,
          commentId: responseImages[i].commentId,
        };
      } else {
        return {
          data: "",
          title: "",
          commentId: responseImages[i].commentId,
        };
      }
    });
    console.log(images);
    const newDocuments = await Promise.all(
      responseDocuments.map((r) => r.promise)
    );
    console.log(newDocuments);
    const documents = newDocuments.map((docum, i) => {
      console.log(docum.data.documents);
      if (docum.data.status === 200) {
        const doc = docum.data.documents[0];
        console.log(doc);
        return {
          data: doc.length === 0 ? "" : doc.data,
          name: doc.length === 0 ? "" : doc.name,
          commentId: responseDocuments[i].commentId,
        };
      } else {
        return {
          data: "",
          name: "",
          commentId: responseDocuments[i].commentId,
        };
      }
    });
    console.log(documents);

    const newItemList = filteredData.map((item) => {
      const newComments = item.comments.map((comment) => {
        const commentImages = images.find(
          (img) => img.commentId === comment.id
        );
        const commentDocuments = documents.find(
          (doc) => doc.commentId === comment.id
        );
        console.log(comment);
        console.log(images);
        console.log(commentDocuments);
        return {
          ...comment,
          image: commentImages.data !== "" ? commentImages : [],
          document:
            commentDocuments.data !== "" ? [commentDocuments] || [] : [],
        };
      });
      return { ...item, comments: newComments };
    });
    console.log(newItemList);
    setFilteredData(newItemList);
    setCommentsActive(!commentsActive); // Toggle state
  };

  // Toggle link visibility
  const handleAddLink = () => {
    setLinksActive(!linksActive); // Toggle state
  };

  useEffect(() => {
    const handleGetNumberOfCopy = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/reports_counter/getNumberOfCopy.php`,
          {
            location_id: parseInt(location.location_id),
            startDate:
              itemList.length !== 0 &&
              format(itemList[0].timeCreated, "yyyy-MM-dd"),
            endDate:
              itemList.length !== 0 &&
              (compareAsc(
                new Date(
                  new Date(itemList[0].timeCreated).setHours(0, 0, 0, 0)
                ),
                new Date(
                  new Date(itemList[itemList.length - 1].timeCreated).setHours(
                    0,
                    0,
                    0,
                    0
                  )
                )
              ) !== 0
                ? format(
                    itemList[itemList.length - 1].timeCreated,
                    "yyyy-MM-dd"
                  )
                : ""),
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        if (data.status === 200) {
          setNumberOfCopy(data.counter);
        } else {
          setNumberOfCopy(1);
        }
      } catch (error) {
        console.log("Error fetching number of copies", error);
      }
    };

    handleGetNumberOfCopy();

    const handleSetCategories = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/categories/getAllCategoriesByLocationId.php`,
          {
            location_id: parseInt(location.location_id),
            api_key: apiKey,
            user_id: loggedUser.id,
            item_id: undefined,
          }
        );
        if (data.status === 200) {
          let findCategories = [];
          filteredData.forEach((item) => {
            if (item.categories !== undefined && item.categories.length !== 0)
              findCategories.push(...item.categories);
          });
          findCategories = Array.from(new Set(findCategories));
          const categories = data.categories.filter((category) =>
            findCategories.includes(category.id)
          );
          setCategoriesItem(categories);
        }
      } catch (error) {
        console.log("Error fetching categories", error);
      }
    };

    handleSetCategories();
  }, []);

  useEffect(() => {
    let newItemList;
    let filterData = itemList;
    if (viewerState.people.length !== 0) {
      newItemList = itemList.filter((item) =>
        viewerState.people.some((user) => user.user_id === item.user_id)
      );
      filterData = newItemList;
    }
    if (viewerState.category.length !== 0) {
      newItemList = filterData.filter((item) =>
        item.categories.some((category) =>
          viewerState.category.some((cat) => cat.id === category)
        )
      );
      filterData = newItemList;
    }
    setFilteredData(filterData);
  }, [viewerState, itemList]);

  return (
    <div className={isFilter ? "pdf-viewer-main" : "pdf-viewer-main-filter"}>
      <div className="pdf-viewer-wrapper">
        <div className="pdf-viewer">
          <PDFLoader
            account={account}
            location={location}
            loggedUser={loggedUser}
            numberOfCopy={numberOfCopy}
            categoriesItem={categoriesItem}
            itemList={filteredData}
            imagesActive={imagesActive}
            documentsActive={documentsActive}
            linksActive={linksActive}
            commentsActive={commentsActive}
          />
        </div>
      </div>
      <div className="pdf-viewer-buttons">
        <div className="pdf-viewer-btn" onClick={() => handleAddComment()}>
          <Button activation={true} typeButton={typeButton}>
            <div className={`pdf-button ${!commentsActive && "non-active"}`}>
              <img src={commentImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={handleAddLink}>
          <Button activation={true} typeButton={typeButton}>
            <div className={`pdf-button ${!linksActive && "non-active"}`}>
              <img src={linkImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => handleAddDocument()}>
          <Button activation={true} typeButton={typeButton}>
            <div className={`pdf-button ${!documentsActive && "non-active"}`}>
              <img src={documentImg} alt="" />
            </div>
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => handleImageAdd()}>
          <Button activation={true} typeButton={typeButton}>
            <div className={`pdf-button ${!imagesActive && "non-active"}`}>
              <img src={imageImg} alt="" />
            </div>
          </Button>
        </div>
      </div>
      <div className="pdf-viewer-buttons">
        <div className="pdf-viewer-btn" onClick={() => setPdfActive(false)}>
          <Button activation={true} typeButton={typeButton}>
            <img src={cancelImg} alt="" className="visible-img" />
          </Button>
        </div>
        <div
          className="pdf-viewer-btn"
          onClick={() => setOpenChart(!openChart)}
        >
          <Button activation={true} typeButton={typeButton}>
            <img src={categoriesImg} alt="" className="visible-img" />
          </Button>
        </div>
        <div className="pdf-viewer-btn" onClick={() => setOpenUser(!openUser)}>
          <Button activation={true} typeButton={typeButton}>
            <img src={userImg} alt="" className="visible-img" />
          </Button>
        </div>
        <div className="pdf-viewer-btn">
          <Button activation={true} typeButton={typeButton}>
            <div className="pdf-download" onClick={handleIncrementCopy}>
              <PDFDownloadLink
                document={
                  <Pdf
                    account={account}
                    location={location}
                    loggedUser={loggedUser}
                    numberOfCopy={numberOfCopy}
                    categoriesItem={categoriesItem}
                    itemList={filteredData}
                    imagesActive={imagesActive}
                    documentsActive={documentsActive}
                    linksActive={linksActive}
                    commentsActive={commentsActive}
                  />
                }
                fileName={`report-${format(new Date(), "yyyy-MM-dd")}.pdf`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ height: "60%", width: "60%" }}
                  src={pdfImg}
                  alt=""
                />
              </PDFDownloadLink>
            </div>
          </Button>
        </div>
      </div>
      {openUser && (
        <UserFilter
          filterState={viewerState}
          setFilterState={setViewerState}
          isFromFilter={false}
        />
      )}
      {openChart && (
        <CategoryFilter
          filterState={viewerState}
          setFilterState={setViewerState}
          isFromFilter={false}
        />
      )}
    </div>
  );
};

export default PdfViewer;
