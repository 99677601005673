import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FeedPage, LandingPage, UserFeed, UserPage, PersonalSpace, ConnectedUsers, FeedPosts, RootPage } from './pages'
import { PostForm } from './components'

import App from '../App'
import {fetchData} from './data/fetchAPI'


import './index.css'

export const Routing = () => {
  
  const [flags, setFlags] = useState()

    useEffect(() => {
      try {
        fetchData().then(data => setFlags(data))
      } catch (error) {
        console.log(error)
      }
    }, []);
  
  

  return (
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/social' element={<RootPage />}>
        <Route path='feed' element={<FeedPage />}>
          <Route index element={<FeedPosts />} />
          <Route path='newpost' />
        </Route>
        <Route path='personal' element={<PersonalSpace />}>
          <Route index element={<UserFeed />} />
          <Route element={<ConnectedUsers />} path='user-bloc' />
          <Route path='user-page' element={<UserPage />} />
        </Route>
        <Route path='test-api' element={<>{flags?.map((flag, key) => <li key={key}>{flag.title}</li>)}</>} />
      </Route>
    </Routes>
  )
}
