import Button from "../Button/Button";
import "./defaultPage.css";
import SettingsDefault from "../picture/SettingsDefault.png";
import Calendar from "../picture/Calendar.png";
import Bell from "../picture/Bell.png";
import House from "../picture/House.png";
import Home from "../picture/Home.png";
import Message from "../picture/Message.png";
import Send from "../picture/Send.png";
import axios from "axios";
import Notebook from "../picture/Notes.png";
import { useState } from "react";
import { useGlobalContext } from "../Context/Context";

const DefaultPage = ({ setSelectAccount, setSelectAccountPin }) => {
  const { loggedUser, setLoggedUser } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  console.log("settings");
  const [chooseDefaultPage, setChooseDefaultPage] = useState(0);

  const handleSetDefaultPage = (index) => {
    setChooseDefaultPage(index);
  };

  const handleSendOption = async () => {
    const newLoggedUser = { ...loggedUser, defaultHomePage: chooseDefaultPage };
    setSelectAccountPin(1);
    setSelectAccount(chooseDefaultPage);
    setLoggedUser(newLoggedUser);
    localStorage.setItem("editor-user", JSON.stringify(newLoggedUser));
    try {
      const { data } = await axios.post(
        `${baseUrl}/looks_settings/updateDefaultHomePage.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          defaultHomePage: chooseDefaultPage,
        }
      );
    } catch (data) {
      console.log(data);
    }
  };

  const handleSetBackDefault = async (index) => {
    const newLoggedUser = { ...loggedUser, defaultHomePage: 0 };
    setSelectAccountPin(1);
    setSelectAccount(4);
    setLoggedUser(newLoggedUser);
    localStorage.setItem("editor-user", JSON.stringify(newLoggedUser));
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/updateDefaultHomePage.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          defaultHomePage: 0,
        }
      );
    } catch (data) {
      console.log(data);
    }
  };

  return (
    <div className="wrapper-settings-default-page">
      <div className="column-wrapper-settings-default-page">
        <div
          className="settings-button-default-page"
          onClick={() => handleSetBackDefault(0)}
        >
          <Button typeButton={typeButton}>
            <div
              className="settings-button-default-page-image"
              style={{ backgroundColor: "red" }}
            >
              <img src={SettingsDefault} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button-default-page">
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={Notebook} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button-default-page">
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={Calendar} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div className="settings-button-default-page">
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={Bell} alt=""></img>{" "}
            </div>
          </Button>
        </div>
      </div>
      <div className="column-wrapper-settings-default-page">
        <div
          className="settings-button-default-page"
          onClick={() => handleSetDefaultPage(6)}
        >
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={House} alt=""></img>
            </div>
          </Button>
        </div>
        <div
          className="settings-button-default-page"
          onClick={() => handleSetDefaultPage(0)}
        >
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={Home} alt=""></img>
            </div>
          </Button>
        </div>
        <div
          className="settings-button-default-page"
          onClick={() => handleSetDefaultPage(5)}
        >
          <Button typeButton={typeButton}>
            <div className="settings-button-default-page-image">
              <img src={Message} alt=""></img>{" "}
            </div>
          </Button>
        </div>
        <div
          className="settings-button-default-page"
          onClick={handleSendOption}
        >
          <Button typeButton={typeButton}>
            <div
              className="settings-button-default-page-image"
              style={{ backgroundColor: "green" }}
              activation={true}
            >
              <img src={Send} alt="" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DefaultPage;
