import MessageList from "./MessageList";
import SingleMessage from "./SingleMessage";
import "./message.css";
import { useGlobalContext } from "../Context/Context";

const Message = ({ checkBack, setCheckBack, currentTab, isExternalChat }) => {
  const { currentChat, setCurrentChat } = useGlobalContext();
  console.log(currentChat);
  return (
    <div className="left-side-message">
      {!checkBack ? (
        <MessageList
          setCurrentChat={setCurrentChat}
          setCheckBack={setCheckBack}
          externalChat={isExternalChat}
        />
      ) : (
        <SingleMessage
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          setCheckBack={setCheckBack}
          currentTab={currentTab}
          isFullVisible={true}
          checkBack={checkBack}
        />
      )}
    </div>
  );
};

export default Message;
