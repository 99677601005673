import Button from "../Button/Button";
import { useGlobalContext } from "../Context/Context";
import cancel from "../picture/Cancel.png";
import "./message.css";

const Message = ({ setActiveExit }) => {
  const { selectedLanguage } = useGlobalContext();
  const { typeButton, letterSize, boldItalic, fontSize, fontStyle } =
    useGlobalContext();

  const handleCloseApp = () => {
    window.close();
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  return (
    <div className="message-wrapper">
      <div className="message-container">
        <img
          src={cancel}
          alt=""
          className="cancel-message"
          onClick={() => setActiveExit(false)}
        />
        <span
          className={
            "message-text " +
            `font-size-${parseInt(
              fontSize === undefined || fontSize === null ? 0 : fontSize / 3
            )}` +
            ` font-style-${parseInt(
              fontStyle === undefined || fontStyle === null ? 0 : fontStyle / 3
            )}`
          }
          style={
            boldItalic.find((element) => parseInt(element) === 2) !== undefined
              ? {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined &&
                boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
              ? {
                  fontWeight: "bold",
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 0) !==
                undefined
              ? {
                  fontStyle: "italic",
                }
              : boldItalic.find((element) => parseInt(element) === 1) !==
                undefined
              ? {
                  fontWeight: "bold",
                }
              : {
                  fontWeight: "normal",
                  fontStyle: "normal",
                }
          }
        >
          {handleConvertText(selectedLanguage.data.messageCancel)}
        </span>
        <div className="leave-button" onClick={() => handleCloseApp()}>
          <Button activation={true} typeButton={typeButton}>
            <span
              className={
                "leave-application " +
                `font-size-${parseInt(
                  fontSize === undefined || fontSize === null ? 0 : fontSize / 3
                )}` +
                ` font-style-${parseInt(
                  fontStyle === undefined || fontStyle === null
                    ? 0
                    : fontStyle / 3
                )}`
              }
              style={
                boldItalic.find((element) => parseInt(element) === 2) !==
                undefined
                  ? {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined &&
                    boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                  ? {
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                  ? {
                      fontStyle: "italic",
                    }
                  : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined
                  ? {
                      fontWeight: "bold",
                    }
                  : {
                      fontWeight: "normal",
                      fontStyle: "normal",
                    }
              }
            >
              {handleConvertText(selectedLanguage.data.leave)}
            </span>{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Message;
