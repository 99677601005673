import React, { useState } from 'react';

import "./circledButton.css";

function CircledButton({ idleImage, activeImage, toggle, type='button', children, handleButtonEffect = () => {console.log('button')}, extraStyling }) {
  const [isActive, setIsActive] = useState(false);

  // Handle click on button
  const handleClick = () => {
    if (toggle) {
      setIsActive(!isActive);
    } else {
      setIsActive(true);
      setTimeout(() => setIsActive(false), 500);  
    }
    handleButtonEffect()
  };

  return (
    <button 
      type={type}
      className={`circled-button ${isActive ? 'active' : ''}`} 
      onClick={handleClick}
      style={{ backgroundColor: isActive ? 'var(--active-color)' : 'initial' }}
    >
      <img src={(isActive && activeImage) ? activeImage : idleImage} alt="Icon" style={extraStyling} />
      <div className="button-extra-child">{children}</div>
      
    </button>
  );
}

export default CircledButton;

